import React from "react"
import { useTranslation } from "react-i18next"
import { HiChevronRight } from "react-icons/hi"

const Breadcrumb = ({ data }) => {
  const { t } = useTranslation()

  return (
    <nav className="tw-flex tw-mb-2 md:tw-mb-4" aria-label="Breadcrumb">
      <ol className="tw-inline-flex tw-items-center tw-text-sm tw-space-x-1 md:tw-space-x-3">
        {data.map((item, index) => {
          if (data.length - 1 === index) {
            return (
              <li className="tw-inline-flex tw-items-center" key={index}>
                <HiChevronRight className="tw-w-6 tw-h-6 tw-text-dark-50" />
                <span className="tw-font-semibold tw-text-dark-50 tw-ml-1 md:tw-ml-3">{t(item.name)}</span>
              </li>
            )
          } else if (index === 0) {
            return (
              <li className="tw-inline-flex tw-items-center" key={index}>
                <a
                  href={item.url}
                  className="tw-inline-flex tw-items-center tw-font-semibold tw-text-dark hover:tw-text-primary hover:tw-no-underline">
                  {t(item.name)}
                </a>
              </li>
            )
          }
          return (
            <li className="tw-inline-flex tw-items-center" key={index}>
              <HiChevronRight className="tw-w-6 tw-h-6 tw-text-dark-50" />
              <a
                href={item.url}
                className="tw-inline-flex tw-items-center tw-font-semibold  tw-ml-1 md:tw-ml-3 tw-text-dark hover:tw-text-primary hover:tw-no-underline">
                {t(item.name)}
              </a>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

Breadcrumb.propTypes = {}
Breadcrumb.defaultProps = {}

export default Breadcrumb
