import React from "react"
import PropTypes from "prop-types"
import * as FontIcon from "react-icons/io"

const IonIcon = ({ name, style, className }) => {
  if (name) {
    let iconName = "IoIos" + name[0].toUpperCase() + name.substring(1)
    const Icon = FontIcon[iconName]
    if (Icon) {
      return <Icon className={className || "icon"} style={style} />
    }
  }
  return <div />
}

IonIcon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
}

IonIcon.defaultProps = {
  name: null,
  className: null,
}

export default IonIcon
