const TIME_FORMAT = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" }

export function dateNow(lang = "fi-FI") {
  return new Date().toLocaleString(lang, TIME_FORMAT)
}

export function datetimeFormat(date, lang = "fi-FI") {
  return new Date(date).toLocaleString(lang, TIME_FORMAT)
}

// To ISO format with timezone
export function ISODateNow() {
  var date = new Date()
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 16)
}
