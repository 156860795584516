// Auth
export const REGISTER_LOADING = "REGISTER_LOADING"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAILED = "REGISTER_FAILED"

export const LOGIN_LOADING = "LOGIN_LOADING"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"

export const LOG_OUT = "LOG_OUT"

export const REQUEST_RESET_EMAIL_LOADING = "REQUEST_RESET_EMAIL_LOADING"
export const REQUEST_RESET_EMAIL_SUCCESS = "REQUEST_RESET_EMAIL_SUCCESS"
export const REQUEST_RESET_EMAIL_FAILED = "REQUEST_RESET_EMAIL_FAILED"

export const PASSWORD_RESET_LOADING = "PASSWORD_RESET_LOADING"
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS"
export const PASSWORD_RESET_FAILED = "PASSWORD_RESET_FAILED"

// Destinations
export const DESTINATIONS_LOADING = "DESTINATIONS_LOADING"
export const DESTINATIONS_SUCCESS = "DESTINATIONS_SUCCESS"
export const DESTINATIONS_FAILED = "DESTINATIONS_FAILED"

export const DESTINATION_SELECTED = "DESTINATION_SELECTED"
export const DESTINATION_UNSELECTED = "DESTINATION_UNSELECTED"

export const CREATE_DESTINATION_LOADING = "CREATE_DESTINATION_LOADING"
export const CREATE_DESTINATION_SUCCESS = "CREATE_DESTINATION_SUCCESS"
export const CREATE_DESTINATION_FAILED = "CREATE_DESTINATION_FAILED"

export const UPDATE_DESTINATION_LOADING = "UPDATE_DESTINATION_LOADING"
export const UPDATE_DESTINATION_SUCCESS = "UPDATE_DESTINATION_SUCCESS"
export const UPDATE_DESTINATION_FAILED = "UPDATE_DESTINATION_FAILED"

export const DELETE_DESTINATION_LOADING = "DELETE_DESTINATION_LOADING"
export const DELETE_DESTINATION_SUCCESS = "DELETE_DESTINATION_SUCCESS"
export const DELETE_DESTINATION_FAILED = "DELETE_DESTINATION_FAILED"

// Guides
export const CREATE_GUIDE_LOADING = "CREATE_GUIDE_LOADING"
export const CREATE_GUIDE_SUCCESS = "CREATE_GUIDE_SUCCESS"
export const CREATE_GUIDE_FAILED = "CREATE_GUIDE_FAILED"

export const UPDATE_GUIDE_LOADING = "UPDATE_GUIDE_LOADING"
export const UPDATE_GUIDE_SUCCESS = "UPDATE_GUIDE_SUCCESS"
export const UPDATE_GUIDE_FAILED = "UPDATE_GUIDE_FAILED"

export const DELETE_GUIDE_LOADING = "DELETE_GUIDE_LOADING"
export const DELETE_GUIDE_SUCCESS = "DELETE_GUIDE_SUCCESS"
export const DELETE_GUIDE_FAILED = "DELETE_GUIDE_FAILED"

// Files
export const UPLOAD_IMAGE_LOADING = "UPLOAD_IMAGE_LOADING"
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS"
export const UPLOAD_IMAGE_FAILED = "UPLOAD_IMAGE_FAILED"

export const UPLOAD_VIDEO_LOADING = "UPLOAD_VIDEO_LOADING"
export const UPLOAD_VIDEO_SUCCESS = "UPLOAD_VIDEO_SUCCESS"
export const UPLOAD_VIDEO_FAILED = "UPLOAD_VIDEO_FAILED"

// Feedback
export const FEEDBACK_LOADING = "FEEDBACK_LOADING"
export const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS"
export const FEEDBACK_FAILED = "FEEDBACK_FAILED"

// Example destination
export const EXAMPLE_DESTINATION_LOADING = "EXAMPLE_DESTINATION_LOADING"
export const EXAMPLE_DESTINATION_SUCCESS = "EXAMPLE_DESTINATION_SUCCESS"
export const EXAMPLE_DESTINATION_FAILED = "EXAMPLE_DESTINATION_FAILED"

// Activities
export const ACTIVITIES_LOADING = "ACTIVITIES_LOADING"
export const ACTIVITIES_SUCCESS = "ACTIVITIES_SUCCESS"
export const ACTIVITIES_FAILED = "ACTIVITIES_FAILED"

export const ACTIVITY_SELECTED = "ACTIVITY_SELECTED"
export const ACTIVITY_UNSELECTED = "ACTIVITY_UNSELECTED"

export const SUBCATEGORIES_LOADING = "SUBCATEGORIES_LOADING"
export const SUBCATEGORIES_SUCCESS = "SUBCATEGORIES_SUCCESS"
export const SUBCATEGORIES_FAILED = "SUBCATEGORIES_FAILED"

export const CREATE_ACTIVITIES_LOADING = "CREATE_ACTIVITIES_LOADING"
export const CREATE_ACTIVITIES_SUCCESS = "CREATE_ACTIVITIES_SUCCESS"
export const CREATE_ACTIVITIES_FAILED = "CREATE_ACTIVITIES_FAILED"

export const UPDATE_ACTIVITIES_LOADING = "UPDATE_ACTIVITIES_LOADING"
export const UPDATE_ACTIVITIES_SUCCESS = "UPDATE_ACTIVITIES_SUCCESS"
export const UPDATE_ACTIVITIES_FAILED = "UPDATE_ACTIVITIES_FAILED"

export const DELETE_ACTIVITIES_LOADING = "DELETE_ACTIVITIES_LOADING"
export const DELETE_ACTIVITIES_SUCCESS = "DELETE_ACTIVITIES_SUCCESS"
export const DELETE_ACTIVITIES_FAILED = "DELETE_ACTIVITIES_FAILED"

// Campaigns
export const CAMPAIGNS_LOADING = "CAMPAIGNS_LOADING"
export const CAMPAIGNS_SUCCESS = "CAMPAIGNS_SUCCESS"
export const CAMPAIGNS_FAILED = "CAMPAIGNS_FAILED"

export const CREATE_CAMPAIGN_LOADING = "CREATE_CAMPAIGN_LOADING"
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS"
export const CREATE_CAMPAIGN_FAILED = "CREATE_CAMPAIGN_FAILED"

export const DELETE_CAMPAIGN_LOADING = "DELETE_CAMPAIGN_LOADING"
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS"
export const DELETE_CAMPAIGN_FAILED = "DELETE_CAMPAIGN_FAILED"
