import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Switch, Route } from "react-router-dom"

import Header from "../../components/header"
import Sidebar from "../../components/sidebar"
import ErrorContainer from "../Error/404"
import ActivitiesProfileContainer from "./Profile"
import ActivitiesCampaignContainer from "./Campaign"
import CreateCampaignContainer from "./Campaign/Create"

import { fetchActivities, selectActivity } from "../../actions/activities"

class ActivitiesContainer extends Component {
  componentDidMount() {
    this.fetchActivities()
  }

  componentDidUpdate(prevProps) {
    // Dirty fixup
    const { activities, selectedActivityId } = prevProps
    const selectedActivity = activities.find(activity => activity.id === selectedActivityId)

    if (!selectedActivity && activities.length) {
      this.props.selectActivity(activities[0].id)
    }
  }

  fetchActivities = async () => {
    this.props
      .fetchActivities()
      .then(resp => resp.payload)
      .then(activities => {
        if (activities.length === 0) {
          this.props.history.push("/activities/create")
        } else if (!this.props.selectedActivityId) {
          this.selectActivity(activities[0])
        }
      })
      .catch(() => {})
  }

  selectActivity = activity => {
    this.props
      .selectActivity(activity.id)
      .then(() => {
        this.props.history.push("/activities/campaigns")
      })
      .catch(() => {})
  }

  render() {
    const { activities, selectedActivityId } = this.props
    const selectedActivity = activities.find(activity => activity.id === selectedActivityId)

    return (
      <>
        <Header />
        <div className="tw-has-header tw-has-sidebar">
          <div className="xl:tw-container tw-mx-auto tw-w-full tw-px-4 sm:tw-px-12">
            <Switch>
              {!activities.length && <Route path="*" component={ErrorContainer} />}
              {/* Visible only if user have activities */}
              <Route exact path="/activities/profile" component={ActivitiesProfileContainer} />
              <Route exact path="/activities/campaigns" component={ActivitiesCampaignContainer} />
              <Route exact path="/activities/campaigns/create" component={CreateCampaignContainer} />
              {/* <Route exact path="/activities/team" component={ErrorContainer} /> */}
              {/* <Route exact path="/activities/billing" component={ErrorContainer} /> */}
              {/* Error page */}
              <Route path="*" component={ErrorContainer} />
            </Switch>
          </div>
        </div>
        <Sidebar type="activities" selectedProject={selectedActivity} />
      </>
    )
  }
}

ActivitiesContainer.propTypes = {
  fetchActivities: PropTypes.func.isRequired,
  selectActivity: PropTypes.func.isRequired,
  selectedActivityId: PropTypes.number,
  activities: PropTypes.arrayOf(PropTypes.shape()),
}

ActivitiesContainer.defaultProps = {
  selectedActivityId: null,
  activities: [],
}

const mapStateToProps = state => ({
  activities: state.activities.activities,
  selectedActivityId: state.activities.selectedActivityId,
})

const mapDispatchToProps = dispatch => ({
  fetchActivities: () => dispatch(fetchActivities()),
  selectActivity: id => dispatch(selectActivity(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesContainer)
