import { FEEDBACK_LOADING, FEEDBACK_SUCCESS, FEEDBACK_FAILED } from "../constants/actionTypes"

const initialState = {
  isLoading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    // =============================================================================
    case FEEDBACK_LOADING:
      return { ...state, isLoading: true }
    case FEEDBACK_SUCCESS:
      return { ...state, isLoading: false }
    case FEEDBACK_FAILED:
      return { ...state, isLoading: false }
    // =============================================================================
    default:
      break
  }
  return state
}
