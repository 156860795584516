export const classNames = classes => {
  return Object.entries(classes)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)
    .join(" ")
}

export const twClassNames = (...classes) => {
  return classes.filter(Boolean).join(" ")
}
