import React from "react"
import PropTypes from "prop-types"
import { NavDropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"

import flag_fi from "../assets/language_fi.png"
import flag_en from "../assets/language_en.png"

const LanguagePicker = ({ absolute, className }) => {
  const { i18n, t } = useTranslation()

  const changeLanguage = lang => {
    // Update application language
    i18n.changeLanguage(lang)
    document.documentElement.lang = lang
  }

  return (
    <NavDropdown
      id="language-picker-dropdown"
      alignRight
      title={
        <img
          src={i18n.language === "fi" ? flag_fi : flag_en}
          alt="Language flag"
          width="28"
          height="20"
          className="language-picker-flag"
        />
      }
      className={[className, absolute ? "language-picker" : ""]}>
      <NavDropdown.Header>{t("header.languagePicker.title")}</NavDropdown.Header>
      <NavDropdown.Item onClick={() => changeLanguage("en")}>English - EN</NavDropdown.Item>
      <NavDropdown.Item onClick={() => changeLanguage("fi")}>Suomeksi - FI</NavDropdown.Item>
    </NavDropdown>
  )
}

LanguagePicker.propTypes = {
  absolute: PropTypes.bool,
}
LanguagePicker.defaultProps = {
  absolute: false,
}

export default LanguagePicker
