/* eslint-disable */
import React from "react"
import { HiPlay } from "react-icons/hi"
import { Image } from "react-bootstrap"
import { classNames } from "../../../../helpers/classNames"

export const GuideVideoThumbnail = ({ data, list, preview, onClick }) => {
  const classes = classNames({
    "image-container": true,
    "position-relative": true,
    list: list,
    preview: preview,
  })

  const onClickFunction = value => {
    if (onClick) {
      return onClick(value)
    }
    return null
  }

  return (
    <div className={classes} onClick={() => onClickFunction(data)}>
      <Image src={data.thumbnail} />
      <HiPlay className="position-absolute video-play-button" />
    </div>
  )
}
