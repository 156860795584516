import React, { useState, useEffect, Fragment, useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Toast } from "react-bootstrap"
import { useHistory, useParams, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Tab, Menu, Transition } from "@headlessui/react"
import { HiChevronDown } from "react-icons/hi"

import Header from "../../components/header"
import ConfirmationModal from "../../components/confirmation"
import { twClassNames } from "../../helpers/classNames"

import { fetchAllDestinations } from "../../actions/destinations"
import { fetchActivities } from "../../actions/activities"
import { TabActivities, TabHandbooks } from "./components"

const DropdownButton = ({ title, children }) => (
  <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
    <Menu.Button className="tw-btn tw-btn-primary tw-min-w-[180px]">
      {title}
      <HiChevronDown className="tw-absolute tw-right-0 tw-mr-4 tw-text-white" aria-hidden="true" />
    </Menu.Button>
    <Transition
      as={Fragment}
      enter="tw-transition tw-ease-out tw-duration-100"
      enterFrom="tw-transform tw-opacity-0 tw-scale-95"
      enterTo="tw-transform tw-opacity-100 tw-scale-100"
      leave="tw-transition tw-ease-in tw-duration-75"
      leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
      leaveTo="tw-transform tw-opacity-0 tw-scale-95">
      <Menu.Items
        className="tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-w-56 tw-origin-top-right tw-rounded-md 
      tw-bg-white tw-shadow-lg tw-border tw-border-dark-15 focus:tw-outline-none">
        <div className="tw-py-1">{children}</div>
      </Menu.Items>
    </Transition>
  </Menu>
)

const ProjectsContainer = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const destinations = useSelector(state => state.destinations.destinations)
  const activities = useSelector(state => state.activities.activities)
  const isLoading = useSelector(state => state.destinations.isLoading || state.activities.isLoading)

  const [confirmation, setConfirmation] = useState({ show: false, callback: null, title: "", content: "" })
  const [toast, setToast] = useState({ display: false, type: null, message: null })
  const [query, setQuery] = useState("")
  const [maxRows, setMaxRows] = useState(5)
  const { active_tab } = useParams()

  const filtereDestinations = useMemo(() => {
    return query === ""
      ? destinations
      : destinations.filter(item => {
          return item.name.toLowerCase().includes(query.toLowerCase())
        })
  }, [query, destinations])

  const filtereActivities = useMemo(() => {
    return query === ""
      ? activities
      : activities.filter(item => {
          return item.name.toLowerCase().includes(query.toLowerCase())
        })
  }, [query, activities])

  const fetchContent = useCallback(() => {
    dispatch(fetchAllDestinations()).catch(() => {})
    dispatch(fetchActivities()).catch(() => {})
  }, [dispatch])

  useEffect(() => {
    // Called once on page load
    fetchContent() // Fetch activities and destinations

    // Get user preferences
    const _maxRows = Number(localStorage.getItem("display.max_rows"))
    if (_maxRows) {
      setMaxRows(_maxRows)
    }
  }, [fetchContent])

  useEffect(() => {
    // Save user preferences
    localStorage.setItem("display.max_rows", maxRows.toString())
  }, [maxRows])

  const hideConfirmation = () => setConfirmation({ show: false, callback: null, title: "", content: "" })

  return (
    <>
      {toast && toast.display && (
        <Toast
          className={"fixed-toast " + toast.type}
          onClose={() => setToast({ show: false, callback: null, title: "", content: "" })}
          show={toast.display}
          delay={5000}
          autohide>
          <Toast.Header>
            <strong className="tw-mr-auto">{t("common.notifications.type." + toast.type)}</strong>
          </Toast.Header>
          <Toast.Body>{t("projects.notifications." + toast.content.message)}</Toast.Body>
        </Toast>
      )}
      <ConfirmationModal
        show={confirmation.show}
        title={confirmation.title}
        content={confirmation.content}
        danger={confirmation.danger}
        onOK={confirmation.callback}
        onCancel={hideConfirmation}
      />
      <Header />
      <div className="xl:tw-container tw-mx-auto tw-w-full tw-mt-24 tw-px-4 sm:tw-px-12">
        <h1 className="tw-font-display tw-font-semibold tw-text-4xl tw-mb-6">{t("projects.title")}</h1>
        <div className="tw-mb-12 tw-w-full">
          <Tab.Group
            selectedIndex={active_tab === "activities" ? 1 : 0}
            onChange={index => history.push(index === 0 ? "/handbooks" : "/activities")}>
            <div className="tw-shadow tw-bg-white tw-rounded">
              {/* Header group */}
              <div className="tw-flex tw-p-6 tw-pb-2 tw-flex-col-reverse md:tw-flex-row">
                {/* Search bar */}
                <div className="tw-grow md:tw-mr-6 max-md:tw-mt-4">
                  <div
                    className="tw-relative tw-w-full tw-rounded-lg tw-bg-white tw-text-left tw-cursor-default 
                    tw-h-10 tw-border tw-border-dark-15 focus:tw-outline-none">
                    <input
                      id="search"
                      type="text"
                      onChange={event => setQuery(event.target.value)}
                      className="tw-w-full tw-border-none tw-py-2 tw-pl-3 tw-pr-10 tw-leading-5 tw-rounded-lg
                        sm:tw-text-sm focus:tw-outline-primary-50"
                      placeholder={t("common.search.placeholder")}
                      spellCheck="false"
                    />
                  </div>
                </div>
                {/* Button group */}
                <div className="tw-flex tw-justify-center md:tw-justify-end tw-content-end tw-space-x-4">
                  <button
                    className="tw-btn tw-btn-outline tw-min-w-[180px]"
                    disabled={isLoading}
                    onClick={fetchContent}>
                    <span>{t("projects.button.refresh")}</span>
                  </button>
                  <DropdownButton title={t("projects.button.create.title")}>
                    <Menu.Item>
                      <Link
                        to="/handbook/create"
                        className="tw-block tw-px-4 tw-py-2
                          hover:tw-bg-gray hover:tw-no-underline hover:tw-text-dark">
                        <span>{t("projects.button.create.handbook")}</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to="/activities/create"
                        className="tw-block tw-px-4 tw-py-2
                          hover:tw-bg-gray hover:tw-no-underline hover:tw-text-dark">
                        <span>{t("projects.button.create.activity")}</span>
                      </Link>
                    </Menu.Item>
                  </DropdownButton>
                </div>
              </div>
              {/* Tabs */}
              <Tab.List className="tw-border-b-2 tw-border-gray tw-mb-6">
                <ul className="tw-flex tw-flex-wrap -tw-mb-px tw-mx-6 tw-space-x-2">
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <li
                        className={twClassNames(
                          "tw-inline-block tw-text-dark-50 tw-cursor-pointer tw-py-4 tw-px-12 tw-text-center",
                          "hover:tw-text-dark-75 hover:tw-border-dark-25 tw-font-semibold",
                          "tw-border-transparent tw-border-b-2 focus:tw-outline-none",
                          selected && "!tw-text-dark !tw-border-primary"
                        )}>
                        {t("projects.handbooks", { length: filtereDestinations.length })}
                      </li>
                    )}
                  </Tab>
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <li
                        className={twClassNames(
                          "tw-inline-block tw-text-dark-50 tw-cursor-pointer tw-py-4 tw-px-12 tw-text-center",
                          "hover:tw-text-dark-75 hover:tw-border-dark-25 tw-font-semibold",
                          "tw-border-transparent tw-border-b-2 focus:tw-outline-none",
                          selected && "!tw-text-dark !tw-border-primary"
                        )}>
                        {t("projects.activities", { length: filtereActivities.length })}
                      </li>
                    )}
                  </Tab>
                </ul>
              </Tab.List>
              {/* Content */}
              <div className="tw-px-6">
                <Tab.Panels className="tw-w-full">
                  {/* Handbooks */}
                  <TabHandbooks
                    destinations={filtereDestinations}
                    query={query}
                    maxRows={maxRows}
                    onMaxRowSelect={setMaxRows}
                    setToast={setToast} // TODO: Replace with common toast
                    setConfirmation={setConfirmation} // TODO: Replace with common toast
                    hideConfirmation={hideConfirmation} // TODO: Replace with common toast
                  />
                  {/* Activities */}
                  <TabActivities
                    activities={filtereActivities}
                    query={query}
                    maxRows={maxRows}
                    onMaxRowSelect={setMaxRows}
                    setConfirmation={setConfirmation} // TODO: Replace with common toast
                    hideConfirmation={hideConfirmation} // TODO: Replace with common toast
                  />
                </Tab.Panels>
              </div>
            </div>
          </Tab.Group>
        </div>
      </div>
    </>
  )
}

ProjectsContainer.propTypes = {
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  ),
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  ),
  isLoading: PropTypes.bool,
}

ProjectsContainer.defaultProps = {
  destinations: [],
  activities: [],
  isLoading: false,
}

export default ProjectsContainer
