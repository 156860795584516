import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import { HiX } from "react-icons/hi"

import Header from "../../../components/header"
import Progressbar from "../../../components/progressbar"
import PostcodeSelect from "../../../components/postcodeSelect"
import SearchSelectMultiple from "../../../components/searchSelectMultiple"
import Breadcrumb from "../../../components/breadcrumb"
import Tooltip from "../../../components/tooltip"
import ConfirmationModal from "../../../components/confirmation"
import { twClassNames } from "../../../helpers/classNames"

import { createActivity, fetchActivityCategories } from "../../../actions/activities"

const PHASES = 2
const breadcrumbs = [
  { name: "activities.create.breadcrumb.home", url: "/activities" },
  { name: "activities.create.breadcrumb.create" },
]

const validationSchema = (phase, t) => {
  if (phase === 1) {
    return Yup.object().shape({
      address: Yup.string()
        .min(3, t("common.validation.invalidMinLength"))
        .max(120, t("common.validation.invalidMaxLength"))
        .required(t("common.validation.required")),
      postal_code: Yup.string().required(t("common.validation.required")),
      phone: Yup.string()
        .min(6, t("common.validation.invalidMinLength"))
        .max(30, t("common.validation.invalidMaxLength"))
        .matches(/^\+(?:[0-9]?){6,14}[0-9]$/, t("common.validation.invalidPhone")),
    })
  } else if (phase === 2) {
    return Yup.object().shape(
      {
        name_fi: Yup.string()
          .when(["name_en"], {
            is: name_en => !name_en,
            then: Yup.string().required(t("common.validation.eitherRequired")),
          })
          .min(3, t("common.validation.invalidMinLength"))
          .max(120, t("common.validation.invalidMaxLength")),
        name_en: Yup.string()
          .when(["name_fi"], {
            is: name_fi => !name_fi,
            then: Yup.string().required(t("common.validation.eitherRequired")),
          })
          .min(3, t("common.validation.invalidMinLength"))
          .max(120, t("common.validation.invalidMaxLength")),
        description_fi: Yup.string().when(["description_en"], {
          is: description_en => !description_en,
          then: Yup.string().required(t("common.validation.eitherRequired")),
        }),
        description_en: Yup.string().when(["description_fi"], {
          is: description_fi => !description_fi,
          then: Yup.string().required(t("common.validation.eitherRequired")),
        }),
        categories: Yup.string().required(t("common.validation.required")),
      },
      [
        ["name_fi", "name_en"],
        ["description_fi", "description_en"],
      ]
    )
  }
}

const Form1 = ({ t, formik, isLoading, isValidCLass, onPrevious, onNext }) => (
  <div className="tw-flex tw-p-6 max-md:tw-flex-col">
    {/* Left side */}
    <div className="tw-min-w-[300px] tw-w-full md:tw-w-1/3 tw-mb-6 md:tw-border-r max-md:tw-border-b">
      {/* Company informations */}
      <div className="tw-relative md:tw-pr-6">
        <h5 className="tw-text-lg tw-mb-2">{t("activities.create.form.phase_1.name")}</h5>
        <p className="tw-text-sm tw-text-dark-75">{t("activities.create.form.phase_1.info")}</p>
      </div>
    </div>
    {/* Right side */}
    <div className="tw-w-full md:tw-w-2/3">
      <div className="tw-h-full tw-flex tw-flex-col md:tw-pl-6">
        <div className="tw-grid tw-grid-cols md:tw-grid-cols-6 tw-gap-x-2 tw-gap-y-3 md:tw-gap-y-4">
          {/* Company name */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="company" className="tw-form-label">
              {t("activities.create.form.company")}
              <span className="tw-form-label-optional">({t("common.validation.optional")})</span>
              <Tooltip message="activities.create.tooltips.company" displayLabel />
            </label>
            <input
              type="text"
              id="company"
              name="company"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.company}
              className={twClassNames(isValidCLass("company"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.company}</div>
          </div>
          {/* Address */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="address" className="tw-form-label">
              {t("activities.create.form.address")}
            </label>
            <input
              type="text"
              id="address"
              name="address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              className={twClassNames(isValidCLass("address"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.address}</div>
          </div>
          {/* Postal Code */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="postal_code" className="tw-form-label">
              {t("activities.create.form.postal_code")}
            </label>
            <PostcodeSelect
              id="postal_code"
              name="postal_code"
              value={formik.values.postal_code}
              onBlur={formik.handleBlur}
              onChange={value => {
                formik.setFieldValue("municipal", value.city)
                formik.setFieldValue("postal_code", value.postcode)
              }}
              errors={formik.touched.postal_code && formik.errors.postal_code}
            />
          </div>
          {/* Municipal */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="municipal" className="tw-form-label">
              {t("activities.create.form.municipal")}
              <Tooltip message="activities.create.tooltips.municipal" />
            </label>
            <input value={formik.values.municipal} className="tw-hidden tw-peer" readOnly />
            <p
              className="tw-block tw-w-full tw-pt-2 tw-px-3 tw-capitalize 
              tw-text-base sm:tw-text-sm peer-disabled:tw-text-dark-50 tw-value-field">
              {formik.values.municipal}
            </p>
          </div>
          {/* Phone */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="phone" className="tw-form-label">
              {t("activities.create.form.phone")}
              <span className="tw-form-label-optional">({t("common.validation.optional")})</span>
              <Tooltip message="activities.create.tooltips.phone" />
            </label>
            <input
              type="phone"
              id="phone"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              className={twClassNames(isValidCLass("phone"), "tw-form-control")}
            />
            <div className="tw-invalid-tooltip">{formik.errors.phone}</div>
          </div>
        </div>
        <div className="tw-flex tw-grow tw-place-content-between tw-items-end tw-pt-12">
          <button
            type="button"
            onClick={onPrevious}
            className="tw-btn tw-btn-outline tw-w-full tw-max-w-[180px]"
            disabled={isLoading}>
            {t("activities.create.button.cancel")}
          </button>
          <button
            type="button"
            onClick={onNext}
            className="tw-btn tw-btn-primary tw-w-full tw-max-w-[180px]"
            disabled={isLoading}>
            {t("activities.create.button.next")}
          </button>
        </div>
      </div>
    </div>
  </div>
)

const Form2 = ({ t, formik, isLoading, isValidCLass, onPrevious, onNext, categories }) => (
  <div className="tw-flex tw-p-6 max-md:tw-flex-col">
    {/* Left side */}
    <div className="tw-min-w-[300px] tw-w-full md:tw-w-1/3 tw-mb-6 md:tw-border-r max-md:tw-border-b">
      {/* Activities informations */}
      <div className="tw-relative md:tw-pr-6">
        <h5 className="tw-text-lg tw-mb-2">{t("activities.create.form.phase_2.name")}</h5>
        <p className="tw-text-sm tw-text-dark-75">{t("activities.create.form.phase_2.info")}</p>
      </div>
    </div>
    {/* Right side */}
    <div className="tw-w-full md:tw-w-2/3">
      <div className="tw-h-full tw-flex tw-flex-col md:tw-pl-6">
        <div className="tw-grid tw-grid-cols md:tw-grid-cols-6 tw-gap-x-2 tw-gap-y-3 md:tw-gap-y-4">
          {/* Name FI */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="name_fi" className="tw-form-label">
              {t("activities.create.form.name_fi")}
              <Tooltip message="activities.create.tooltips.name_fi" />
            </label>
            <input
              type="text"
              id="name_fi"
              name="name_fi"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name_fi}
              className={twClassNames(isValidCLass("name_fi"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.name_fi}</div>
          </div>
          {/* Name EN */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="name_en" className="tw-form-label tw-flex tw-justify-betweesn">
              {t("activities.create.form.name_en")}
            </label>
            <input
              type="text"
              id="name_en"
              name="name_en"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name_en}
              className={twClassNames(isValidCLass("name_en"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.name_en}</div>
          </div>
          {/* Description FI */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="description_fi" className="tw-form-label">
              {t("activities.create.form.description_fi")}
              <Tooltip message="activities.create.tooltips.description_fi" />
            </label>
            <textarea
              type="text"
              id="description_fi"
              name="description_fi"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description_fi}
              className={twClassNames(isValidCLass("description_fi"), "tw-form-control tw-min-h-[110px]")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.description_fi}</div>
          </div>
          {/* Description EN */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="description_en" className="tw-form-label">
              {t("activities.create.form.description_en")}
            </label>
            <textarea
              type="text"
              id="description_en"
              name="description_en"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description_en}
              className={twClassNames(isValidCLass("description_en"), "tw-form-control tw-min-h-[110px]")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.description_en}</div>
          </div>
          {/* Category */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="categories" className="tw-form-label">
              {t("activities.create.form.categories")}
              <Tooltip message="activities.create.tooltips.categories" />
            </label>
            <SearchSelectMultiple
              id="categories"
              name="categories"
              data={categories}
              nameKey="name"
              valueKey="id"
              indexKey="id"
              placeholder={t("activities.create.placeholders.categories")}
              onChange={value => formik.setFieldValue("categories", value)}
              onBlur={formik.handleBlur}
              value={formik.values.categories}
              errors={formik.touched.categories && formik.errors.categories}
            />
          </div>
          <div className="tw-relative tw-col-span-6 tw-flex tw-overflow-x-auto">
            {formik.values.categories &&
              categories
                .filter(x => formik.values.categories.indexOf(x.id) !== -1)
                .map((tag, index) => (
                  <div
                    key={index}
                    className="tw-bg-dark-25 tw-text-dark tw-px-3 tw-py-1 tw-mr-1 tw-mb-2 
                tw-rounded tw-text-sm tw-cursor-default tw-min-w-fit">
                    {tag.name}
                    <HiX
                      className="tw-ml-1 tw-mb-0.5 tw-cursor-pointer tw-align-middle"
                      onClick={() =>
                        formik.setFieldValue(
                          "categories",
                          formik.values.categories.filter(x => x !== tag.id)
                        )
                      }
                    />
                  </div>
                ))}
          </div>
        </div>
        <div className="tw-flex tw-grow tw-place-content-between tw-items-end tw-pt-12">
          <button
            type="button"
            onClick={onPrevious}
            className="tw-btn tw-btn-outline tw-w-full tw-max-w-[180px]"
            disabled={isLoading}>
            {t("activities.create.button.previous")}
          </button>
          <button
            type="button"
            onClick={onNext}
            className="tw-btn tw-btn-primary tw-w-full tw-max-w-[180px]"
            disabled={isLoading}>
            {t("activities.create.button.next")}
          </button>
        </div>
      </div>
    </div>
  </div>
)

const CreateActivityContainer = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const activityCategories = useSelector(state => state.activities.categories)
  const isLoading = useSelector(state => state.activities.isLoading)

  const [phase, setPhase] = useState(1)
  const [confirmation, setConfirmation] = useState({ show: false, callback: null, title: "", content: "" })

  useEffect(() => {
    if (activityCategories?.length === 0) {
      dispatch(fetchActivityCategories())
    }
  }, [activityCategories, dispatch])

  const formik = useFormik({
    initialValues: {
      company: "",
      address: "",
      postal_code: "",
      municipal: "akaa",
      name_fi: "",
      name_en: "",
      description_fi: "",
      description_en: "",
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: validationSchema(phase, t),
    onSubmit: values => onSave(values),
  })

  const onSave = values => {
    const data = {
      ...values,
      name: values.name_fi || values.name_en,
      description: values.description_fi || values.description_en,
      category: values.categories[0], // TODO: Remove later
    }
    dispatch(createActivity(data))
      .then(() => history.push("/activities/campaigns"))
      .catch(() => {})
  }

  const onNext = () => {
    formik.validateForm().then(errors => {
      formik.setErrors(errors)
      formik.setFieldTouched(Object.keys(errors), true)
      if (!Object.keys(errors).length) {
        if (phase === PHASES) {
          formik.handleSubmit()
        } else {
          setPhase(phase + 1)
        }
      }
    })
  }

  const onPrevious = () => {
    if (phase <= 1) {
      if (formik.dirty) {
        setConfirmation({
          show: true,
          callback: () => history.push("/activities"),
          title: "dirty",
          content: "dirty",
          danger: true,
        })
      } else {
        history.push("/activities")
      }
    } else {
      setPhase(phase - 1)
    }
  }

  const isValidCLass = field => formik.touched[field] && formik.errors[field] && "tw-is-invalid"

  return (
    <>
      <Header />
      <div className="tw-has-header xl:tw-container tw-mx-auto tw-w-full tw-px-4 sm:tw-px-12">
        <Breadcrumb data={breadcrumbs} />
        <h1 className="tw-font-display tw-font-semibold tw-text-4xl tw-mb-6">{t("activities.create.title")}</h1>
        <div className="tw-mb-12 tw-w-full">
          <div className="tw-mb-6 tw-shadow tw-bg-white tw-rounded">
            <form noValidate onSubmit={formik.handleSubmit}>
              {(() => {
                switch (phase) {
                  case 1:
                    return (
                      <Form1
                        t={t}
                        formik={formik}
                        isLoading={isLoading}
                        isValidCLass={isValidCLass}
                        onPrevious={onPrevious}
                        onNext={onNext}
                      />
                    )
                  case 2:
                    return (
                      <Form2
                        t={t}
                        formik={formik}
                        isLoading={isLoading}
                        isValidCLass={isValidCLass}
                        onPrevious={onPrevious}
                        onNext={onNext}
                        categories={activityCategories}
                      />
                    )
                  default:
                    return null
                }
              })()}
            </form>
          </div>
        </div>
        <div className="tw-mb-12 tw-w-full">
          <Progressbar phases={PHASES} current={phase} translationKeys="activities.create.progress" />
        </div>
        <ConfirmationModal
          show={confirmation.show}
          title={confirmation.title}
          content={confirmation.content}
          danger={confirmation.danger}
          onOK={confirmation.callback}
          onCancel={() => setConfirmation({ show: false, callback: null, title: "", content: "" })}
        />
      </div>
    </>
  )
}

CreateActivityContainer.propTypes = {
  isLoading: PropTypes.bool,
  activityCategories: PropTypes.array,
}

CreateActivityContainer.defaultProps = {
  isLoading: false,
  activityCategories: [],
}

export default CreateActivityContainer
