import React, { useState, useEffect, useRef, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Toast, Image } from "react-bootstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import imageCompression from "browser-image-compression"
import { HiLocationMarker, HiPhone, HiTrash, HiOutlinePhotograph, HiX } from "react-icons/hi"
import { Switch } from "@headlessui/react"

import PostcodeSelect from "../../../components/postcodeSelect"
import SearchSelectMultiple from "../../../components/searchSelectMultiple"
import DragZone from "../../../components/dragZone"
import Tooltip from "../../../components/tooltip"
import Emulator from "../../../components/emulator"

import { updateActivity, fetchActivityCategories } from "../../../actions/activities"

import { twClassNames } from "../../../helpers/classNames"
import { capitalize } from "../../../helpers/capitalize"
import { withHttp } from "../../../helpers/withHttp"

import { MAX_IMAGE_FILE_SIZE } from "../../../constants/fileHandling"

const validationSchema = t => {
  return Yup.object().shape(
    {
      address: Yup.string()
        .min(3, t("common.validation.invalidMinLength"))
        .max(120, t("common.validation.invalidMaxLength"))
        .required(t("common.validation.required")),
      postal_code: Yup.string().required(t("common.validation.required")),
      phone: Yup.string()
        .min(6, t("common.validation.invalidMinLength"))
        .max(30, t("common.validation.invalidMaxLength"))
        .matches(/^\+(?:[0-9]?){6,14}[0-9]$/, t("common.validation.invalidPhone")),
      name_en: Yup.string()
        .when(["name_fi"], {
          is: name_fi => !name_fi,
          then: Yup.string().required(t("common.validation.eitherRequired")),
        })
        .min(3, t("common.validation.invalidMinLength"))
        .max(120, t("common.validation.invalidMaxLength")),
      name_fi: Yup.string()
        .when(["name_en"], {
          is: name_en => !name_en,
          then: Yup.string().required(t("common.validation.eitherRequired")),
        })
        .min(3, t("common.validation.invalidMinLength"))
        .max(120, t("common.validation.invalidMaxLength")),
      description_en: Yup.string().when(["description_fi"], {
        is: description_fi => !description_fi,
        then: Yup.string().required(t("common.validation.eitherRequired")),
      }),
      description_fi: Yup.string().when(["description_en"], {
        is: description_en => !description_en,
        then: Yup.string().required(t("common.validation.eitherRequired")),
      }),
      categories: Yup.string().required(t("common.validation.required")),
    },
    [
      ["name_fi", "name_en"],
      ["description_fi", "description_en"],
    ]
  )
}

const Preview = ({ data, t }) => {
  const [lng, setPreviewLanguage] = useState("fi")

  return (
    <Emulator lng={lng} setPreviewLanguage={setPreviewLanguage}>
      {/* Image */}
      <div className="tw-relative tw-h-[247px] tw-w-full tw-bg-dark-25 tw-rounded-t-[25px]">
        <div className="tw-h-[25px] tw-w-full tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-bg-white tw-rounded-t-[25px]" />
        {data.image && (
          <img
            alt=""
            src={typeof data.image === "string" ? data.image : URL.createObjectURL(data.image)}
            className="tw-h-full tw-w-full tw-object-cover tw-rounded-t-[25px]"
          />
        )}
      </div>
      {/* Text */}
      <div className="tw-h-full tw-w-full tw-flex tw-flex-col tw-px-4">
        <span className="tw-text-xs tw-text-dark-50 tw-mb-2">{data.company}</span>
        <span className="tw-font-semibold tw-font-display tw-text-lg tw-leading-tight tw-mb-4">
          {data["name_" + lng]}
        </span>
        <div className="tw-mb-2">
          {data.address && (
            <p className="tw-space-x-2 tw-mb-0">
              <HiLocationMarker className="tw-text-primary" />
              <span className="tw-text-sm">
                {data.address}, {data?.postal_code} {capitalize(data?.municipal)}
              </span>
            </p>
          )}
          {data.phone && (
            <p className="tw-space-x-2 tw-mb-0">
              <HiPhone className="tw-text-primary" />
              <span className="tw-text-sm">{data.phone}</span>
            </p>
          )}
        </div>
        {data["description_" + lng] && (
          <div className="tw-mb-2">
            <p className="tw-font-semibold tw-font-display tw-text-sm tw-mb-2">
              {t("activities.profile.emulator.description", { lng })}
            </p>
            <p className="tw-text-sm tw-text-dark-75 tw-line-clamp-6">{data["description_" + lng]}</p>
          </div>
        )}
        {data.url && (
          <button className="tw-w-full tw-btn tw-btn-primary" type="button">
            {t("activities.profile.form.link_title_options." + data.link_title, { lng })}
          </button>
        )}
      </div>
    </Emulator>
  )
}

const Form = ({ formik, categories, isLoading, t }) => {
  const imageRef = useRef()

  const onReset = () => {
    formik.handleReset()
    imageRef.current.value = null //Fix: reset image file selector
  }

  const isValidCLass = field => formik.touched[field] && formik.errors[field] && "tw-is-invalid"

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className="tw-w-full md:tw-pr-6 max-md:tw-pb-6">
        {!!formik.values.visit_finland_id && (
          <div className="tw-bg-light tw-p-4 tw-rounded-2xl tw-mb-8">
            {/* Business Finland Datahub informations */}
            <div className="tw-relative tw-col-span-6">
              <h5>{t("activities.profile.form.datahubSubtitle.name")}</h5>
              <p className="tw-text-sm tw-text-dark-75">{t("activities.profile.form.datahubSubtitle.info")}</p>
            </div>
            {/* Integration */}
            <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
              <label htmlFor="visit_finland_integration" className="tw-form-label">
                {t("activities.profile.form.integration")}
              </label>
              <div className="tw-flex tw-space-x-2">
                <Switch
                  id="visit_finland_integration"
                  name="visit_finland_integration"
                  className="tw-items-center"
                  checked={formik.values.visit_finland_integration}
                  onChange={value => formik.setFieldValue("visit_finland_integration", value)}
                  as={Fragment}>
                  {({ checked }) => (
                    <button
                      className={twClassNames(
                        checked ? "tw-bg-primary" : "tw-bg-dark-25",
                        "tw-relative tw-inline-flex tw-h-6 tw-w-11 tw-items-center tw-rounded-full",
                        "focus-within:tw-outline-none focus-within:tw-ring-2 focus-within:tw-ring-primary-50"
                      )}>
                      <span
                        className={twClassNames(
                          checked ? "tw-translate-x-6" : "tw-translate-x-1",
                          "tw-inline-block tw-h-4 tw-w-4 tw-transform tw-rounded-full tw-bg-white tw-transition"
                        )}
                      />
                    </button>
                  )}
                </Switch>
              </div>
            </div>
          </div>
        )}
        <fieldset
          disabled={formik.values.visit_finland_integration}
          className="tw-grid tw-grid-cols md:tw-grid-cols-6 tw-gap-x-2 tw-gap-y-3 md:tw-gap-y-4">
          {/* Company informations */}
          <div className="tw-relative tw-col-span-6 tw-border-b">
            <h5>{t("activities.profile.form.companySubtitle.name")}</h5>
            <p className="tw-text-sm tw-text-dark-75">{t("activities.profile.form.companySubtitle.info")}</p>
          </div>
          {/* Company name */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="company" className="tw-form-label">
              {t("activities.profile.form.company")}
              <span className="tw-form-label-optional">({t("common.validation.optional")})</span>
              <Tooltip message="activities.profile.tooltips.company" displayLabel />
            </label>
            <input
              type="text"
              id="company"
              name="company"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.company}
              className={twClassNames(isValidCLass("company"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.company}</div>
          </div>
          {/* Address */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="address" className="tw-form-label">
              {t("activities.profile.form.address")}
            </label>
            <input
              type="text"
              id="address"
              name="address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              className={twClassNames(isValidCLass("address"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.address}</div>
          </div>
          {/* Postal Code */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="postal_code" className="tw-form-label">
              {t("activities.profile.form.postal_code")}
            </label>
            <PostcodeSelect
              id="postal_code"
              name="postal_code"
              value={formik.values.postal_code}
              onBlur={formik.handleBlur}
              onChange={value => {
                formik.setFieldValue("municipal", value.city)
                formik.setFieldValue("postal_code", value.postcode)
              }}
              errors={formik.touched.postal_code && formik.errors.postal_code}
            />
          </div>
          {/* Municipal */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="municipal" className="tw-form-label">
              {t("activities.profile.form.municipal")}
              <Tooltip message="activities.profile.tooltips.municipal" />
            </label>
            <input value={formik.values.municipal} className="tw-hidden tw-peer" readOnly />
            <p
              className="tw-block tw-w-full tw-pt-2 tw-px-3 tw-capitalize 
              tw-text-base sm:tw-text-sm peer-disabled:tw-text-dark-50 tw-value-field">
              {formik.values.municipal}
            </p>
          </div>
          {/* Phone */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="phone" className="tw-form-label">
              {t("activities.profile.form.phone")}
              <span className="tw-form-label-optional">({t("common.validation.optional")})</span>
            </label>
            <input
              type="phone"
              id="phone"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              className={twClassNames(isValidCLass("phone"), "tw-form-control")}
            />
            <div className="tw-invalid-tooltip">{formik.errors.phone}</div>
          </div>
          {/* Activities informations */}
          <div className="tw-relative tw-col-span-6 tw-border-b">
            <h5 className="tw-mt-4">{t("activities.profile.form.activitySubtitle.name")}</h5>
            <p className="tw-text-sm tw-text-dark-75">{t("activities.profile.form.activitySubtitle.info")}</p>
          </div>
          {/* Name FI */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="name_fi" className="tw-form-label">
              {t("activities.profile.form.name_fi")}
              <Tooltip message="activities.profile.tooltips.name_fi" />
            </label>
            <input
              type="text"
              id="name_fi"
              name="name_fi"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name_fi}
              className={twClassNames(isValidCLass("name_fi"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.name_fi}</div>
          </div>
          {/* Name EN */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="name_en" className="tw-form-label tw-flex tw-justify-betweesn">
              {t("activities.profile.form.name_en")}
            </label>
            <input
              type="text"
              id="name_en"
              name="name_en"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name_en}
              className={twClassNames(isValidCLass("name_en"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.name_en}</div>
          </div>
          {/* Description FI */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="description_fi" className="tw-form-label">
              {t("activities.profile.form.description_fi")}
              <Tooltip message="activities.profile.tooltips.description_fi" />
            </label>
            <textarea
              type="text"
              id="description_fi"
              name="description_fi"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description_fi}
              className={twClassNames(isValidCLass("description_fi"), "tw-form-control tw-min-h-[150px]")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.description_fi}</div>
          </div>
          {/* Description EN */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="description_en" className="tw-form-label">
              {t("activities.profile.form.description_en")}
            </label>
            <textarea
              type="text"
              id="description_en"
              name="description_en"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description_en}
              className={twClassNames(isValidCLass("description_en"), "tw-form-control tw-min-h-[150px]")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.description_en}</div>
          </div>
          {/* Category */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="categories" className="tw-form-label">
              {t("activities.profile.form.categories")}
              <Tooltip message="activities.profile.tooltips.categories" />
            </label>
            <SearchSelectMultiple
              id="categories"
              name="categories"
              data={categories}
              nameKey="name"
              valueKey="id"
              indexKey="id"
              placeholder={t("activities.profile.placeholders.categories")}
              onChange={value => formik.setFieldValue("categories", value)}
              onBlur={formik.handleBlur}
              value={formik.values.categories}
              errors={formik.touched.categories && formik.errors.categories}
            />
          </div>
          <div className="tw-relative tw-col-span-6 tw-flex tw-overflow-x-auto">
            {formik.values.categories &&
              categories
                .filter(x => formik.values.categories.indexOf(x.id) !== -1)
                .map((tag, index) => (
                  <div
                    key={index}
                    className="tw-bg-dark-25 tw-text-dark tw-px-3 tw-py-1 tw-mr-1 tw-mb-2 
                tw-rounded tw-text-sm tw-cursor-default tw-min-w-fit">
                    {tag.name}
                    <HiX
                      className="tw-ml-1 tw-mb-0.5 tw-cursor-pointer tw-align-middle"
                      onClick={() =>
                        formik.setFieldValue(
                          "categories",
                          formik.values.categories.filter(x => x !== tag.id)
                        )
                      }
                    />
                  </div>
                ))}
          </div>
          {/* Url */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="url" className="tw-form-label">
              {t("activities.profile.form.url")}
              <span className="tw-form-label-optional">({t("common.validation.optional")})</span>
            </label>
            <input
              type="text"
              id="url"
              name="url"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.url}
              className={twClassNames(isValidCLass("url"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.url}</div>
          </div>
          {/* link title */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="link_title" className="tw-form-label">
              {t("activities.profile.form.link_title")}
            </label>
            <select
              id="link_title"
              name="link_title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.link_title}
              className={twClassNames(isValidCLass("link_title"), "tw-form-control")}>
              <option value="readmore">{t("activities.profile.form.link_title_options.readmore")}</option>
              <option value="book">{t("activities.profile.form.link_title_options.book")}</option>
              <option value="contact">{t("activities.profile.form.link_title_options.contact")}</option>
            </select>
            <div className="tw-invalid-tooltip">{formik.errors.link_title}</div>
          </div>
          {/* Images */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="image" className="tw-form-label">
              {t("activities.profile.form.image")}
              <span className="tw-form-label-optional">({t("common.validation.optional")})</span>
            </label>
            {formik.values.image ? (
              <div className="tw-relative tw-w-fit">
                <Image
                  thumbnail
                  onClick={() => imageRef.current.click()}
                  className="background-thumbnail"
                  src={
                    typeof formik.values.image === "string"
                      ? formik.values.image
                      : URL.createObjectURL(formik.values.image)
                  }
                />
                <button
                  type="button"
                  onClick={() => formik.setFieldValue("image", null)}
                  className="tw-absolute tw-bottom-0 tw-right-4 tw-bg-red-400 tw-rounded-full disabled:tw-opacity-50">
                  <HiTrash className="tw-text-white tw-h-8 tw-w-8 tw-p-2" />
                </button>
              </div>
            ) : (
              <DragZone
                onDrop={data => formik.setFieldValue("image", data)}
                onClick={() => imageRef.current.click()}
                className="tw-flex tw-justify-center tw-items-center tw-rounded-md tw-px-6 tw-py-4
                  tw-border-2 tw-border-dashed tw-cursor-pointer">
                <div className="tw-space-y-1 tw-text-center">
                  <HiOutlinePhotograph className="tw-mx-auto tw-h-12 tw-w-12 tw-text-dark-25 [&>*]:tw-stroke-1" />
                  <div className="tw-flex tw-text-sm tw-text-dark-75">
                    <label
                      htmlFor="image"
                      className="tw-relative tw-rounded-md tw-bg-white tw-font-medium tw-text-primary">
                      <span>{t("common.components.dragZone.upload")}</span>
                    </label>
                    <p className="tw-pl-1">{t("common.components.dragZone.dragAndDrop")}</p>
                  </div>
                  <p className="tw-text-xs tw-text-dark-50">{t("common.components.dragZone.type")}</p>
                </div>
              </DragZone>
            )}
            <input
              type="file"
              name="image"
              accept="image/*"
              className="tw-hidden tw-sr-only"
              ref={imageRef}
              onChange={event => formik.setFieldValue("image", event.currentTarget.files[0])}
            />
            <div className="tw-invalid-tooltip">{formik.errors.image}</div>
          </div>
        </fieldset>
      </div>
      {formik.dirty && (
        <div className="tw-fixed-footer tw-has-sidebar tw-bg-white tw-shadow-2xl tw-border-t tw-z-10 tw-py-4">
          <div className="tw-px-6 tw-flex tw-w-full tw-items-center tw-justify-between">
            <span>{t("common.unsavedChanges.content")}</span>
            <div className="tw-flex tw-flex-row tw-space-x-4">
              <button
                type="reset"
                disabled={isLoading}
                onClick={onReset}
                className="tw-btn tw-btn-outline tw-min-w-[180px]">
                {t("common.unsavedChanges.button.cancel")}
              </button>
              <button type="submit" disabled={isLoading} className="tw-btn tw-btn-primary tw-min-w-[180px]">
                {t("common.unsavedChanges.button.save")}
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  )
}

const ActivitiesProfileContainer = props => {
  const { t } = useTranslation()
  const [toast, setToast] = useState({ display: false, type: null, content: null })

  useEffect(() => {
    props.fetchActivityCategories()
  }, []) // eslint-disable-line

  const onSave = async ({ image, items, ...values }, actions) => {
    let compressedFile = null
    let data = {
      ...values,
      url: withHttp(values.url),
      name: values.name_en || values.name_fi,
      description: values.description_en || values.description_fi,
    }

    if (image?.name) {
      const filesize = (image.size / 1024 / 1024).toFixed(4) // MB
      const options = {
        maxSizeMB: MAX_IMAGE_FILE_SIZE,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        initialQuality: 0.6,
      }
      compressedFile = filesize > MAX_IMAGE_FILE_SIZE ? await imageCompression(image, options) : image
      data.image = compressedFile // Include image file to post request
    } else if (image === null) {
      data.delete_image = true
    }

    props
      .updateActivity(props.selectedActivityId, data)
      .then(response => {
        actions.setSubmitting(false)
        actions.resetForm({ values: response.payload })
        setToast({ display: true, type: "success", content: { message: "save.success" } })
      })
      .catch(() => setToast({ display: true, type: "error", content: { message: "save.failed" } }))
  }

  const formik = useFormik({
    initialValues: {
      company: props.activity?.company, // optional
      address: props.activity?.address || "",
      municipal: props.activity?.municipal || "",
      postal_code: props.activity?.postal_code || "",
      phone: props.activity?.phone, // optional
      name_fi: props.activity?.name_fi || "",
      name_en: props.activity?.name_en || "",
      description_fi: props.activity?.description_fi || "",
      description_en: props.activity?.description_en || "",
      image: props.activity?.image, // optional
      url: props.activity?.url, // optional,
      link_title: props.activity?.link_title || "readmore",
      category: props.activity?.category,
      categories: props.activity?.categories,
      visit_finland_id: props.activity?.visit_finland_id, // optional,
      visit_finland_integration: props.activity?.visit_finland_integration || false,
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: validationSchema(t),
    onSubmit: (values, actions) => onSave(values, actions),
  })

  return (
    <>
      {toast && toast.display && (
        <Toast
          className={"fixed-toast " + toast.type}
          onClose={() => setToast({ display: false, type: null, content: null })}
          show={toast.display}
          delay={5000}
          autohide>
          <Toast.Header>
            <strong className="mr-auto">{t("common.notifications.type." + toast.type)}</strong>
          </Toast.Header>
          <Toast.Body>{t("settings.notifications." + toast.content.message, toast.content.values)}</Toast.Body>
        </Toast>
      )}
      <h1 className="tw-font-display tw-font-semibold tw-text-4xl tw-mb-6">{t("activities.profile.title")}</h1>
      <div className={twClassNames(formik.dirty ? "tw-mb-20" : "tw-mb-12", "tw-w-full")}>
        <div className="tw-mb-6 tw-shadow tw-bg-white tw-rounded">
          <div className="tw-flex tw-p-6 max-md:tw-flex-col">
            {/* Left side */}
            <div className="tw-w-full md:tw-w-1/2">
              <Form formik={formik} categories={props.activityCategories} isLoading={props.isLoading} t={t} />
            </div>
            {/* Right side */}
            <div className="tw-w-full md:tw-w-1/2 tw-min-w-[360px] max-xs:tw-hidden">
              <Preview data={formik.values} t={t} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ActivitiesProfileContainer.propTypes = {
  isLoading: PropTypes.bool,
  selectedActivityId: PropTypes.number,
  updateActivity: PropTypes.func.isRequired,
  fetchActivityCategories: PropTypes.func.isRequired,
}

ActivitiesProfileContainer.defaultProps = {
  isLoading: false,
  activity: null,
  activityCategories: [],
}

const mapStateToProps = state => ({
  isLoading: state.destinations.isLoading,
  activity: state.activities.activities.find(x => x.id === state.activities.selectedActivityId),
  selectedActivityId: state.activities.selectedActivityId,
  activityCategories: state.activities.categories,
})

const mapDispatchToProps = dispatch => ({
  updateActivity: (id, values) => dispatch(updateActivity(id, values)),
  fetchActivityCategories: () => dispatch(fetchActivityCategories()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesProfileContainer)
