import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"

import Header from "../../../components/header"
import Progressbar from "../../../components/progressbar"
import SearchSelect from "../../../components/searchSelect"
import Breadcrumb from "../../../components/breadcrumb"
import Tooltip from "../../../components/tooltip"
import ConfirmationModal from "../../../components/confirmation"
import { twClassNames } from "../../../helpers/classNames"

import { createDestination } from "../../../actions/destinations"
import kunnat from "../../../assets/kunnat.json"

const PHASES = 2
const breadcrumbs = [
  { name: "handbook.create.breadcrumb.home", url: "/handbooks" },
  { name: "handbook.create.breadcrumb.create" },
]

const validationSchema = (phase, t) => {
  if (phase === 1) {
    return Yup.object().shape(
      {
        name_fi: Yup.string()
          .when(["name_en"], {
            is: name_en => !name_en,
            then: Yup.string().required(t("common.validation.eitherRequired")),
          })
          .min(3, t("common.validation.invalidMinLength"))
          .max(120, t("common.validation.invalidMaxLength")),
        name_en: Yup.string()
          .when(["name_fi"], {
            is: name_fi => !name_fi,
            then: Yup.string().required(t("common.validation.eitherRequired")),
          })
          .min(3, t("common.validation.invalidMinLength"))
          .max(120, t("common.validation.invalidMaxLength")),
        address: Yup.string()
          .min(3, t("common.validation.invalidMinLength"))
          .max(120, t("common.validation.invalidMaxLength"))
          .required(t("common.validation.required")),
        municipal: Yup.string()
          .min(3, t("common.validation.invalidMinLength"))
          .max(30, t("common.validation.invalidMaxLength"))
          .required(t("common.validation.required")),
        country: Yup.string()
          .min(3, t("common.validation.invalidMinLength"))
          .max(30, t("common.validation.invalidMaxLength"))
          .required(t("common.validation.required")),
        phone: Yup.string()
          .min(6, t("common.validation.invalidMinLength"))
          .max(30, t("common.validation.invalidMaxLength"))
          .matches(/^\+(?:[0-9]?){6,14}[0-9]$/, t("common.validation.invalidPhone")),
      },
      [["name_fi", "name_en"]]
    )
  } else if (phase === 2) {
    return Yup.object().shape(
      {
        description_fi: Yup.string().when(["description_en"], {
          is: description_en => !description_en,
          then: Yup.string().required(t("common.validation.eitherRequired")),
        }),
        description_en: Yup.string().when(["description_fi"], {
          is: description_fi => !description_fi,
          then: Yup.string().required(t("common.validation.eitherRequired")),
        }),
      },
      [["description_fi", "description_en"]]
    )
  }
}

const Form1 = ({ t, formik, isLoading, isValidCLass, onPrevious, onNext }) => (
  <div className="tw-flex tw-p-6 max-md:tw-flex-col">
    {/* Left side */}
    <div className="tw-min-w-[300px] tw-w-full md:tw-w-1/3 tw-mb-6 md:tw-border-r max-md:tw-border-b">
      {/* Basic details */}
      <div className="tw-relative md:tw-pr-6">
        <h5 className="tw-text-lg tw-mb-2">{t("handbook.create.form.phase_1.name")}</h5>
        <p className="tw-text-sm tw-text-dark-75">{t("handbook.create.form.phase_1.info")}</p>
      </div>
    </div>
    {/* Right side */}
    <div className="tw-w-full md:tw-w-2/3">
      <div className="tw-h-full tw-flex tw-flex-col md:tw-pl-6">
        <div className="tw-grid tw-grid-cols md:tw-grid-cols-6 tw-gap-x-2 tw-gap-y-3 md:tw-gap-y-4">
          {/* Name Fi */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="name_fi" className="tw-form-label">
              {t("handbook.create.form.name_fi")}
              <Tooltip message="handbook.create.tooltips.name" displayLabel />
            </label>
            <input
              type="text"
              id="name_fi"
              name="name_fi"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name_fi}
              className={twClassNames(isValidCLass("name_fi"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.name_fi}</div>
          </div>
          {/* Name En */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="name_en" className="tw-form-label">
              {t("handbook.create.form.name_en")}
            </label>
            <input
              type="text"
              id="name_en"
              name="name_en"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name_en}
              className={twClassNames(isValidCLass("name_en"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.name_en}</div>
          </div>
          {/* Address */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="address" className="tw-form-label">
              {t("handbook.create.form.address")}
            </label>
            <input
              type="text"
              id="address"
              name="address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              className={twClassNames(isValidCLass("address"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.address}</div>
          </div>
          {/* Municipal */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="municipal" className="tw-form-label">
              {t("handbook.create.form.municipal")}
            </label>
            <SearchSelect
              id="municipal"
              name="municipal"
              data={kunnat}
              nameKey="name"
              valueKey="name"
              indexKey="name"
              onChange={value => formik.setFieldValue("municipal", value)}
              value={formik.values.municipal}
              errors={formik.touched.municipal && formik.errors.municipal}
            />
          </div>
          {/* Municipal */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="country" className="tw-form-label">
              {t("handbook.create.form.country")}
            </label>
            <SearchSelect
              id="country"
              name="country"
              data={[{ name: "Finland" }]}
              nameKey="name"
              valueKey="name"
              indexKey="name"
              onChange={value => formik.setFieldValue("country", value)}
              value={formik.values.country}
              errors={formik.touched.country && formik.errors.country}
            />
          </div>
          {/* Phone */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="phone" className="tw-form-label">
              {t("handbook.create.form.phone")}
              <span className="tw-form-label-optional">({t("common.validation.optional")})</span>
              <Tooltip message="handbook.create.tooltips.phone" />
            </label>
            <input
              type="phone"
              id="phone"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              className={twClassNames(isValidCLass("phone"), "tw-form-control")}
            />
            <div className="tw-invalid-tooltip">{formik.errors.phone}</div>
          </div>
        </div>
        <div className="tw-flex tw-grow tw-place-content-between tw-items-end tw-pt-12">
          <button
            type="button"
            onClick={onPrevious}
            className="tw-btn tw-btn-outline tw-w-full tw-max-w-[180px]"
            disabled={isLoading}>
            {t("handbook.create.button.cancel")}
          </button>
          <button
            type="button"
            onClick={onNext}
            className="tw-btn tw-btn-primary tw-w-full tw-max-w-[180px]"
            disabled={isLoading}>
            {t("handbook.create.button.next")}
          </button>
        </div>
      </div>
    </div>
  </div>
)

const Form2 = ({ t, formik, isLoading, isValidCLass, onPrevious, onNext }) => (
  <div className="tw-flex tw-p-6 max-md:tw-flex-col">
    {/* Left side */}
    <div className="tw-min-w-[300px] tw-w-full md:tw-w-1/3 tw-mb-6 md:tw-border-r max-md:tw-border-b">
      {/* Activities informations */}
      <div className="tw-relative md:tw-pr-6">
        <h5 className="tw-text-lg tw-mb-2">{t("handbook.create.form.phase_2.name")}</h5>
        <p className="tw-text-sm tw-text-dark-75">{t("handbook.create.form.phase_2.info")}</p>
      </div>
    </div>
    {/* Right side */}
    <div className="tw-w-full md:tw-w-2/3">
      <div className="tw-h-full tw-flex tw-flex-col md:tw-pl-6">
        <div className="tw-grid tw-grid-cols md:tw-grid-cols-6 tw-gap-x-2 tw-gap-y-3 md:tw-gap-y-4">
          {/* Description FI */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="description_fi" className="tw-form-label">
              {t("handbook.create.form.description_fi")}
              <Tooltip message="handbook.create.tooltips.description" displayLabel />
            </label>
            <textarea
              type="text"
              id="description_fi"
              name="description_fi"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description_fi}
              className={twClassNames(isValidCLass("description_fi"), "tw-form-control tw-min-h-[110px]")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.description_fi}</div>
          </div>
          {/* Description EN */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="description_en" className="tw-form-label">
              {t("handbook.create.form.description_en")}
            </label>
            <textarea
              type="text"
              id="description_en"
              name="description_en"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description_en}
              className={twClassNames(isValidCLass("description_en"), "tw-form-control tw-min-h-[110px]")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.description_en}</div>
          </div>
        </div>
        <div className="tw-flex tw-grow tw-place-content-between tw-items-end tw-pt-12">
          <button
            type="button"
            onClick={onPrevious}
            className="tw-btn tw-btn-outline tw-w-full tw-max-w-[180px]"
            disabled={isLoading}>
            {t("handbook.create.button.previous")}
          </button>
          <button
            type="button"
            onClick={onNext}
            className="tw-btn tw-btn-primary tw-w-full tw-max-w-[180px]"
            disabled={isLoading}>
            {t("handbook.create.button.next")}
          </button>
        </div>
      </div>
    </div>
  </div>
)

const CreateDestinationContainer = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const isLoading = useSelector(state => state.destinations.isLoading)

  const [phase, setPhase] = useState(1)
  const [confirmation, setConfirmation] = useState({ show: false, callback: null, title: "", content: "" })

  const formik = useFormik({
    initialValues: {
      name_fi: "",
      name_en: "",
      address: "",
      country: "finland",
      municipal: "akaa",
      phone: "",
      description_fi: "",
      description_en: "",
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: validationSchema(phase, t),
    onSubmit: values => onSave(values),
  })

  const onSave = values => {
    const data = {
      ...values,
      name: values.name_fi || values.name_en,
      description: values.description_fi || values.description_en,
    }
    dispatch(createDestination(data))
      .then(() => history.push("/handbook/guides"))
      .catch(() => {})
  }

  const onNext = () => {
    formik.validateForm().then(errors => {
      formik.setErrors(errors)
      formik.setFieldTouched(Object.keys(errors), true)
      if (!Object.keys(errors).length) {
        if (phase === PHASES) {
          formik.handleSubmit()
        } else {
          setPhase(phase + 1)
        }
      }
    })
  }

  const onPrevious = () => {
    if (phase <= 1) {
      if (formik.dirty) {
        setConfirmation({
          show: true,
          callback: () => history.push("/handbooks"),
          title: "dirty",
          content: "dirty",
          danger: true,
        })
      } else {
        history.push("/handbooks")
      }
    } else {
      setPhase(phase - 1)
    }
  }

  const isValidCLass = field => formik.touched[field] && formik.errors[field] && "tw-is-invalid"

  return (
    <>
      <Header />
      <div className="tw-has-header xl:tw-container tw-mx-auto tw-w-full tw-px-4 sm:tw-px-12">
        <Breadcrumb data={breadcrumbs} />
        <h1 className="tw-font-display tw-font-semibold tw-text-4xl tw-mb-6">{t("handbook.create.title")}</h1>
        <div className="tw-mb-12 tw-w-full">
          <div className="tw-mb-6 tw-shadow tw-bg-white tw-rounded">
            <form noValidate onSubmit={formik.handleSubmit}>
              {(() => {
                switch (phase) {
                  case 1:
                    return (
                      <Form1
                        t={t}
                        formik={formik}
                        isLoading={isLoading}
                        isValidCLass={isValidCLass}
                        onPrevious={onPrevious}
                        onNext={onNext}
                      />
                    )
                  case 2:
                    return (
                      <Form2
                        t={t}
                        formik={formik}
                        isLoading={isLoading}
                        isValidCLass={isValidCLass}
                        onPrevious={onPrevious}
                        onNext={onNext}
                      />
                    )
                  default:
                    return null
                }
              })()}
            </form>
          </div>
        </div>
        <div className="tw-mb-12 tw-w-full">
          <Progressbar phases={PHASES} current={phase} translationKeys="handbook.create.progress" />
        </div>
        <ConfirmationModal
          show={confirmation.show}
          title={confirmation.title}
          content={confirmation.content}
          danger={confirmation.danger}
          onOK={confirmation.callback}
          onCancel={() => setConfirmation({ show: false, callback: null, title: "", content: "" })}
        />
      </div>
    </>
  )
}

CreateDestinationContainer.propTypes = {
  isLoading: PropTypes.bool,
}

CreateDestinationContainer.defaultProps = {
  isLoading: false,
}

export default CreateDestinationContainer
