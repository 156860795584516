import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Switch, Route } from "react-router-dom"

import Header from "../../components/header"
import Sidebar from "../../components/sidebar"
import GuidesContainer from "./Guides"
import EditGuideContainer from "./Guides/Edit"
import DestinationSettingsContainer from "./Settings"
import PartnersContainer from "./Partners"
import ExamplesContainer from "./Examples"
import ErrorContainer from "../Error/404/index"
import MissingDestinationContainer from "../Error/destination/index"

import { fetchAllDestinations, selectDestination } from "../../actions/destinations"

class HomeContainer extends Component {
  componentDidMount() {
    this.fetchDestinations()
  }

  componentDidUpdate(prevProps) {
    // Dirty fixup
    const { destinations, selectedDestinationId } = prevProps
    const selectedDestination = destinations.find(destination => destination.id === selectedDestinationId)

    if (!selectedDestination && destinations.length) {
      this.props.selectDestination(destinations[0].id)
    }
  }

  fetchDestinations = async () => {
    this.props
      .fetchAllDestinations()
      .then(resp => resp.payload)
      .then(destinations => {
        if (destinations.length === 0) {
          this.props.history.push("/handbook/create")
        } else if (!this.props.selectedDestinationId) {
          this.selectDestination(destinations[0])
        }
      })
      .catch(() => {})
  }

  selectDestination = destination => {
    this.props
      .selectDestination(destination.id)
      .then(() => {
        this.props.history.push("/handbook/guides")
      })
      .catch(() => {})
  }

  render() {
    const { destinations, selectedDestinationId } = this.props
    const selectedDestination = destinations.find(destination => destination.id === selectedDestinationId)

    return (
      <>
        <Header />
        <div className="tw-has-header tw-has-sidebar">
          <div className="xl:tw-container tw-mx-auto tw-w-full tw-px-4 sm:tw-px-12">
            <Switch>
              <Route exact path="/handbook/examples" component={ExamplesContainer} />
              <Route exact path="/handbook/partners" component={PartnersContainer} />
              {!destinations.length && <Route path="*" component={MissingDestinationContainer} />}
              {/* Visible only if user have destinations */}
              <Route exact path="/handbook/guides" component={GuidesContainer} />
              <Route exact path="/handbook/guides/:id" component={EditGuideContainer} />
              <Route exact path="/handbook/settings" component={DestinationSettingsContainer} />
              {/* Error page */}
              <Route path="*" component={ErrorContainer} />
            </Switch>
          </div>
        </div>
        <Sidebar type="handbook" selectedProject={selectedDestination} />
      </>
    )
  }
}

HomeContainer.propTypes = {
  fetchAllDestinations: PropTypes.func.isRequired,
  selectDestination: PropTypes.func.isRequired,
  selectedDestinationId: PropTypes.number,
  destinations: PropTypes.arrayOf(PropTypes.shape()),
}

HomeContainer.defaultProps = {
  selectedDestinationId: null,
  destinations: [],
}

const mapStateToProps = state => ({
  destinations: state.destinations.destinations,
  selectedDestinationId: state.destinations.selectedDestinationId,
})

const mapDispatchToProps = dispatch => ({
  fetchAllDestinations: () => dispatch(fetchAllDestinations()),
  selectDestination: id => dispatch(selectDestination(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
