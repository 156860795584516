import React from "react"
import { Navbar, NavDropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { HiUserCircle } from "react-icons/hi"

import LanguagePicker from "./languagePicker"
import Feedback from "./feedback"
import { logout } from "../actions/auth"

const Header = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(state => state.auth.user)

  const onLogout = () => {
    dispatch(logout())
      .then(() => history.push("/login"))
      .catch(() => {})
  }

  return (
    <Navbar bg="white" className="header-navigation">
      <Feedback />
      <NavDropdown title={<HiUserCircle />} id="user-details-dropdown" alignRight className="bl-2">
        <NavDropdown.Header>{user?.email || ""}</NavDropdown.Header>
        <NavDropdown.Item onClick={onLogout}>{t("header.logout")}</NavDropdown.Item>
      </NavDropdown>
      <LanguagePicker className="bl-2" />
    </Navbar>
  )
}

export default Header
