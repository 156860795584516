import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"

import Progressbar from "../../../../components/progressbar"
import Breadcrumb from "../../../../components/breadcrumb"
import Tooltip from "../../../../components/tooltip"
import ConfirmationModal from "../../../../components/confirmation"
import Emulator from "../../../../components/emulator"

import { twClassNames } from "../../../../helpers/classNames"
import { ISODateNow } from "../../../../helpers/time"
import { getStatus } from "../../../../helpers/campaignStatus"
import { withHttp } from "../../../../helpers/withHttp"

import { createCampaign } from "../../../../actions/campaigns"

const PHASES = 3
const breadcrumbs = [
  { name: "activities.campaigns.create.breadcrumb.campaigns", url: "/activities/campaigns" },
  { name: "activities.campaigns.create.breadcrumb.create" },
]

const validationSchema = (phase, t) => {
  if (phase === 1) {
    return Yup.object().shape(
      {
        short_title_fi: Yup.string()
          .when(["short_title_en"], {
            is: short_title_en => !short_title_en,
            then: Yup.string().required(t("common.validation.eitherRequired")),
          })
          .max(40, t("common.validation.invalidMaxLength")),
        short_title_en: Yup.string()
          .when(["short_title_fi"], {
            is: short_title_fi => !short_title_fi,
            then: Yup.string().required(t("common.validation.eitherRequired")),
          })
          .max(40, t("common.validation.invalidMaxLength")),
        short_description_fi: Yup.string()
          .when(["short_description_en"], {
            is: short_description_en => !short_description_en,
            then: Yup.string().required(t("common.validation.eitherRequired")),
          })
          .max(120, t("common.validation.invalidMaxLength")),
        short_description_en: Yup.string()
          .when(["short_description_fi"], {
            is: short_description_fi => !short_description_fi,
            then: Yup.string().required(t("common.validation.eitherRequired")),
          })
          .max(120, t("common.validation.invalidMaxLength")),
      },
      [
        ["short_title_fi", "short_title_en"],
        ["short_description_fi", "short_description_en"],
      ]
    )
  } else if (phase === 2) {
    return Yup.object().shape(
      {
        title_fi: Yup.string()
          .when(["title_en"], {
            is: title_en => !title_en,
            then: Yup.string().required(t("common.validation.eitherRequired")),
          })
          .max(120, t("common.validation.invalidMaxLength")),
        title_en: Yup.string()
          .when(["title_fi"], {
            is: title_fi => !title_fi,
            then: Yup.string().required(t("common.validation.eitherRequired")),
          })
          .max(120, t("common.validation.invalidMaxLength")),
        description_fi: Yup.string()
          .when(["description_en"], {
            is: description_en => !description_en,
            then: Yup.string().required(t("common.validation.eitherRequired")),
          })
          .max(300, t("common.validation.invalidMaxLength")),
        description_en: Yup.string()
          .when(["description_fi"], {
            is: description_fi => !description_fi,
            then: Yup.string().required(t("common.validation.eitherRequired")),
          })
          .max(300, t("common.validation.invalidMaxLength")),
      },
      [
        ["title_fi", "title_en"],
        ["description_fi", "description_en"],
      ]
    )
  } else if (phase === 3) {
    return Yup.object().shape({})
  }
}

const Form1 = ({ t, formik, isLoading, isValidCLass, onPrevious, onNext }) => (
  <div className="tw-flex tw-flex-col tw-h-full">
    {/* Header */}
    <div className="tw-min-w-[300px] tw-w-full tw-mb-4 tw-border-b">
      <div className="tw-relative md:tw-pr-6">
        <h5 className="tw-text-lg tw-mb-4">{t("activities.campaigns.create.form.phase_1.name")}</h5>
        <p className="tw-text-sm tw-text-dark-75">{t("activities.campaigns.create.form.phase_1.info")}</p>
      </div>
    </div>
    {/* Content */}
    <div className="tw-grid tw-grid-cols md:tw-grid-cols-6 tw-gap-x-2 tw-gap-y-3 md:tw-gap-y-4">
      {/* Short title FI */}
      <div className="tw-relative tw-col-span-6">
        <label htmlFor="short_title_fi" className="tw-form-label">
          {t("activities.campaigns.create.form.short_title_fi")}
          <Tooltip message="activities.campaigns.create.tooltips.short_title_fi" displayLabel />
        </label>
        <input
          type="text"
          id="short_title_fi"
          name="short_title_fi"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.short_title_fi}
          className={twClassNames(isValidCLass("short_title_fi"), "tw-form-control tw-pr-12")}
          spellCheck="false"
        />
        <span className="tw-form-counter">{formik.values.short_title_fi.length}/40</span>
        <div className="tw-invalid-tooltip">{formik.errors.short_title_fi}</div>
      </div>
      {/* Short title EN */}
      <div className="tw-relative tw-col-span-6">
        <label htmlFor="short_title_en" className="tw-form-label">
          {t("activities.campaigns.create.form.short_title_en")}
        </label>
        <input
          type="text"
          id="short_title_en"
          name="short_title_en"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.short_title_en}
          className={twClassNames(isValidCLass("short_title_en"), "tw-form-control tw-pr-12")}
          spellCheck="false"
        />
        <span className="tw-form-counter">{formik.values.short_title_en.length}/40</span>
        <div className="tw-invalid-tooltip">{formik.errors.short_title_en}</div>
      </div>
      {/* Short description FI */}
      <div className="tw-relative tw-col-span-6">
        <label htmlFor="short_description_fi" className="tw-form-label">
          {t("activities.campaigns.create.form.short_description_fi")}
        </label>
        <textarea
          type="text"
          id="short_description_fi"
          name="short_description_fi"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.short_description_fi}
          className={twClassNames(isValidCLass("short_description_fi"), "tw-form-control tw-min-h-[110px] tw-pr-12")}
          spellCheck="false"
        />
        <span className="tw-form-counter">{formik.values.short_description_fi.length}/120</span>
        <div className="tw-invalid-tooltip">{formik.errors.short_description_fi}</div>
      </div>
      {/* Short description EN */}
      <div className="tw-relative tw-col-span-6">
        <label htmlFor="short_description_en" className="tw-form-label">
          {t("activities.campaigns.create.form.short_description_en")}
        </label>
        <textarea
          type="text"
          id="short_description_en"
          name="short_description_en"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.short_description_en}
          className={twClassNames(isValidCLass("short_description_en"), "tw-form-control tw-min-h-[110px] tw-pr-12")}
          spellCheck="false"
        />
        <span className="tw-form-counter">{formik.values.short_description_en.length}/120</span>
        <div className="tw-invalid-tooltip">{formik.errors.short_description_en}</div>
      </div>
    </div>
    {/* Buttons */}
    <div className="tw-flex tw-grow tw-place-content-between tw-items-end tw-pt-12">
      <button
        type="button"
        onClick={onPrevious}
        className="tw-btn tw-btn-outline tw-w-full tw-max-w-[180px]"
        disabled={isLoading}>
        {t("activities.campaigns.create.button.cancel")}
      </button>
      <button
        type="button"
        onClick={onNext}
        className="tw-btn tw-btn-primary tw-w-full tw-max-w-[180px]"
        disabled={isLoading}>
        {t("activities.campaigns.create.button.next")}
      </button>
    </div>
  </div>
)

const Form2 = ({ t, formik, isLoading, isValidCLass, onPrevious, onNext }) => (
  <div className="tw-flex tw-flex-col tw-h-full">
    {/* Header */}
    <div className="tw-min-w-[300px] tw-w-full tw-mb-4 tw-border-b">
      <div className="tw-relative md:tw-pr-6">
        <h5 className="tw-text-lg tw-mb-4">{t("activities.campaigns.create.form.phase_2.name")}</h5>
        <p className="tw-text-sm tw-text-dark-75">{t("activities.campaigns.create.form.phase_2.info")}</p>
      </div>
    </div>
    {/* Content */}
    <div className="tw-grid tw-grid-cols md:tw-grid-cols-6 tw-gap-x-2 tw-gap-y-3 md:tw-gap-y-4">
      {/* Title FI */}
      <div className="tw-relative tw-col-span-6">
        <label htmlFor="title_fi" className="tw-form-label">
          {t("activities.campaigns.create.form.title_fi")}
          <Tooltip message="activities.campaigns.create.tooltips.title_fi" displayLabel />
        </label>
        <textarea
          type="text"
          id="title_fi"
          name="title_fi"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title_fi}
          className={twClassNames(isValidCLass("title_fi"), "tw-form-control tw-min-h-[55px] tw-pr-12")}
          spellCheck="false"
        />
        <span className="tw-form-counter">{formik.values.title_fi.length}/120</span>
        <div className="tw-invalid-tooltip">{formik.errors.title_fi}</div>
      </div>
      {/* Title EN */}
      <div className="tw-relative tw-col-span-6">
        <label htmlFor="title_en" className="tw-form-label">
          {t("activities.campaigns.create.form.title_en")}
        </label>
        <textarea
          type="text"
          id="title_en"
          name="title_en"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title_en}
          className={twClassNames(isValidCLass("title_en"), "tw-form-control tw-min-h-[55px] tw-pr-12")}
          spellCheck="false"
        />
        <span className="tw-form-counter">{formik.values.title_en.length}/120</span>
        <div className="tw-invalid-tooltip">{formik.errors.title_en}</div>
      </div>
      {/* Description FI */}
      <div className="tw-relative tw-col-span-6">
        <label htmlFor="description_fi" className="tw-form-label">
          {t("activities.campaigns.create.form.description_fi")}
        </label>
        <textarea
          type="text"
          id="description_fi"
          name="description_fi"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description_fi}
          className={twClassNames(isValidCLass("description_fi"), "tw-form-control tw-min-h-[110px] tw-pr-12")}
          spellCheck="false"
        />
        <span className="tw-form-counter">{formik.values.description_fi.length}/300</span>
        <div className="tw-invalid-tooltip">{formik.errors.description_fi}</div>
      </div>
      {/* Description EN */}
      <div className="tw-relative tw-col-span-6">
        <label htmlFor="description_en" className="tw-form-label">
          {t("activities.campaigns.create.form.description_en")}
        </label>
        <textarea
          type="text"
          id="description_en"
          name="description_en"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description_en}
          className={twClassNames(isValidCLass("description_en"), "tw-form-control tw-min-h-[110px] tw-pr-12")}
          spellCheck="false"
        />
        <span className="tw-form-counter">{formik.values.description_en.length}/300</span>
        <div className="tw-invalid-tooltip">{formik.errors.description_en}</div>
      </div>
      {/* Details FI */}
      <div className="tw-relative tw-col-span-6">
        <label htmlFor="details_fi" className="tw-form-label">
          {t("activities.campaigns.create.form.details_fi")}
          <Tooltip message="activities.campaigns.create.tooltips.details_fi" />
        </label>
        <textarea
          type="text"
          id="details_fi"
          name="details_fi"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.details_fi}
          className={twClassNames(isValidCLass("details_fi"), "tw-form-control tw-min-h-[110px] tw-pr-12")}
        />
        <span className="tw-form-counter">{formik.values.details_fi.length}/300</span>
        <div className="tw-invalid-tooltip">{formik.errors.details_fi}</div>
      </div>
      {/* Details EN */}
      <div className="tw-relative tw-col-span-6">
        <label htmlFor="details_en" className="tw-form-label">
          {t("activities.campaigns.create.form.details_en")}
        </label>
        <textarea
          type="text"
          id="details_en"
          name="details_en"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.details_en}
          className={twClassNames(isValidCLass("details_en"), "tw-form-control tw-min-h-[110px] tw-pr-12")}
        />
        <span className="tw-form-counter">{formik.values.details_en.length}/300</span>
        <div className="tw-invalid-tooltip">{formik.errors.details_en}</div>
      </div>
      {/* Url */}
      <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
        <label htmlFor="url" className="tw-form-label">
          {t("activities.profile.form.url")}
          <span className="tw-form-label-optional">({t("common.validation.optional")})</span>
          <Tooltip message="activities.campaigns.create.tooltips.url" />
        </label>
        <input
          type="text"
          id="url"
          name="url"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.url}
          className={twClassNames(isValidCLass("url"), "tw-form-control")}
          spellCheck="false"
        />
        <div className="tw-invalid-tooltip">{formik.errors.url}</div>
      </div>
      {/* link title */}
      <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
        <label htmlFor="link_title" className="tw-form-label">
          {t("activities.profile.form.link_title")}
        </label>
        <select
          id="link_title"
          name="link_title"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.link_title}
          className={twClassNames(isValidCLass("link_title"), "tw-form-control")}>
          <option value="readmore">{t("activities.profile.form.link_title_options.readmore")}</option>
          <option value="book">{t("activities.profile.form.link_title_options.book")}</option>
          <option value="contact">{t("activities.profile.form.link_title_options.contact")}</option>
        </select>
        <div className="tw-invalid-tooltip">{formik.errors.link_title}</div>
      </div>
    </div>
    {/* Buttons */}
    <div className="tw-flex tw-grow tw-place-content-between tw-items-end tw-pt-12">
      <button
        type="button"
        onClick={onPrevious}
        className="tw-btn tw-btn-outline tw-w-full tw-max-w-[180px]"
        disabled={isLoading}>
        {t("activities.campaigns.create.button.previous")}
      </button>
      <button
        type="button"
        onClick={onNext}
        className="tw-btn tw-btn-primary tw-w-full tw-max-w-[180px]"
        disabled={isLoading}>
        {t("activities.campaigns.create.button.next")}
      </button>
    </div>
  </div>
)

const Form3 = ({ t, formik, isLoading, isValidCLass, onPrevious, onNext }) => (
  <div className="tw-flex tw-flex-col tw-h-full">
    {/* Header */}
    <div className="tw-min-w-[300px] tw-w-full tw-mb-4 tw-border-b">
      <div className="tw-relative md:tw-pr-6">
        <h5 className="tw-text-lg tw-mb-4">{t("activities.campaigns.create.form.phase_3.name")}</h5>
        <p className="tw-text-sm tw-text-dark-75">{t("activities.campaigns.create.form.phase_3.info")}</p>
      </div>
    </div>
    {/* Content */}
    <div className="tw-grid tw-grid-cols md:tw-grid-cols-6 tw-gap-x-2 tw-gap-y-3 md:tw-gap-y-4">
      {/* Title FI */}
      <div className="tw-relative tw-col-span-6">
        <label htmlFor="published_at" className="tw-form-label">
          {t("activities.campaigns.create.form.published_at")}
        </label>
        <input
          type="datetime-local"
          id="published_at"
          name="published_at"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.published_at}
          className={twClassNames(isValidCLass("published_at"), "tw-form-control")}
        />
        <div className="tw-invalid-tooltip">{formik.errors.published_at}</div>
      </div>
    </div>
    {/* Buttons */}
    <div className="tw-flex tw-grow tw-place-content-between tw-items-end tw-pt-12">
      <button
        type="button"
        onClick={onPrevious}
        className="tw-btn tw-btn-outline tw-w-full tw-max-w-[180px]"
        disabled={isLoading}>
        {t("activities.campaigns.create.button.previous")}
      </button>
      <button
        type="button"
        onClick={onNext}
        className="tw-btn tw-btn-primary tw-w-full tw-max-w-[180px]"
        disabled={isLoading}>
        {t("activities.campaigns.create.button.publish")}
      </button>
    </div>
  </div>
)

const Preview = ({ data, t, phase }) => {
  const [lng, setPreviewLanguage] = useState("fi")

  return (
    <Emulator lng={lng} setPreviewLanguage={setPreviewLanguage} flex>
      {phase === 1 ? (
        <>
          <div
            className="tw-flex tw-self-center tw-flex-col tw-bg-white tw-border tw-rounded-2xl tw-shadow 
                tw-mx-2.5 tw-w-[260px]">
            <div className="tw-flex tw-flex-row tw-justify-between tw-p-2.5">
              <span className="tw-text-sm tw-font-bold tw-line-clamp-2">{data["short_title_" + lng]}</span>
              <span className="tw-min-w-[40px] tw-text-right tw-text-xs tw-font-semibold tw-text-dark-50">
                {t("activities.campaigns.create.emulator.published.short", { lng })}
              </span>
            </div>
            <p className="tw-text-sm tw-text-dark-75 tw-line-clamp-3 tw-px-2.5">{data["short_description_" + lng]}</p>
            <div className="tw-flex tw-self-center tw-border-t tw-w-full">
              <span
                className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full
                    tw-text-sm tw-text-primary tw-p-2.5">
                {t("activities.campaigns.create.emulator.readmore", { lng })}
              </span>
            </div>
          </div>
          {/* Placeholder */}
          <div className="tw-h-[140px] tw-w-[15px] tw-bg-[#CDCED0] tw-shadow tw-rounded-tl tw-rounded-bl tw-flex tw-self-center" />
        </>
      ) : (
        <div className="tw-flex tw-self-center tw-flex-col tw-bg-white tw-border tw-rounded-2xl tw-shadow tw-mx-2.5 tw-p-4 tw-w-full">
          <span className="tw-text-xs tw-text-dark-50">
            {t("activities.campaigns.create.emulator.published.full", { lng })}
          </span>
          <span className="tw-font-semibold tw-text-sm tw-mt-4">{data["title_" + lng]}</span>
          {data["description_" + lng] && (
            <span className="tw-text-sm tw-text-dark-75 tw-mt-1.5">{data["description_" + lng]}</span>
          )}
          {data["details_" + lng] && (
            <>
              <span className="tw-font-semibold tw-text-sm tw-mb-1.5 tw-mt-4">
                {t("activities.campaigns.create.emulator.details", { lng })}
              </span>
              <span className="tw-text-sm tw-text-dark-75">{data["details_" + lng]}</span>
            </>
          )}
          {data.url && (
            <button className="tw-w-full tw-btn tw-btn-primary tw-mt-4" type="button">
              {t("activities.campaigns.create.form.link_title_options." + data.link_title, { lng })}
            </button>
          )}
        </div>
      )}
    </Emulator>
  )
}

const CreateCampaignContainer = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const isLoading = useSelector(state => state.activities.isLoading)
  const amenityId = useSelector(state => state.campaigns.activityId)
  const campaigns = useSelector(state => state.campaigns.campaigns)

  const [phase, setPhase] = useState(1)
  const [confirmation, setConfirmation] = useState({ show: false, callback: null, title: "", content: "" })

  const defaultDate = useMemo(() => {
    return ISODateNow()
  }, [])

  const hasActiveCampaigns = useMemo(() => {
    return campaigns.map(campaign => getStatus(campaign).title !== "expired").filter(x => x !== false).length > 0
  }, [campaigns])

  const formik = useFormik({
    initialValues: {
      title_fi: "",
      title_en: "",
      description_fi: "",
      description_en: "",
      short_title_fi: "",
      short_title_en: "",
      short_description_fi: "",
      short_description_en: "",
      details_fi: "",
      details_en: "",
      categories: [],
      url: "",
      link_title: "readmore",
      published_at: defaultDate,
    },
    enableReinitialize: false,
    validateOnBlur: false,
    validationSchema: validationSchema(phase, t),
    onSubmit: values => onSave(values),
  })

  const onSave = values => {
    if (hasActiveCampaigns) {
      setConfirmation({
        show: true,
        callback: () => history.push("/activities/campaigns"),
        title: "unable",
        content: "campaignExists",
      })
      return
    }

    const data = {
      ...values,
      short_title: values.short_title_fi || values.short_title_en,
      short_description: values.short_description_fi || values.short_description_en,
      title: values.title_fi || values.title_en,
      description: values.description_fi || values.description_en,
      details: values.details_fi || values.details_en,
      amenity: amenityId,
      url: withHttp(values.url),
      published_at: new Date(values.published_at).toISOString(),
    }
    dispatch(createCampaign(amenityId, data))
      .then(() => history.push("/activities/campaigns"))
      .catch(() => {})
  }

  const onNext = () => {
    formik.validateForm().then(errors => {
      formik.setErrors(errors)
      formik.setFieldTouched(Object.keys(errors), true)
      if (!Object.keys(errors).length) {
        if (phase === PHASES) {
          formik.handleSubmit()
        } else {
          if (phase === 1) {
            // Copy short values to next phase if long values are empty
            if (!formik.values.title_fi && formik.values.short_title_fi) {
              formik.setFieldValue("title_fi", formik.values.short_title_fi)
            }
            if (!formik.values.title_en && formik.values.short_title_en) {
              formik.setFieldValue("title_en", formik.values.short_title_en)
            }
            if (!formik.values.description_fi && formik.values.short_description_fi) {
              formik.setFieldValue("description_fi", formik.values.short_description_fi)
            }
            if (!formik.values.description_en && formik.values.short_description_en) {
              formik.setFieldValue("description_en", formik.values.short_description_en)
            }
          }
          setPhase(phase + 1)
        }
      }
    })
  }

  const onPrevious = () => {
    if (phase <= 1) {
      if (formik.dirty) {
        setConfirmation({
          show: true,
          callback: () => history.push("/activities/campaigns"),
          title: "dirty",
          content: "dirty",
          danger: true,
        })
      } else {
        history.push("/activities/campaigns")
      }
    } else {
      setPhase(phase - 1)
    }
  }

  const isValidCLass = field => formik.touched[field] && formik.errors[field] && "tw-is-invalid"

  return (
    <>
      <Breadcrumb data={breadcrumbs} />
      <h1 className="tw-font-display tw-font-semibold tw-text-4xl tw-mb-6">{t("activities.campaigns.create.title")}</h1>
      <div className="tw-mb-12 tw-w-full">
        <div className="tw-mb-6 tw-shadow tw-bg-white tw-rounded">
          <div className="tw-flex tw-p-6 max-md:tw-flex-col">
            {/* Left side */}
            <form noValidate onSubmit={formik.handleSubmit} className="tw-w-full md:tw-w-1/2 md:tw-pr-6">
              {(() => {
                switch (phase) {
                  case 1:
                    return (
                      <Form1
                        t={t}
                        formik={formik}
                        isLoading={isLoading}
                        isValidCLass={isValidCLass}
                        onPrevious={onPrevious}
                        onNext={onNext}
                      />
                    )
                  case 2:
                    return (
                      <Form2
                        t={t}
                        formik={formik}
                        isLoading={isLoading}
                        isValidCLass={isValidCLass}
                        onPrevious={onPrevious}
                        onNext={onNext}
                      />
                    )
                  case 3:
                    return (
                      <Form3
                        t={t}
                        formik={formik}
                        isLoading={isLoading}
                        isValidCLass={isValidCLass}
                        onPrevious={onPrevious}
                        onNext={onNext}
                      />
                    )
                  default:
                    return null
                }
              })()}
            </form>
            {/* Right side */}
            <div className="tw-w-full md:tw-w-1/2 tw-min-w-[360px] max-xs:tw-hidden">
              <Preview data={formik.values} t={t} phase={phase} />
            </div>
          </div>
        </div>
      </div>
      <div className="tw-mb-12 tw-w-full">
        <Progressbar phases={PHASES} current={phase} translationKeys="activities.campaigns.create.progress" />
      </div>
      <ConfirmationModal
        show={confirmation.show}
        title={confirmation.title}
        content={confirmation.content}
        danger={confirmation.danger}
        onOK={confirmation.callback}
        onCancel={() => setConfirmation({ show: false, callback: null, title: "", content: "" })}
      />
    </>
  )
}

CreateCampaignContainer.propTypes = {
  isLoading: PropTypes.bool,
  activityCategories: PropTypes.array,
}

CreateCampaignContainer.defaultProps = {
  isLoading: false,
  activityCategories: [],
}

export default CreateCampaignContainer
