// In theory not used anymore, since user is redirected to create new destination if he doesn't have any.
// TODO: Remove this component and references (handbooks/index.js)

import React from "react"
import { useTranslation } from "react-i18next"

function MissingDestinationContainer(props) {
  const { t } = useTranslation()
  return (
    <div
      className="tw-w-full tw-bg-white tw-rounded tw-shadow tw-text-center tw-mt-24 tw-p-12
      tw-flex tw-flex-col tw-space-y-6 tw-items-center tw-align-items-center">
      <h4 className="tw-font-semibiold tw-mb-0">{t("error.missingDestination.title")}</h4>
      <p>{t("error.missingDestination.content")}</p>
      <button className="tw-btn tw-btn-primary tw-w-48" onClick={() => props.history.push("/handbook/create")}>
        {t("error.missingDestination.button")}
      </button>
    </div>
  )
}

export default MissingDestinationContainer
