import React, { useState, useEffect, useCallback, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { HiOutlineDotsHorizontal, HiShare } from "react-icons/hi"
import { Tab, Menu, Transition } from "@headlessui/react"

import { getLocaleValue } from "../../../helpers/getLocaleValue"
import { twClassNames } from "../../../helpers/classNames"
import { selectDestination, deleteDestination } from "../../../actions/destinations"

import ListFooter from "./listFooter"
import placeholder from "../../../assets/destination-placeholder.jpg"

const SHARE_LINK = "https://www.gettips.fi/handbook/"

const ThumbnailImage = ({ image }) => (
  <img
    width="200"
    height="200"
    className="tw-w-[200px] tw-max-w-[200px] tw-h-[200px] tw-object-cover tw-rounded-tl-2xl tw-rounded-bl-2xl"
    alt="destination cover"
    src={image ? (typeof image === "string" ? image : URL.createObjectURL(image)) : placeholder}
  />
)

const Handbook = ({ destination, onSelect, onDelete, onShareLink }) => {
  const { t, i18n } = useTranslation()
  const language = i18n.language

  return (
    <div
      className="tw-flex tw-flex-col md:tw-flex-row tw-bg-white tw-shadow tw-rounded-2xl 
      tw-border-2 tw-border-gray tw-mb-4">
      {/* Image */}
      <div className="tw-w-full tw-max-w-[200px] tw-min-h-[200px] tw-bg-gray tw-hidden lg:tw-block">
        <ThumbnailImage image={destination.image} />
      </div>
      {/* Content */}
      <div className="tw-flex tw-flex-col tw-w-full tw-px-6 tw-py-4">
        {/* Header */}
        <div className="tw-flex tw-mb-2">
          <div className="tw-grow tw-font-semibold tw-text-lg">{getLocaleValue(destination, "name", language)}</div>
          <Menu as="div" className="tw-relative tw-inline-block tw-text-left tw-mb-2">
            <Menu.Button className="tw-inline-flex tw-w-full tw-justify-right tw-px-2">
              <HiOutlineDotsHorizontal className="tw-h-5 tw-w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="tw-transition tw-ease-out tw-duration-100"
              enterFrom="tw-transform tw-opacity-0 tw-scale-95"
              enterTo="tw-transform tw-opacity-100 tw-scale-100"
              leave="tw-transition tw-ease-in tw-duration-75"
              leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
              leaveTo="tw-transform tw-opacity-0 tw-scale-95">
              <Menu.Items
                className="tw-absolute tw-right-0 tw-z-10 tw-w-56 tw-origin-top-right tw-rounded-md tw-py-1
                    tw-bg-white tw-shadow-lg tw-border tw-border-dark-15 focus:tw-outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => onDelete(destination)}
                      className={twClassNames(
                        active ? "tw-bg-gray" : "tw-text-red-700",
                        "tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-no-underline hover:tw-text-red-700"
                      )}>
                      {t("projects.button.delete")}
                    </div>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        {/* Body */}
        <div className="tw-flex tw-grow tw-flex-col md:tw-flex-row">
          {/* Left */}
          <div className="tw-w-full tw-border-t-2 tw-border-gray md:tw-mr-6 lg:tw-mr-12">
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-text-sm tw-my-3">
              <div className="tw-w-full md:tw-w-1/2">
                <span className="tw-capitalize">
                  {destination.address}
                  <br />
                  {destination.municipal}
                </span>
                <span className="tw-uppercase">, Finland</span>
              </div>
              {destination.phone && <div className="tw-w-full md:tw-w-1/2">{destination.phone}</div>}
            </div>
            <div className="tw-text-dark-50 tw-text-sm tw-line-clamp-3">
              {getLocaleValue(destination, "description", language)}
            </div>
          </div>
          {/* Right */}
          <div className="tw-flex md:tw-w-1/3 tw-flex-col">
            <div
              className="tw-w-full tw-rounded-xl tw-bg-light tw-cursor-pointer tw-flex tw-px-4 tw-py-2 max-md:tw-mt-4"
              onClick={() => onShareLink(destination.access_code)}>
              <div className="tw-flex tw-grow tw-flex-col tw-text-left">
                <span className="tw-uppercase tw-text-xs tw-text-dark-50">{t("projects.accessCode")}</span>
                <span>{destination.access_code}</span>
              </div>
              <div className="tw-text-right tw-flex tw-items-center">
                <HiShare className="tw-text-xl" />
              </div>
            </div>
            <div className="tw-grow tw-flex tw-items-end max-md:tw-mt-4">
              <button className="tw-btn tw-btn-primary tw-w-full" onClick={() => onSelect(destination)}>
                {t("projects.button.select")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TabHandbooks = ({
  destinations,
  query,
  onMaxRowSelect,
  maxRows,
  setConfirmation,
  hideConfirmation,
  setToast,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const defaultPagination = useCallback(() => {
    return { selected: 1, min: 0, max: maxRows > destinations.length ? destinations.length : maxRows }
  }, [destinations, maxRows])

  const [pagination, setPagination] = useState(defaultPagination)

  useEffect(() => {
    setPagination(defaultPagination)
  }, [query, defaultPagination])

  const onPaginationSelect = value =>
    setPagination({
      selected: value,
      min: (value - 1) * maxRows,
      max: value * maxRows > destinations.length ? destinations.length : value * maxRows,
    })

  const onSelect = id => {
    dispatch(selectDestination(id))
      .then(() => history.push("/handbook/guides"))
      .catch(() => {})
  }

  const onDelete = id =>
    setConfirmation({
      show: true,
      callback: () => {
        hideConfirmation()
        dispatch(deleteDestination(id))
      },
      title: "delete",
      content: "delete.destination",
      danger: true,
    })

  const onCopyLinkToClipboard = data => {
    setToast({ display: true, type: "success", content: { message: "copiedShareLink" } })
    navigator.clipboard.writeText(SHARE_LINK + data)
  }

  return (
    <Tab.Panel>
      {/* Empty */}
      {destinations.length === 0 && (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-my-12">
          <p className="tw-text-center tw-text-dark-50 tw-text-lg tw-mb-0">
            {query ? t("projects.empty.withQuery.handbooks") : t("projects.empty.withoutQuery.handbooks")}
          </p>
          {query === "" && (
            <button
              className="tw-btn tw-btn-outline tw-min-w-[180px] tw-mt-6"
              onClick={() => history.push("/handbook/create")}>
              <span>{t("projects.button.create.handbook")}</span>
            </button>
          )}
        </div>
      )}
      {destinations.slice(pagination.min, pagination.max).map(destination => (
        <Handbook
          destination={destination}
          key={destination.id}
          onSelect={destination => onSelect(destination.id)}
          onDelete={destination => onDelete(destination.id)}
          onShareLink={onCopyLinkToClipboard}
        />
      ))}
      <ListFooter
        total={destinations.length}
        pagination={pagination}
        onPaginationSelect={onPaginationSelect}
        maxRows={maxRows}
        onMaxRowSelect={onMaxRowSelect}
      />
    </Tab.Panel>
  )
}

export default TabHandbooks
