// Clear the preferences when user logout
export const clearPreferences = async () => {
  try {
    await null
    // Remove preferences from the secure storage
    return localStorage.removeItem("display.max_rows")
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Failed to delete preferences: ", error)
    return null
  }
}
