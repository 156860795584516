import React, { useState } from "react"
import PropTypes from "prop-types"
import { Col, Row, Button, Form } from "react-bootstrap"
import { Link, useLocation } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import { useTranslation, Trans } from "react-i18next"

const validationSchema = t => {
  return Yup.object({
    first_name: Yup.string()
      .min(2, t("common.validation.invalidMinLength"))
      .max(30, t("common.validation.invalidMaxLength"))
      .required(t("common.validation.required")),
    last_name: Yup.string()
      .min(2, t("common.validation.invalidMinLength"))
      .max(30, t("common.validation.invalidMaxLength"))
      .required(t("common.validation.required")),
    email: Yup.string().email(t("common.validation.invalidEmail")).required(t("common.validation.required")),
    terms: Yup.boolean()
      .required(t("common.validation.invalidTerms"))
      .oneOf([true], t("common.validation.invalidTerms")),
  })
}

const displayIF = boolean => {
  return { display: boolean ? "block" : "none" }
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const Register = ({ onSubmit, isLoading }) => {
  const [promoVisibility, setPromoVisibility] = useState(false)
  const query = useQuery()
  const { t } = useTranslation()

  return (
    <>
      <h1 className="title mb-5">{t("auth.register")}</h1>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          business: "",
          business_id: "",
          promo_code: query.get("code") || "",
          email: "",
          terms: false,
        }}
        validationSchema={validationSchema(t)}
        validateOnBlur={false}
        onSubmit={values => onSubmit(values)}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="my-4">
              <Form.Group as={Col} xs={12} md={6} controlId="RegisterFormFirstname">
                <Form.Control
                  type="text"
                  name="first_name"
                  placeholder={t("auth.form.first_name")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  isInvalid={touched.first_name && errors.first_name}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.first_name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} controlId="RegisterFormLastname">
                <Form.Control
                  type="text"
                  name="last_name"
                  placeholder={t("auth.form.last_name")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  isInvalid={touched.last_name && errors.last_name}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.last_name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="RegisterFormEmail">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder={t("auth.form.email")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  isInvalid={touched.email && errors.email}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="RegisterFormBusiness">
                <Form.Control
                  type="text"
                  name="business"
                  placeholder={t("auth.form.business")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.business}
                  isInvalid={touched.business && errors.business}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.business}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="RegisterFormBusinessId">
                <Form.Control
                  type="text"
                  name="business_id"
                  placeholder={t("auth.form.business_id")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.business_id}
                  isInvalid={touched.business_id && errors.business_id}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.business_id}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="RegisterFormPromoCode" style={displayIF(promoVisibility)}>
                <Form.Control
                  type="text"
                  name="promo_code"
                  placeholder={t("auth.form.promo_code")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.promo_code}
                  isInvalid={touched.promo_code && errors.promo_code}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.promo_code}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="RegisterFormTerms" className="text-left">
                <Form.Check
                  type="checkbox"
                  name="terms"
                  label={
                    <>
                      <span className="text-dark">{t("auth.form.accept")} </span>
                      <a
                        className="underline"
                        href="https://www.gettips.fi/terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer">
                        {t("auth.form.terms")}
                      </a>
                    </>
                  }
                  onChange={handleChange}
                  value={values.terms}
                  isInvalid={touched.terms && errors.terms}
                />
                <Form.Control.Feedback tooltip type="invalid" style={displayIF(touched.terms && errors.terms)}>
                  {errors.terms}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Col xl={{ span: 8, offset: 2 }} xs={12} style={displayIF(!promoVisibility)}>
                <Button block variant="outline-dark" type="button" onClick={() => setPromoVisibility(true)}>
                  {t("auth.button.promoCode")}
                </Button>
              </Col>
            </Row>
            <Row className="mb-4 mt-2">
              <Col xl={{ span: 8, offset: 2 }} xs={12}>
                <Button block variant="primary" type="submit" disabled={isLoading}>
                  {t("auth.button.signup")}
                </Button>
              </Col>
            </Row>
            <Row className="small">
              <Col>
                <Trans i18nKey="auth.button.alreadyAccount">
                  <Link className="underline" to="/login"></Link>
                </Trans>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

Register.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
Register.defaultProps = {}

export default Register
