import React from "react"
import { getLocaleValue } from "../../../../helpers/getLocaleValue"

const empty = value => {
  return !value || value.trim() === ""
}

export const GuideText = ({ data, language, list, preview }) => {
  const title = getLocaleValue(data, "title", language)
  const paragraph = getLocaleValue(data, "paragraph", language)

  if (empty(title) && empty(paragraph) && !list) {
    return null
  }

  return (
    <>
      {list ? (
        <div className="text-container list">
          {!empty(title) && <p className="font-14 mb-1 font-weight-bold limit-text-1">{title}</p>}
          {!empty(paragraph) && (
            <p className={"font-14 pre-wrap limit-text-" + (!empty(title) ? "2" : "3")}>{paragraph}</p>
          )}
        </div>
      ) : (
        <div className="text-container preview">
          {!empty(title) && <p className="font-14 font-weight-bold">{title}</p>}
          {!empty(paragraph) && <p className="font-14 pre-wrap m-0">{paragraph}</p>}
        </div>
      )}
    </>
  )
}
