import React, { useState, useRef, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Toast, Image } from "react-bootstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"
import imageCompression from "browser-image-compression"
import {
  HiEyeOff,
  HiEye,
  HiTrash,
  HiOutlinePhotograph,
  HiLocationMarker,
  HiChevronRight,
  HiPhone,
  HiChatAlt2,
} from "react-icons/hi"
import { Switch } from "@headlessui/react"

import { updateDestinationWithFile } from "../../../actions/destinations"
import { capitalize, twClassNames } from "../../../helpers"
import DragZone from "../../../components/dragZone"
import Tooltip from "../../../components/tooltip"
import Emulator from "../../../components/emulator"
import defaultBackground from "../../../assets/city_background.jpg"

import { MAX_IMAGE_FILE_SIZE } from "../../../constants/fileHandling"

const validationSchema = t => {
  return Yup.object().shape(
    {
      name_en: Yup.string()
        .when(["name_fi"], {
          is: name_fi => !name_fi,
          then: Yup.string().required(t("common.validation.eitherRequired")),
        })
        .min(3, t("common.validation.invalidMinLength"))
        .max(120, t("common.validation.invalidMaxLength")),
      name_fi: Yup.string()
        .when(["name_en"], {
          is: name_en => !name_en,
          then: Yup.string().required(t("common.validation.eitherRequired")),
        })
        .min(3, t("common.validation.invalidMinLength"))
        .max(120, t("common.validation.invalidMaxLength")),
      address: Yup.string()
        .min(3, t("common.validation.invalidMinLength"))
        .max(120, t("common.validation.invalidMaxLength"))
        .required(t("common.validation.required")),
      municipal: Yup.string()
        .min(3, t("common.validation.invalidMinLength"))
        .max(30, t("common.validation.invalidMaxLength"))
        .required(t("common.validation.required")),
      country: Yup.string()
        .min(3, t("common.validation.invalidMinLength"))
        .max(30, t("common.validation.invalidMaxLength")),
      phone: Yup.string()
        .min(6, t("common.validation.invalidMinLength"))
        .max(30, t("common.validation.invalidMaxLength"))
        .matches(/^\+(?:[0-9]?){6,14}[0-9]$/, t("common.validation.invalidPhone")),
      description_en: Yup.string().when(["description_fi"], {
        is: description_fi => !description_fi,
        then: Yup.string().required(t("common.validation.eitherRequired")),
      }),
      description_fi: Yup.string().when(["description_en"], {
        is: description_en => !description_en,
        then: Yup.string().required(t("common.validation.eitherRequired")),
      }),
      pincode: Yup.string()
        .matches(/^\d+$/, t("common.validation.digitsOnly"))
        .min(4, t("common.validation.invalidMinLength"))
        .max(4, t("common.validation.invalidMaxLength")),
    },
    [
      ["name_fi", "name_en"],
      ["description_fi", "description_en"],
    ]
  )
}

const Preview = ({ data, t }) => {
  const [lng, setPreviewLanguage] = useState("fi")

  const image = data.image
    ? typeof data.image === "string"
      ? data.image
      : URL.createObjectURL(data.image)
    : defaultBackground

  return (
    <Emulator lng={lng} setPreviewLanguage={setPreviewLanguage}>
      <div className="tw-h-full tw-w-full tw-rounded-[25px] tw-bg-light">
        {/* Image */}
        <div className="tw-relative tw-h-[247px] tw-w-full tw-bg-dark-25 tw-rounded-t-[25px]">
          <img alt="" src={image} className="tw-h-full tw-w-full tw-object-cover tw-rounded-t-[25px]" />
        </div>
        {/* Text */}
        <div className="tw-absolute tw-top-48 tw-px-[15px]">
          <div className="tw-bg-white tw-border tw-rounded-2xl tw-p-2.5 tw-w-[260px]">
            <div className="tw-text-xs tw-text-dark tw-mb-2">
              <HiLocationMarker className="tw-text-sm tw-mr-2" />
              {data.address}, {data?.postal_code} {capitalize(data?.municipal)}
            </div>
            <div className="tw-text-xs tw-mb-2">
              {data["description_" + lng] && (
                <p className="tw-text-xs tw-text-dark-75 tw-line-clamp-6">{data["description_" + lng]}</p>
              )}
            </div>
            <div className="tw-flex tw-space-x-2">
              {data.display_call && (
                <button
                  type="button"
                  className="tw-w-1/2 tw-btn tw-h-[32px] tw-min-w-[20px] tw-btn-primary 
                    tw-flex tw-items-center tw-justify-start">
                  <HiPhone className="tw-text-sm" />
                  <span className="tw-text-sm">{t("settings.emulator.call", { lng })}</span>
                </button>
              )}
              {data.display_message && (
                <button
                  type="button"
                  className="tw-w-1/2 tw-btn tw-h-[32px] tw-min-w-[20px] tw-btn-primary 
                    tw-flex tw-items-center tw-justify-start">
                  <HiChatAlt2 className="tw-text-sm" />
                  <span className="tw-text-sm">{t("settings.emulator.message", { lng })}</span>
                </button>
              )}
            </div>
          </div>
          {["1", "2", "3", "4", "5"].map(item => (
            <div
              key={item}
              className="tw-flex tw-w-full tw-h-9 tw-mt-2.5 tw-px-4 tw-bg-white tw-border tw-rounded-2xl
                tw-shadow tw-items-center tw-justify-between">
              <span className=" tw-text-sm">{t("settings.emulator.guide", { value: item, lng })}</span>
              <HiChevronRight className="tw-text-sm" />
            </div>
          ))}
        </div>
      </div>
    </Emulator>
  )
}

const Form = ({ formik, isLoading, t }) => {
  const imageRef = useRef()
  const [showPincode, setShowPincode] = useState(false)

  const onReset = () => {
    formik.handleReset()
    imageRef.current.value = null //Fix: reset image file selector
  }

  const isValidCLass = field => formik.touched[field] && formik.errors[field] && "tw-is-invalid"

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <div className="tw-w-full md:tw-pr-6 max-md:tw-pb-6">
        <fieldset className="tw-grid tw-grid-cols md:tw-grid-cols-6 tw-gap-x-2 tw-gap-y-3 md:tw-gap-y-4">
          {/* Active */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="is_public" className="tw-form-label">
              {t("settings.form.is_public")}
            </label>
            <div className="tw-flex tw-space-x-2">
              <Switch
                id="is_public"
                name="is_public"
                className="tw-items-center"
                checked={formik.values.is_public}
                onChange={value => formik.setFieldValue("is_public", value)}
                as={Fragment}>
                {({ checked }) => (
                  <span className="tw-flex">
                    <button
                      className={twClassNames(
                        checked ? "tw-bg-primary" : "tw-bg-dark-25",
                        "tw-relative tw-inline-flex tw-h-6 tw-w-11 tw-items-center tw-rounded-full",
                        "focus-within:tw-outline-none focus-within:tw-ring-2 focus-within:tw-ring-primary-50"
                      )}>
                      <span
                        className={twClassNames(
                          checked ? "tw-translate-x-6" : "tw-translate-x-1",
                          "tw-inline-block tw-h-4 tw-w-4 tw-transform tw-rounded-full tw-bg-white tw-transition"
                        )}
                      />
                    </button>
                    <span className="tw-ml-3 tw-text-sm">
                      {checked ? t("settings.form.active") : t("settings.form.unactive")}
                    </span>
                  </span>
                )}
              </Switch>
            </div>
          </div>
          {/* Access code */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="access_code" className="tw-form-label">
              {t("settings.form.access_code")}
              <Tooltip message="settings.tooltips.access_code" />
            </label>
            <input
              disabled
              type="text"
              id="access_code"
              name="access_code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.access_code}
              className={twClassNames(isValidCLass("access_code"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.access_code}</div>
          </div>
          {/* Pincode */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="pincode" className="tw-form-label">
              {t("settings.form.pincode")}
              <span className="tw-form-label-optional">({t("common.validation.optional")})</span>
              <Tooltip message="settings.tooltips.pincode" />
            </label>
            <span className="tw-relative">
              <input
                type={showPincode ? "text" : "password"}
                id="pincode"
                name="pincode"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pincode}
                maxLength={4}
                className={twClassNames(isValidCLass("pincode"), "tw-form-control !tw-pr-9")}
                spellCheck="false"
              />
              <button
                type="button"
                onClick={() => setShowPincode(!showPincode)}
                className={twClassNames("tw-absolute tw-right-2.5 tw-top-0 tw-my-1.5 tw-bg-white")}>
                {showPincode ? <HiEyeOff /> : <HiEye />}
              </button>
              <div className="tw-invalid-tooltip">{formik.errors.pincode}</div>
            </span>
          </div>
          {/* Basic informations */}
          <div className="tw-relative tw-col-span-6 tw-border-b tw-mt-4">
            <h5>{t("settings.form.subtitle.name")}</h5>
            <p className="tw-text-sm tw-text-dark-75">{t("settings.form.subtitle.info")}</p>
          </div>
          {/* Name (fi) */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="name_fi" className="tw-form-label">
              {t("settings.form.name_fi")}
            </label>
            <input
              type="text"
              id="name_fi"
              name="name_fi"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name_fi}
              className={twClassNames(isValidCLass("name_fi"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.name_fi}</div>
          </div>
          {/* Name (eng) */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="name_en" className="tw-form-label">
              {t("settings.form.name_en")}
            </label>
            <input
              type="text"
              id="name_en"
              name="name_en"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name_en}
              className={twClassNames(isValidCLass("name_en"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.name_en}</div>
          </div>
          {/* Address */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="address" className="tw-form-label">
              {t("settings.form.address")}
            </label>
            <input
              type="text"
              id="address"
              name="address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              className={twClassNames(isValidCLass("address"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.address}</div>
          </div>
          {/* Municipal */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="municipal" className="tw-form-label">
              {t("settings.form.municipal")}
            </label>
            <input
              type="text"
              id="municipal"
              name="municipal"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={capitalize(formik.values.municipal)}
              className={twClassNames(isValidCLass("municipal"), "tw-form-control")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.municipal}</div>
          </div>
          {/* Phone */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3">
            <label htmlFor="phone" className="tw-form-label">
              {t("settings.form.phone")}
              <span className="tw-form-label-optional">({t("common.validation.optional")})</span>
              <Tooltip message="settings.tooltips.phone" />
            </label>
            <input
              type="phone"
              id="phone"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              className={twClassNames(isValidCLass("phone"), "tw-form-control")}
            />
            <div className="tw-invalid-tooltip">{formik.errors.phone}</div>
          </div>
          {/* Description FI */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="description_fi" className="tw-form-label">
              {t("settings.form.description_fi")}
              <Tooltip message="settings.tooltips.description_fi" />
            </label>
            <textarea
              type="text"
              id="description_fi"
              name="description_fi"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description_fi}
              className={twClassNames(isValidCLass("description_fi"), "tw-form-control tw-min-h-[150px]")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.description_fi}</div>
          </div>
          {/* Description EN */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="description_en" className="tw-form-label">
              {t("settings.form.description_en")}
            </label>
            <textarea
              type="text"
              id="description_en"
              name="description_en"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description_en}
              className={twClassNames(isValidCLass("description_en"), "tw-form-control tw-min-h-[150px]")}
              spellCheck="false"
            />
            <div className="tw-invalid-tooltip">{formik.errors.description_en}</div>
          </div>
          {/*  Call button */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3 lg:tw-col-span-2">
            <label htmlFor="display_call" className="tw-form-label">
              {t("settings.form.display_call")}
            </label>
            <div className="tw-flex tw-space-x-2">
              <Switch
                id="display_call"
                name="display_call"
                className="tw-items-center"
                checked={formik.values.display_call}
                onChange={value => formik.setFieldValue("display_call", value)}
                as={Fragment}>
                {({ checked }) => (
                  <button
                    className={twClassNames(
                      checked ? "tw-bg-primary" : "tw-bg-dark-25",
                      "tw-relative tw-inline-flex tw-h-6 tw-w-11 tw-items-center tw-rounded-full",
                      "focus-within:tw-outline-none focus-within:tw-ring-2 focus-within:tw-ring-primary-50"
                    )}>
                    <span
                      className={twClassNames(
                        checked ? "tw-translate-x-6" : "tw-translate-x-1",
                        "tw-inline-block tw-h-4 tw-w-4 tw-transform tw-rounded-full tw-bg-white tw-transition"
                      )}
                    />
                  </button>
                )}
              </Switch>
            </div>
          </div>
          {/* Message button */}
          <div className="tw-relative tw-col-span-6 md:tw-col-span-3 lg:tw-col-span-2">
            <label htmlFor="display_message" className="tw-form-label">
              {t("settings.form.display_message")}
            </label>
            <div className="tw-flex tw-space-x-2">
              <Switch
                id="display_message"
                name="display_message"
                className="tw-items-center"
                checked={formik.values.display_message}
                onChange={value => formik.setFieldValue("display_message", value)}
                as={Fragment}>
                {({ checked }) => (
                  <button
                    className={twClassNames(
                      checked ? "tw-bg-primary" : "tw-bg-dark-25",
                      "tw-relative tw-inline-flex tw-h-6 tw-w-11 tw-items-center tw-rounded-full",
                      "focus-within:tw-outline-none focus-within:tw-ring-2 focus-within:tw-ring-primary-50"
                    )}>
                    <span
                      className={twClassNames(
                        checked ? "tw-translate-x-6" : "tw-translate-x-1",
                        "tw-inline-block tw-h-4 tw-w-4 tw-transform tw-rounded-full tw-bg-white tw-transition"
                      )}
                    />
                  </button>
                )}
              </Switch>
            </div>
          </div>
          {/* Images */}
          <div className="tw-relative tw-col-span-6">
            <label htmlFor="image" className="tw-form-label">
              {t("settings.form.image")}
              <span className="tw-form-label-optional">({t("common.validation.optional")})</span>
            </label>
            {formik.values.image ? (
              <div className="tw-relative tw-w-fit">
                <Image
                  thumbnail
                  onClick={() => imageRef.current.click()}
                  className="background-thumbnail"
                  src={
                    typeof formik.values.image === "string"
                      ? formik.values.image
                      : URL.createObjectURL(formik.values.image)
                  }
                />
                <button
                  type="button"
                  onClick={() => formik.setFieldValue("image", null)}
                  className="tw-absolute tw-bottom-0 tw-right-4 tw-bg-red-400 tw-rounded-full disabled:tw-opacity-50">
                  <HiTrash className="tw-text-white tw-h-8 tw-w-8 tw-p-2" />
                </button>
              </div>
            ) : (
              <DragZone
                onDrop={data => formik.setFieldValue("image", data)}
                onClick={() => imageRef.current.click()}
                className="tw-flex tw-justify-center tw-items-center tw-rounded-md tw-px-6 tw-py-4
                  tw-border-2 tw-border-dashed tw-cursor-pointer">
                <div className="tw-space-y-1 tw-text-center">
                  <HiOutlinePhotograph className="tw-mx-auto tw-h-12 tw-w-12 tw-text-dark-25 [&>*]:tw-stroke-1" />
                  <div className="tw-flex tw-text-sm tw-text-dark-75">
                    <label
                      htmlFor="image"
                      className="tw-relative tw-rounded-md tw-bg-white tw-font-medium tw-text-primary">
                      <span>{t("common.components.dragZone.upload")}</span>
                    </label>
                    <p className="tw-pl-1">{t("common.components.dragZone.dragAndDrop")}</p>
                  </div>
                  <p className="tw-text-xs tw-text-dark-50">{t("common.components.dragZone.type")}</p>
                </div>
              </DragZone>
            )}
            <input
              type="file"
              name="image"
              accept="image/*"
              className="tw-hidden tw-sr-only"
              ref={imageRef}
              onChange={event => formik.setFieldValue("image", event.currentTarget.files[0])}
            />
            <div className="tw-invalid-tooltip">{formik.errors.image}</div>
          </div>
        </fieldset>
      </div>
      {formik.dirty && (
        <div className="tw-fixed-footer tw-has-sidebar tw-bg-white tw-shadow-2xl tw-border-t tw-z-10 tw-py-4">
          <div className="tw-px-6 tw-flex tw-w-full tw-items-center tw-justify-between">
            <span>{t("common.unsavedChanges.content")}</span>
            <div className="tw-flex tw-flex-row tw-space-x-4">
              <button
                type="reset"
                disabled={isLoading}
                onClick={onReset}
                className="tw-btn tw-btn-outline tw-min-w-[180px]">
                {t("common.unsavedChanges.button.cancel")}
              </button>
              <button type="submit" disabled={isLoading} className="tw-btn tw-btn-primary tw-min-w-[180px]">
                {t("common.unsavedChanges.button.save")}
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  )
}

const SettingsContainer = props => {
  const { t } = useTranslation()
  const [toast, setToast] = useState({ display: false, type: null, content: null })

  const onSave = async ({ image, ...values }, actions) => {
    let compressedFile = null
    let data = {
      //...values,
      is_public: values.is_public,
      pincode: values.pincode,
      name: values.name_en || values.name_fi,
      name_en: values.name_en,
      name_fi: values.name_fi,
      description: values.description_en || values.description_fi,
      description_en: values.description_en,
      description_fi: values.description_fi,
      municipal: values.municipal,
      address: values.address,
      phone: values.phone,
      display_call: values.display_call,
      display_message: values.display_message,
    }

    if (image?.name) {
      const filesize = (image.size / 1024 / 1024).toFixed(4) // MB
      const options = {
        maxSizeMB: MAX_IMAGE_FILE_SIZE,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        initialQuality: 0.6,
      }
      compressedFile = filesize > MAX_IMAGE_FILE_SIZE ? await imageCompression(image, options) : image
      data.image = compressedFile // Include image file to post request
    } else if (image === null) {
      data.delete_image = true
    }

    props
      .updateDestinationWithFile(props.selectedDestinationId, data)
      .then(response => {
        actions.setSubmitting(false)
        actions.resetForm({ values: response.payload })
        setToast(true, "success", "save.success")
      })
      .catch(() => setToast(true, "error", "save.failed"))
  }

  const formik = useFormik({
    initialValues: {
      is_public: props.destination?.is_public ?? false,
      pincode: props.destination?.pincode || "",
      access_code: props.destination?.access_code || "",
      name_en: props.destination?.name_en || "",
      name_fi: props.destination?.name_fi || "",
      address: props.destination?.address || "",
      country: props.destination?.country || "",
      municipal: props.destination?.municipal || "",
      phone: props.destination?.phone || "",
      description_en: props.destination?.description_en || "",
      description_fi: props.destination?.description_fi || "",
      image: props.destination?.image || null,
      display_call: props.destination?.display_call ?? false,
      display_message: props.destination?.display_message ?? false,
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: validationSchema(t),
    onSubmit: (values, actions) => onSave(values, actions),
  })

  return (
    <>
      {toast && toast.display && (
        <Toast
          className={"fixed-toast " + toast.type}
          onClose={() => this.setToast(false)}
          show={toast.display}
          delay={5000}
          autohide>
          <Toast.Header>
            <strong className="mr-auto">{t("common.notifications.type." + toast.type)}</strong>
          </Toast.Header>
          <Toast.Body>{t("settings.notifications." + toast.content.message, toast.content.values)}</Toast.Body>
        </Toast>
      )}
      <h1 className="tw-font-display tw-font-semibold tw-text-4xl tw-mb-6">{t("settings.title")}</h1>
      <div className={twClassNames(formik.dirty ? "tw-mb-20" : "tw-mb-12", "tw-w-full")}>
        <div className="tw-mb-6 tw-shadow tw-bg-white tw-rounded">
          <div className="tw-flex tw-p-6 max-md:tw-flex-col">
            {/* Left side */}
            <div className="tw-w-full md:tw-w-1/2">
              <Form formik={formik} isLoading={props.isLoading} t={t} />
            </div>
            {/* Right side */}
            <div className="tw-w-full md:tw-w-1/2 tw-min-w-[360px] max-xs:tw-hidden">
              <Preview data={formik.values} t={t} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

SettingsContainer.propTypes = {
  updateDestinationWithFile: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

SettingsContainer.defaultProps = {
  isLoading: false,
}

const mapStateToProps = state => ({
  isLoading: state.destinations.isLoading,
  selectedDestinationId: state.destinations.selectedDestinationId,
  destination: state.destinations.destinations.find(x => x.id === state.destinations.selectedDestinationId),
})

const mapDispatchToProps = dispatch => ({
  updateDestinationWithFile: (id, values) => dispatch(updateDestinationWithFile(id, values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer)
