import React, { Fragment, useState } from "react"
import { Combobox, Transition } from "@headlessui/react"
import { HiSelector, HiCheck } from "react-icons/hi"
import { useTranslation } from "react-i18next"

import { twClassNames } from "../helpers/classNames"

const isString = value => {
  return typeof value === "string" || value instanceof String
}

const toLowerCase = value => {
  return isString(value) ? value.toLowerCase() : value
}

// data = [{name: "foo"}, {name: "bar"}]
const SearchSelectMultiple = ({
  id,
  data,
  onChange,
  onBlur,
  value,
  errors,
  placeholder = "",
  valueKey = "name",
  nameKey = "name",
  indexKey = "name",
}) => {
  const { t } = useTranslation()
  const defaultValue = value ? data.filter(x => value.indexOf(x.id) !== -1) : []

  const [query, setQuery] = useState("")
  const filteredData =
    query === ""
      ? data
      : data.filter(item => {
          return toLowerCase(item[nameKey]).includes(query.toLowerCase())
        })

  return (
    <Combobox
      multiple
      value={defaultValue}
      onChange={value => value.length <= 5 && onChange(value.map(x => x[valueKey]))}>
      <div>
        <div
          className={twClassNames(
            errors && "tw-is-invalid",
            "tw-relative tw-w-full tw-rounded-lg tw-bg-white tw-text-left tw-cursor-default tw-h-10",
            "tw-border tw-border-dark-15 focus:tw-outline-none"
          )}>
          <Combobox.Input
            id={id}
            className="tw-w-full tw-border-none tw-py-2 tw-pl-3 tw-pr-10 tw-leading-5 tw-rounded-lg
              sm:tw-text-sm focus:tw-outline-primary-50 disabled:tw-bg-light-75 disabled:tw-text-dark-50"
            placeholder={placeholder}
            displayValue={() => ""}
            onBlur={onBlur}
            onChange={event => setQuery(event.target.value)}
          />
          <Combobox.Button className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
            <HiSelector className="tw-h-5 tw-w-5 tw-text-dark-50" aria-hidden="true" />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="tw-transition tw-ease-in tw-duration-100"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
          afterLeave={() => setQuery("")}>
          <Combobox.Options
            className="tw-absolute tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto 
            tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-border tw-border-dark-15
            focus:tw-outline-none sm:tw-text-sm tw-z-10">
            {filteredData.length === 0 && query !== "" ? (
              <div className="tw-relative tw-cursor-default tw-select-none tw-py-2 tw-px-4">
                {t("common.search.noResults")}
              </div>
            ) : (
              filteredData.map(value => (
                <Combobox.Option
                  key={value[indexKey]}
                  value={value}
                  className={({ active }) =>
                    twClassNames(
                      active && "tw-bg-primary tw-text-white",
                      "tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-10 tw-pr-4"
                    )
                  }>
                  {({ selected, active }) => (
                    <>
                      <span className={twClassNames(selected && "tw-font-medium", "tw-block tw-truncate")}>
                        {value[nameKey]}
                      </span>
                      {selected ? (
                        <span
                          className={twClassNames(
                            active ? "tw-text-white" : "tw-text-primary",
                            "tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
                          )}>
                          <HiCheck className="tw-h-5 tw-w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
        <div className="tw-invalid-tooltip">{errors}</div>
      </div>
    </Combobox>
  )
}

SearchSelectMultiple.propTypes = {}
SearchSelectMultiple.defaultProps = {}

export default SearchSelectMultiple
