import { postRequest } from "../helpers/fetchHandler"
import { clearAuthToken, setAuthToken } from "../storage/authStorage"
import { clearPreferences } from "../storage/preferencesStorage"
import {
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  LOGIN_LOADING,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOG_OUT,
  REQUEST_RESET_EMAIL_LOADING,
  REQUEST_RESET_EMAIL_SUCCESS,
  REQUEST_RESET_EMAIL_FAILED,
  PASSWORD_RESET_LOADING,
  PASSWORD_RESET_FAILED,
  PASSWORD_RESET_SUCCESS,
} from "../constants/actionTypes"

export function logout() {
  return async dispatch => {
    await clearAuthToken()
    await clearPreferences()
    return dispatch({
      type: LOG_OUT,
    })
  }
}

export function login(email, code) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING,
    })

    return postRequest({
      url: "/login/",
      data: {
        username: email,
        password: code,
      },
    })
      .then(data => setAuthToken(data.token))
      .then(data =>
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data,
          meta: {
            email,
          },
        })
      )
      .catch(error => {
        dispatch({
          type: LOGIN_FAILED,
          error,
        })
        return Promise.reject(error)
      })
  }
}

export function register(data) {
  return dispatch => {
    dispatch({
      type: REGISTER_LOADING,
    })
    const { email } = data

    return postRequest({
      url: "/api/register/",
      data,
    })
      .then(data =>
        dispatch({
          type: REGISTER_SUCCESS,
          payload: data,
          meta: {
            email,
          },
        })
      )
      .catch(error => {
        dispatch({
          type: REGISTER_FAILED,
          error,
        })
        return Promise.reject(error)
      })
  }
}

export function requestResetEmail(email) {
  return dispatch => {
    dispatch({
      type: REQUEST_RESET_EMAIL_LOADING,
    })

    return postRequest({
      url: "/api/request-reset-email/",
      data: {
        email,
        redirect_url: "/reset-password",
      },
    })
      .then(() =>
        dispatch({
          type: REQUEST_RESET_EMAIL_SUCCESS,
        })
      )
      .catch(error => {
        dispatch({
          type: REQUEST_RESET_EMAIL_FAILED,
          error,
        })
        return Promise.reject(error)
      })
  }
}

export function resetPassword(data) {
  return dispatch => {
    dispatch({
      type: PASSWORD_RESET_LOADING,
    })

    return postRequest({
      url: "/api/reset-password-complete/",
      data,
    })
      .then(() =>
        dispatch({
          type: PASSWORD_RESET_SUCCESS,
        })
      )
      .catch(error => {
        dispatch({
          type: PASSWORD_RESET_FAILED,
          error,
        })
        return Promise.reject(error)
      })
  }
}
