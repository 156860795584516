/* eslint-disable */
import React, { useState, Fragment } from "react"
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { HiNewspaper, HiCog, HiMenu, HiX, HiChevronDown } from "react-icons/hi"
import { Menu, Transition } from "@headlessui/react"

import { ReactComponent as Icon } from "../assets/gettips_icon_purple.svg"
import { ReactComponent as Logo } from "../assets/gettips_logo_purple.svg"

import { twClassNames } from "../helpers/classNames"
import { getLocaleValue } from "../helpers/getLocaleValue"

const projectsNavigation = [
  { key: "spacer", title: "sidebar.dropdown.handbook.title" },
  { key: "view-handbooks", title: "sidebar.dropdown.handbook.viewAll", href: "/handbooks" },
  { key: "create-handbook", title: "sidebar.dropdown.handbook.create", href: "/handbook/create" },
  { key: "spacer", title: "sidebar.dropdown.activities.title" },
  { key: "view-activities", title: "sidebar.dropdown.activities.viewAll", href: "/activities" },
  { key: "create-activity", title: "sidebar.dropdown.activities.create", href: "/activities/create" },
]

const ProjectsDropdown = ({ selectedProject, type, lang }) => {
  const { t } = useTranslation()
  const title = getLocaleValue(selectedProject, "name", lang)
  const subtitle = type === "handbook" ? selectedProject?.access_code : selectedProject?.company

  return (
    <Menu as="div" className="tw-relative">
      <Menu.Button
        className="tw-flex tw-items-center tw-w-full tw-p-5 tw-min-h-[85px] tw-bg-light hover:tw-bg-gray
        tw-border-b tw-border-t tw-border-gray">
        {selectedProject.image && (
          <img
            src={selectedProject.image}
            alt=""
            className="tw-object-fill tw-h-[44px] tw-w-[44px] tw-rounded-xl tw-justify-self-start tw-mr-2"
          />
        )}
        <div className="tw-flex tw-flex-col tw-leading-tight tw-text-left tw-grow tw-break-all">
          <span className="tw-line-clamp-2 tw-font-bold">{title}</span>
          <span className="tw-line-clamp-1 tw-text-dark-50">{subtitle}</span>
        </div>
        <HiChevronDown className="tw-text-2xl tw-min-w-[24px]" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="tw-transition tw-ease-out tw-duration-100"
        enterFrom="tw-transform tw-opacity-0 tw-scale-95"
        enterTo="tw-transform tw-opacity-100 tw-scale-100"
        leave="tw-transition tw-ease-in tw-duration-75"
        leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
        leaveTo="tw-transform tw-opacity-0 tw-scale-95">
        <Menu.Items
          className="tw-absolute tw-right-0 tw-z-10 tw-w-56 tw-origin-top-right tw-rounded-md tw-py-1
          tw-bg-white tw-shadow-lg tw-border tw-border-dark-15 focus:tw-outline-none">
          {projectsNavigation.map((item, index) => {
            if (item.key === "spacer") {
              return (
                <div className="tw-w-full tw-pt-4 tw-px-5 tw-border-b tw-border-gray" key={index}>
                  <p className="tw-text-dark-50 tw-uppercase tw-text-sm tw-mb-2">{t(item.title)}</p>
                </div>
              )
            }
            return (
              <Menu.Item key={index}>
                {({ active }) => (
                  <Link
                    to={item.href}
                    className={twClassNames(
                      active && "tw-bg-gray",
                      "tw-block tw-px-5 tw-py-2 tw-text hover:tw-no-underline hover:tw-text-dark"
                    )}>
                    {t(item.title)}
                  </Link>
                )}
              </Menu.Item>
            )
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

const handbookNavigation = [
  {
    key: "spacer",
    title: "sidebar.handbook.handbook",
  },
  // {
  //   key: "dashboard",
  //   title: "sidebar.handbook.dashboard",
  //   icon: <HiHome className="tw-text-xl" />,
  //   href: "/handbook/dashboard",
  // },
  {
    key: "guides",
    title: "sidebar.handbook.guides",
    icon: <HiNewspaper className="tw-text-xl" />,
    href: "/handbook/guides",
  },
  {
    key: "settings",
    title: "sidebar.handbook.settings",
    icon: <HiCog className="tw-text-xl" />,
    href: "/handbook/settings",
  },
  // {
  //   key: "spacer",
  //   title: "sidebar.handbook.workspace",
  // },
  // {
  //   key: "team",
  //   title: "sidebar.handbook.team",
  //   icon: <HiUsers className="tw-text-xl" />,
  //   href: "/handbook/team",
  // },
  // {
  //   key: "billing",
  //   title: "sidebar.handbook.billing",
  //   icon: <HiCreditCard className="tw-text-xl" />,
  //   href: "/handbook/billing",
  // },
  {
    key: "spacer",
    title: "sidebar.handbook.details",
  },
  {
    key: "examples",
    title: "sidebar.handbook.examples",
    icon: null,
    href: "/handbook/examples",
  },
  // {
  //   key: "partners",
  //   title: "sidebar.handbook.partners",
  //   icon: null,
  //   href: "/handbook/partners",
  // },
]

const activitiesNavigation = [
  {
    key: "spacer",
    title: "sidebar.activities.activities",
  },
  {
    key: "campaigns",
    title: "sidebar.activities.campaigns",
    icon: <HiNewspaper className="tw-text-xl" />,
    href: "/activities/campaigns",
  },
  {
    key: "profile",
    title: "sidebar.activities.profile",
    icon: <HiCog className="tw-text-xl" />,
    href: "/activities/profile",
  },
  // {
  //   key: "spacer",
  //   title: "sidebar.activities.workspace",
  // },
  // {
  //   key: "team",
  //   title: "sidebar.activities.team",
  //   icon: <HiUsers className="tw-text-xl" />,
  //   href: "/activities/team",
  // },
  // {
  //   key: "billing",
  //   title: "sidebar.activities.billing",
  //   icon: <HiCreditCard className="tw-text-xl" />,
  //   href: "/activities/billing",
  // },
]

function Sidebar({ selectedProject, type }) {
  const [visible, setVisibility] = useState(false)
  const location = useLocation()
  const { t, i18n } = useTranslation()

  const active = location && location.pathname.split("/")[2]
  const navigation = type === "handbook" ? handbookNavigation : activitiesNavigation

  return (
    <>
      <div className={"tw-px-0 sidebar-navigation " + (visible ? "show" : "hide")}>
        <div className="tw-flex tw-flex-col tw-h-full tw-bg-white tw-shadow">
          <Logo alt="Get Tips logo" className="tw-my-10 tw-mx-auto tw-w-[7rem]" />
          {selectedProject && <ProjectsDropdown selectedProject={selectedProject} type={type} lang={i18n.language} />}
          <div className="tw-flex tw-flex-col tw-space-y-2 tw-mt-4 tw-grow">
            {navigation.map((item, index) => {
              if (item.key === "spacer") {
                return (
                  <div className="tw-w-full tw-pt-4 tw-px-5 tw-border-b tw-border-gray" key={index}>
                    <p className="tw-text-dark-50 tw-uppercase tw-text-sm tw-mb-2">{t(item.title)}</p>
                  </div>
                )
              }
              return (
                <Link
                  key={index}
                  to={item.href}
                  className={twClassNames(
                    item.key === active
                      ? "tw-bg-primary tw-text-white tw-font-semibold hover:tw-text-white"
                      : "hover:tw-text-dark hover:tw-bg-gray",
                    "tw-mx-2.5 tw-h-[50px] tw-px-4 tw-flex tw-items-center tw-rounded-xl tw-space-x-2.5 hover:tw-no-underline"
                  )}
                  aria-current={item.key === active ? "page" : undefined}>
                  {item.icon} <span>{t(item.title)}</span>
                </Link>
              )
            })}
          </div>
          <div className="tw-flex tw-items-center tw-py-4 tw-px-5 tw-space-x-5 tw-border-t tw-border-gray">
            <Icon alt="Get Tips icon" className="tw-object-scale-down tw-h-[24px] tw-w-[24px]" />
            <div className="tw-flex tw-flex-col tw-text-sm">
              <span>Get Tips © {new Date().getFullYear()}</span>
              <span className="tw-text-dark-50 tw-capitalize">
                {process.env.NODE_ENV} {process.env.REACT_APP_VERSION} ({process.env.REACT_APP_BUILD})
              </span>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={() => setVisibility(!visible)}
        className={twClassNames(
          "tw-text-white tw-bg-dark tw-rounded tw-px-3 tw-py-2 sidebar-toggle",
          visible ? "open" : "closed"
        )}>
        {visible ? <HiX /> : <HiMenu />}
      </button>
    </>
  )
}

Sidebar.propTypes = {}
Sidebar.defaultProps = {}

export default Sidebar
