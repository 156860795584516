import {
  LOG_OUT,
  ACTIVITIES_LOADING,
  ACTIVITIES_SUCCESS,
  ACTIVITIES_FAILED,
  ACTIVITY_SELECTED,
  ACTIVITY_UNSELECTED,
  CREATE_ACTIVITIES_LOADING,
  CREATE_ACTIVITIES_SUCCESS,
  CREATE_ACTIVITIES_FAILED,
  UPDATE_ACTIVITIES_LOADING,
  UPDATE_ACTIVITIES_SUCCESS,
  UPDATE_ACTIVITIES_FAILED,
  DELETE_ACTIVITIES_LOADING,
  DELETE_ACTIVITIES_SUCCESS,
  DELETE_ACTIVITIES_FAILED,
  SUBCATEGORIES_LOADING,
  SUBCATEGORIES_SUCCESS,
  SUBCATEGORIES_FAILED,
} from "../constants/actionTypes"

const initialState = {
  isLoading: false,
  categories: [],
  activities: [],
  selectedActivityId: null,
}

function updateActivity(activities, activity) {
  return [...activities.map(data => (data.id === activity.id ? activity : data))]
}

function deleteActivity(activities, id) {
  return [...activities.filter(data => data.id !== id)]
}

export default function (state = initialState, action) {
  switch (action.type) {
    // =============================================================================
    case ACTIVITIES_LOADING:
      return { ...state, isLoading: true }
    case ACTIVITIES_SUCCESS:
      return { ...state, isLoading: false, activities: action.payload }
    case ACTIVITIES_FAILED:
      return { ...state, isLoading: false }
    // =============================================================================
    case ACTIVITY_SELECTED:
      return { ...state, selectedActivityId: action.payload }
    case ACTIVITY_UNSELECTED:
      return { ...state, selectedActivityId: null }
    // =============================================================================
    case CREATE_ACTIVITIES_LOADING:
      return { ...state, isLoading: true }
    case CREATE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activities: [...state.activities, action.payload],
        selectedActivityId: action.payload.id,
      }
    case CREATE_ACTIVITIES_FAILED:
      return { ...state, isLoading: false }
    // =============================================================================
    case UPDATE_ACTIVITIES_LOADING:
      return { ...state, isLoading: true }
    case UPDATE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activities: updateActivity(state.activities, action.payload),
      }
    case UPDATE_ACTIVITIES_FAILED:
      return { ...state, isLoading: false }
    // =============================================================================
    case DELETE_ACTIVITIES_LOADING:
      return { ...state, isLoading: true }
    case DELETE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activities: deleteActivity(state.activities, action.payload),
      }
    case DELETE_ACTIVITIES_FAILED:
      return { ...state, isLoading: false }
    // =============================================================================
    case SUBCATEGORIES_LOADING:
      return { ...state, isLoading: true }
    case SUBCATEGORIES_SUCCESS:
      return { ...state, isLoading: false, categories: action.payload }
    case SUBCATEGORIES_FAILED:
      return { ...state, isLoading: false }
    // =============================================================================
    case LOG_OUT:
      return initialState
    // =============================================================================
    default:
      break
  }
  return state
}
