import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  Nav,
  Form,
  Toast,
  ListGroup,
  Button,
  DropdownButton,
  Dropdown,
} from "react-bootstrap"
import { HiOutlineMenu, HiOutlineRefresh, HiPencil, HiTrash } from "react-icons/hi"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { Formik } from "formik"
import * as Yup from "yup"
import imageCompression from "browser-image-compression"
import { withTranslation } from "react-i18next"
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg/dist/ffmpeg.min.js"

import { updateGuide, addImageToGuide, addVideoToGuide } from "../../../../actions/guides"
import IonIcon from "../../../../components/ionIcon"
import FileUploader from "../../../../components/fileUploader"
import ConfirmationModal from "../../../../components/confirmation"
import { getLocaleValue } from "../../../../helpers/getLocaleValue"
import { reorderArray } from "../../../../helpers/reorderArray"
import { GuideText, GuideImage, GuideVideo, GuideVideoThumbnail, GuideModal } from "../components"

import { MAX_IMAGE_FILE_SIZE, MAX_VIDEO_FILE_SIZE } from "../../../../constants/fileHandling"

const validationSchema = t => {
  return Yup.object({
    title: Yup.string().max(160, t("guides.validation.invalidMaxTitleLength")),
    paragraph: Yup.string(),
    "title-en": Yup.string().max(160, t("guides.validation.invalidMaxTitleLength")),
    "paragraph-en": Yup.string(),
  })
}

const renderSwitch = (data, language) => {
  switch (Object.keys(data)[0]) {
    case "text":
      return <GuideText data={data.text} language={language} list />
    case "image":
      return <GuideImage data={data.image} list />
    case "video":
      return <GuideVideoThumbnail data={data.video} list />
    default:
      return null
  }
}

const SortableItem = SortableElement(({ value, language, onClick, selected }) => (
  <ListGroup.Item className={selected ? "selected" : ""} onClick={onClick}>
    <div className="round-shadow-btn">
      <HiOutlineMenu />
    </div>
    {renderSwitch(value, language)}
  </ListGroup.Item>
))

const SortableList = SortableContainer(({ items, language, onClick, selectedContentIndex }) => {
  return (
    <div>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          sortIndex={index}
          value={value}
          language={language}
          onClick={() => onClick(index, value)}
          selected={index === selectedContentIndex}
        />
      ))}
    </div>
  )
})

class EditGuideContainer extends Component {
  constructor(props) {
    super(props)
    const { id } = this.props.match.params
    this.formRef = React.createRef()

    this.state = {
      toast: { display: false, type: "success", content: null, autohide: true },
      language: props.i18n.language,
      selectedGuideId: parseInt(id),
      showEditTitleModal: false,
      confirmation: {
        show: false,
        callback: null,
        title: "",
        content: "",
        danger: false,
      },
    }
  }

  setToast = (display, type, message, values, autohide = true) => {
    this.setState({ toast: { display, type, autohide, content: { message, values } } })
  }

  sortEnd = (oldIndex, newIndex) => {
    const { selectedGuideId, selectedContentIndex } = this.state
    const guide = this.props.destination.guides.find(x => x.id === selectedGuideId)

    if (guide && guide.id) {
      const updatedContent = reorderArray(oldIndex, newIndex, guide.content)
      const updatedGuide = { ...guide, content: updatedContent }

      this.props
        .updateGuide(guide.id, updatedGuide)
        .then(() => {
          // Set selected content index to match new order
          if (oldIndex === selectedContentIndex) {
            this.setState({ selectedContentIndex: newIndex })
          } else if (oldIndex <= selectedContentIndex && newIndex >= selectedContentIndex) {
            this.setState({ selectedContentIndex: selectedContentIndex - 1 })
          } else if (oldIndex >= selectedContentIndex && newIndex <= selectedContentIndex) {
            this.setState({ selectedContentIndex: selectedContentIndex + 1 })
          }
        })
        .catch(e => this.setToast(true, "error", "sort.failed"))
    } else {
      this.setToast(true, "error", "sort.failed")
    }
  }

  onSortEnd = (form, oldIndex, newIndex) => {
    if (oldIndex === newIndex) return

    if (!form || !form.dirty) {
      this.sortEnd(oldIndex, newIndex)
    } else {
      this.setState({
        confirmation: {
          show: true,
          callback: () => {
            this.hideConfirmation()
            this.sortEnd(oldIndex, newIndex)
          },
          title: "dirty",
          content: "dirty",
        },
      })
    }
  }

  selectContent = (index, value) => {
    let selectedType = Object.keys(value)[0]
    this.setState({ selectedContentIndex: index, selectedContent: value, selectedType: selectedType })
  }

  onSelectContent = (form, index, value) => {
    if (!form || !form.dirty) {
      this.selectContent(index, value)
    } else {
      this.setState({
        confirmation: {
          show: true,
          callback: () => {
            this.hideConfirmation()
            this.selectContent(index, value)
          },
          title: "dirty",
          content: "dirty",
        },
      })
    }
  }

  onSaveContent = (values, resetForm) => {
    const { selectedGuideId, selectedContentIndex } = this.state
    const guide = this.props.destination.guides.find(x => x.id === selectedGuideId)

    if (guide && guide.id) {
      // Make copy of current guide
      const updatedGuide = Object.assign({}, guide)
      const updatedTextContent = {
        title: values["title"],
        paragraph: values["paragraph"],
        "title-en": values["title-en"],
        "paragraph-en": values["paragraph-en"],
      }
      // Edit guide text
      updatedGuide.content[selectedContentIndex] = { text: updatedTextContent }
      // Save changes
      this.props
        .updateGuide(guide.id, updatedGuide)
        .then(() => this.setToast(true, "success", "updateContent.success"))
        .then(() => resetForm({ values: updatedTextContent }))
        .catch(e => this.setToast(true, "error", "updateContent.failed"))
    } else {
      this.setToast(true, "error", "updateContent.failed")
    }
  }

  onEditGuide = values => {
    this.setState({ showEditTitleModal: false })

    const { selectedGuideId } = this.state
    const guide = this.props.destination.guides.find(x => x.id === selectedGuideId)

    if (guide && guide.id) {
      // Make copy of current guide
      const updatedGuide = Object.assign({}, guide)
      // Edit guide values
      updatedGuide["topic"] = values.topic_en !== "" ? values["topic_en"] : values["topic_fi"]
      updatedGuide["topic_en"] = values["topic_en"]
      updatedGuide["topic_fi"] = values["topic_fi"]
      updatedGuide["icon"] = values["icon"]
      updatedGuide["is_draft"] = values["is_draft"]
      // Save changes
      this.props
        .updateGuide(guide.id, updatedGuide)
        .then(() => this.setToast(true, "success", "updateGuide.success"))
        .catch(e => this.setToast(true, "error", "updateGuide.failed"))
    } else {
      this.setToast(true, "error", "updateGuide.failed")
    }
  }

  addTextContent = () => {
    const { selectedGuideId } = this.state
    const guide = this.props.destination.guides.find(x => x.id === selectedGuideId)

    if (guide && guide.id) {
      // Make copy of current guide
      const updatedGuide = Object.assign({}, guide)
      // Add new empty text content
      updatedGuide.content.push({
        text: {
          title: "",
          paragraph: "",
          "title-en": "",
          "paragraph-en": "",
        },
      })
      // Save changes
      this.props
        .updateGuide(guide.id, updatedGuide)
        .then(response => response.payload)
        .then(response => {
          const index = response.content.length - 1
          const selectedContent = response.content[index]
          this.selectContent(index, selectedContent)
        })
        .then(() => this.setToast(true, "success", "addTextContent.success"))
        .catch(e => this.setToast(true, "error", "addTextContent.failed"))
    } else {
      this.setToast(true, "error", "addTextContent.failed")
    }
  }

  onAddTextContent = form => {
    if (!form || !form.dirty) {
      this.addTextContent()
    } else {
      this.setState({
        confirmation: {
          show: true,
          callback: () => {
            this.hideConfirmation()
            this.addTextContent()
          },
          title: "dirty",
          content: "dirty",
        },
      })
    }
  }

  onAddImageContent = async image => {
    const { selectedGuideId } = this.state
    const guide = this.props.destination.guides.find(x => x.id === selectedGuideId)

    if (guide && guide.id) {
      const filesize = (image.size / 1024 / 1024).toFixed(4) // MB
      const options = {
        maxSizeMB: MAX_IMAGE_FILE_SIZE,
        maxWidthOrHeight: 1280,
        useWebWorker: true,
        initialQuality: 0.4,
      }
      const compressedFile = filesize > MAX_IMAGE_FILE_SIZE ? await imageCompression(image, options) : image

      this.props
        .addImageToGuide(compressedFile, guide.id)
        .then(() => this.setToast(true, "success", "addImageContent.success"))
        .catch(e => this.setToast(true, "error", "addImageContent.failed"))
    } else {
      this.setToast(true, "error", "addImageContent.failed")
    }
  }

  getThumbnail = async inputVideo => {
    if (inputVideo.duration > 90000) {
      // limit to 90 seconds
      this.setToast(true, "error", "addVideoContent.failed.duration")
      return null
    }

    const filesize = (inputVideo.size / 1024 / 1024).toFixed(4) // MB
    if (filesize > MAX_VIDEO_FILE_SIZE) {
      this.setToast(true, "error", "addVideoContent.failed.size", { max_size: MAX_VIDEO_FILE_SIZE })
      return null
    }

    const thumbnailFile = "thumnail.jpg"
    const inputFile = inputVideo.name

    try {
      this.setToast(true, "init", "compressing.thumbnail", null, false)

      const ffmpeg = createFFmpeg({
        corePath: "https://unpkg.com/@ffmpeg/core-st@0.11.1/dist/ffmpeg-core.js",
        mainName: "main",
      })
      await ffmpeg.load()

      ffmpeg.FS("writeFile", inputFile, await fetchFile(inputVideo)) // Create temporary file
      await ffmpeg.run(
        "-y",
        "-i",
        inputFile,
        "-ss",
        "00:00:01.00",
        "-frames:v",
        "1",
        "-vf",
        "scale=720:-2",
        "-q:v",
        "8",
        thumbnailFile
      )

      const thumbnail = ffmpeg.FS("readFile", thumbnailFile)
      const thumbnailBlob = new Blob([thumbnail.buffer], { type: "image/jpeg" })

      return thumbnailBlob
    } catch (e) {
      this.setToast(true, "error", "addVideoContent.failed.default")
    }
    return null
  }

  getCompressedVideo = async inputVideo => {
    if (inputVideo.duration > 90000) {
      // limit to 90 seconds
      this.setToast(true, "error", "addVideoContent.failed.duration")
      return null
    }

    const videoFile = "video.mp4"
    const thumbnailFile = "thumnail.jpg"
    const inputFile = inputVideo.name

    try {
      this.setToast(true, "init", "compressing.init", null, false)

      const ffmpeg = createFFmpeg({
        corePath: "https://unpkg.com/@ffmpeg/core-st@0.11.1/dist/ffmpeg-core.js",
        mainName: "main",
      })
      await ffmpeg.load()

      this.setToast(true, "compressing", "compressing.running", null, false)

      ffmpeg.FS("writeFile", inputFile, await fetchFile(inputVideo)) // Create temporary file
      await ffmpeg.run(
        "-y",
        "-i",
        inputFile,
        "-ss",
        "00:00:01.00",
        "-frames:v",
        "1",
        "-vf",
        "scale=720:-2",
        "-q:v",
        "8",
        thumbnailFile,
        "-vf",
        "scale=w='if(gte(iw,ih),min(1280,iw),-2)':h='if(lt(iw,ih),min(1280,ih),-2)'",
        "-c:v",
        "libx264",
        "-crf",
        "26",
        "-preset",
        "fast",
        "-r",
        "30",
        videoFile
      )

      const video = ffmpeg.FS("readFile", videoFile)
      const videoBlob = new Blob([video.buffer], { type: "video/mp4" })

      const thumbnail = ffmpeg.FS("readFile", thumbnailFile)
      const thumbnailBlob = new Blob([thumbnail.buffer], { type: "image/jpeg" })

      const filesize = (videoBlob.size / 1024 / 1024).toFixed(4) // MB
      if (filesize > MAX_VIDEO_FILE_SIZE) {
        this.setToast(true, "error", "addVideoContent.failed.size", { max_size: MAX_VIDEO_FILE_SIZE })
        return null
      }

      return { video: videoBlob, thumbnail: thumbnailBlob }
    } catch (e) {
      this.setToast(true, "error", "addVideoContent.failed.default")
    }
    return null
  }

  onAddVideoContent = async video => {
    const { selectedGuideId } = this.state
    const guide = this.props.destination.guides.find(x => x.id === selectedGuideId)

    if (guide && guide.id && video) {
      const thumbnail = await this.getThumbnail(video)
      if (thumbnail) {
        this.props
          .addVideoToGuide(video, thumbnail, guide.id)
          .then(() => this.setToast(true, "success", "addVideoContent.success"))
          .catch(e => this.setToast(true, "error", "addVideoContent.failed.default"))
      }
    } else {
      this.setToast(true, "error", "addVideoContent.failed.default")
    }
  }

  onAddCompressedVideoContent = async video => {
    const { selectedGuideId } = this.state
    const guide = this.props.destination.guides.find(x => x.id === selectedGuideId)

    if (guide && guide.id && video) {
      const data = await this.getCompressedVideo(video)
      if (data) {
        this.props
          .addVideoToGuide(data.video, data.thumbnail, guide.id)
          .then(() => this.setToast(true, "success", "addVideoContent.success"))
          .catch(e => this.setToast(true, "error", "addVideoContent.failed.default"))
      }
    } else {
      this.setToast(true, "error", "addVideoContent.failed.default")
    }
  }

  openTitleEditModal = () => {
    this.setState({ showEditTitleModal: true })
  }

  closeTitleEditModal = () => {
    this.setState({ showEditTitleModal: false })
  }

  deleteContent = () => {
    const { selectedGuideId, selectedContentIndex } = this.state
    const guide = this.props.destination.guides.find(x => x.id === selectedGuideId)

    if (guide && guide.id && selectedContentIndex !== null) {
      // Make copy of current guide
      const updatedGuide = Object.assign({}, guide)
      // Remove guide text
      updatedGuide.content.splice(selectedContentIndex, 1)
      // Save changes
      this.props
        .updateGuide(guide.id, updatedGuide)
        .then(() => this.setToast(true, "success", "deleteContent.success"))
        .then(() =>
          this.setState({
            selectedContentIndex: null,
            selectedContent: null,
            selectedType: null,
          })
        )
        .catch(e => this.setToast(true, "error", "deleteContent.failed"))
    } else {
      this.setToast(true, "error", "deleteContent.failed")
    }
  }

  onDeleteContent = () => {
    this.setState({
      confirmation: {
        show: true,
        callback: () => {
          this.hideConfirmation()
          this.deleteContent()
        },
        title: "delete",
        content: "delete.content",
        danger: true,
      },
    })
  }

  hideConfirmation = () => {
    this.setState({
      confirmation: {
        show: false,
        callback: null,
        title: "",
        content: "",
        danger: false,
      },
    })
  }

  render() {
    const { toast, language, selectedGuideId, selectedContentIndex, selectedContent, selectedType } = this.state
    const { t, destination, isLoading } = this.props
    if (!destination) return null // Fixup, prevent crash after logout

    const guide = destination.guides.find(x => x.id === selectedGuideId) // Selected guide

    return (
      <Container fluid className="mb-2">
        {!!toast && !!toast.display && (
          <Toast
            className={"fixed-toast " + toast.type}
            onClose={() => this.setToast(false)}
            show={!!toast.display}
            delay={5000}
            autohide={!!toast.autohide}>
            <Toast.Header>
              <strong className="mr-auto">{t("common.notifications.type." + toast.type)}</strong>
              {!toast.autohide && <HiOutlineRefresh className="slow-spin icon loading-spinner-icon ml-1" />}
            </Toast.Header>
            <Toast.Body>{t("guides.notifications." + toast.content?.message, toast.content?.values)}</Toast.Body>
          </Toast>
        )}

        <GuideModal
          show={this.state.showEditTitleModal}
          data={guide}
          onSubmit={this.onEditGuide}
          onCancel={this.closeTitleEditModal}
        />

        <ConfirmationModal
          show={this.state.confirmation.show}
          title={this.state.confirmation.title}
          content={this.state.confirmation.content}
          onOK={this.state.confirmation.callback}
          onCancel={this.hideConfirmation}
          danger={this.state.confirmation.danger}
        />

        <Row>
          <Col>
            <h3 className="mb-4 font-weight-bold">{t("guides.title")}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Row>
                <Col xs={3}>
                  {guide && (
                    <ListGroup className="h-100 br-2">
                      <div className="p-3 guide-list-header">
                        <Row>
                          <Col xs={12}>
                            <Nav
                              justify
                              defaultActiveKey={language}
                              onSelect={language => this.setState({ language })}
                              className="mb-3 language-navigation">
                              <Nav.Item>
                                <Nav.Link eventKey="fi">{t("common.languages.fi")}</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="en">{t("common.languages.en")}</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col xs={12}>
                            <IonIcon name={guide.icon} />
                            <span className="ml-2 font-weight-bold">{getLocaleValue(guide, "topic", language)}</span>
                            <Button
                              variant="link"
                              className="btn p-0 float-right text-primary"
                              onClick={this.openTitleEditModal}>
                              <HiPencil className="icon mr-1" />
                              <span className="small">{t("guides.button.edit")}</span>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      {isLoading && (
                        <div className="loading-spinner">
                          <HiOutlineRefresh className="slow-spin icon loading-spinner-icon" />
                        </div>
                      )}
                      <SortableList
                        items={guide.content}
                        selectedContentIndex={selectedContentIndex}
                        language={language}
                        onSortEnd={({ oldIndex, newIndex }) => this.onSortEnd(this.formRef.current, oldIndex, newIndex)}
                        distance={5}
                        onClick={(index, value) => this.onSelectContent(this.formRef.current, index, value)}
                      />
                      {!guide.content.length && (
                        <ListGroup.Item>
                          <p className="text-muted text-center font-italic m-0 p-4">
                            {t("guides.selectedGuide.noContent")}
                          </p>
                        </ListGroup.Item>
                      )}
                      <ListGroup.Item className="h-100 d-flex flex-column justify-content-end">
                        <DropdownButton
                          title={t("guides.button.new")}
                          id="add-guide-content-dropdown"
                          className="text-center m-2 dropdown-btn-block">
                          <Dropdown.Item onClick={() => this.onAddTextContent(this.formRef.current)}>
                            {t("guides.button.dropdown.text")}
                          </Dropdown.Item>
                          <FileUploader handleFile={this.onAddImageContent} accept="image/*">
                            <Dropdown.Item>{t("guides.button.dropdown.image")}</Dropdown.Item>
                          </FileUploader>
                          <FileUploader
                            handleFile={this.onAddCompressedVideoContent}
                            accept="video/mp4,video/x-m4v,video/*"
                            tooltip={t("guides.tooltips.compress")}>
                            <Dropdown.Item>{t("guides.button.dropdown.video")}</Dropdown.Item>
                          </FileUploader>
                          <FileUploader
                            handleFile={this.onAddVideoContent}
                            accept="video/mp4,video/x-m4v,video/*"
                            tooltip={t("guides.tooltips.video", { max_size: MAX_VIDEO_FILE_SIZE })}>
                            <Dropdown.Item bsPrefix="dropdown-item text-prewrap-xl">
                              {t("guides.button.dropdown.uncompressedVideo")}
                            </Dropdown.Item>
                          </FileUploader>
                        </DropdownButton>
                      </ListGroup.Item>
                    </ListGroup>
                  )}
                </Col>
                <Col xs={9}>
                  <div className="py-4 pr-4">
                    <Row className="mb-3">
                      <Col>{selectedType && <h5>{t("guides.selectedContent.title." + selectedType)}</h5>}</Col>
                      <Col>
                        {selectedContentIndex >= 0 && (
                          <Button
                            variant="link"
                            className="float-right text-danger"
                            disabled={isLoading}
                            onClick={this.onDeleteContent}>
                            <HiTrash className="icon mr-1" />
                            {t("guides.button.delete")}
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {selectedContent && selectedType === "image" && (
                          <GuideImage data={selectedContent.image} full />
                        )}
                        {selectedContent && selectedType === "video" && (
                          <GuideVideo data={selectedContent.video} full />
                        )}
                        {selectedContent && selectedType === "text" && (
                          <Formik
                            innerRef={this.formRef}
                            key={"Formik-" + selectedContentIndex}
                            enableReinitialize={true}
                            initialValues={selectedContent.text}
                            validationSchema={validationSchema(t)}
                            validateOnBlur={false}
                            onSubmit={(values, { resetForm }) => this.onSaveContent(values, resetForm)}>
                            {({ handleSubmit, handleReset, ...props }) => (
                              <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                  <Form.Group as={Col} xs={12} xl={6} controlId="title" className="position-relative">
                                    <Form.Text className="text-muted">{t("guides.form.title_fi")}</Form.Text>
                                    <Form.Control
                                      autoFocus
                                      type="text"
                                      name="title"
                                      placeholder=""
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.title}
                                      isInvalid={props.touched.title && props.errors.title}
                                      className={props.touched.title && props.errors.title ? "has-error" : null}
                                    />
                                    <Form.Control.Feedback tooltip type="invalid">
                                      {props.errors.title}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group
                                    as={Col}
                                    xs={12}
                                    xl={6}
                                    controlId="title-en"
                                    className="position-relative">
                                    <Form.Text className="text-muted">{t("guides.form.title_en")}</Form.Text>
                                    <Form.Control
                                      type="text"
                                      name="title-en"
                                      placeholder=""
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values["title-en"]}
                                      isInvalid={props.touched["title-en"] && props.errors["title-en"]}
                                      className={
                                        props.touched["title-en"] && props.errors["title-en"] ? "has-error" : null
                                      }
                                    />
                                    <Form.Control.Feedback tooltip type="invalid">
                                      {props.errors["title-en"]}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Row>
                                <Row>
                                  <Form.Group
                                    as={Col}
                                    xs={12}
                                    xl={6}
                                    controlId="paragraph"
                                    className="position-relative">
                                    <Form.Text className="text-muted">{t("guides.form.paragraph_fi")}</Form.Text>
                                    <Form.Control
                                      as="textarea"
                                      rows={6}
                                      name="paragraph"
                                      placeholder=""
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values.paragraph}
                                      isInvalid={props.touched.paragraph && props.errors.paragraph}
                                      className={props.touched.paragraph && props.errors.paragraph ? "has-error" : null}
                                      spellCheck="false"
                                    />
                                    <Form.Control.Feedback tooltip type="invalid">
                                      {props.errors.paragraph}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group
                                    as={Col}
                                    xs={12}
                                    xl={6}
                                    controlId="paragraph-en"
                                    className="position-relative">
                                    <Form.Text className="text-muted">{t("guides.form.paragraph_en")}</Form.Text>
                                    <Form.Control
                                      as="textarea"
                                      rows={6}
                                      name="paragraph-en"
                                      placeholder=""
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                      value={props.values["paragraph-en"]}
                                      isInvalid={props.touched["paragraph-en"] && props.errors["paragraph-en"]}
                                      className={
                                        props.touched["paragraph-en"] && props.errors["paragraph-en"]
                                          ? "has-error"
                                          : null
                                      }
                                      spellCheck="false"
                                    />
                                    <Form.Control.Feedback tooltip type="invalid">
                                      {props.errors["paragraph-en"]}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Row>
                                {props.dirty && (
                                  <div className="fixed-footer-bar">
                                    <div className="float-left">
                                      <span className="text-dark align-middle">
                                        {t("common.unsavedChanges.content")}
                                      </span>
                                    </div>
                                    <div className="float-right">
                                      <Button
                                        variant="link"
                                        onClick={handleReset}
                                        className="mr-4"
                                        type="reset"
                                        disabled={isLoading}>
                                        {t("common.unsavedChanges.button.cancel")}
                                      </Button>
                                      <Button
                                        variant="primary"
                                        className="btn-default-lg"
                                        type="submit"
                                        disabled={isLoading}>
                                        {t("common.unsavedChanges.button.save")}
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </Form>
                            )}
                          </Formik>
                        )}
                        {!selectedContent && (
                          <p className="text-muted font-italic mt-5 text-center">
                            {t("guides.selectedContent.unselected")}
                          </p>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

EditGuideContainer.propTypes = {
  isLoading: PropTypes.bool,
}

EditGuideContainer.defaultProps = {
  isLoading: false,
}

const mapStateToProps = state => ({
  isLoading: state.destinations.isLoading,
  destination: state.destinations.destinations.find(x => x.id === state.destinations.selectedDestinationId),
})

const mapDispatchToProps = dispatch => ({
  updateGuide: (id, data) => dispatch(updateGuide(id, data)),
  addImageToGuide: (image, id) => dispatch(addImageToGuide(image, id)),
  addVideoToGuide: (video, thumbnail, id) => dispatch(addVideoToGuide(video, thumbnail, id)),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditGuideContainer))
