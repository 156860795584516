import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Formik } from "formik"
import { Row, Col, Button, Form } from "react-bootstrap"
import * as Yup from "yup"
import { useTranslation, Trans } from "react-i18next"

const validationSchema = t => {
  return Yup.object({
    email: Yup.string().email(t("common.validation.invalidEmail")).required(t("common.validation.required")),
  })
}

const Recovery = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation()

  return (
    <>
      <h1 className="title mb-5">{t("auth.recovery")}</h1>
      <Formik
        enableReinitialize={true}
        initialValues={{ email: "" }}
        validationSchema={validationSchema(t)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => onSubmit(values.email)}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mt-2">
              <Col>
                <Form.Group controlId="LoginFormEmail" className="position-relative">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder={t("auth.form.email")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={touched.email && errors.email}
                  />
                  <Form.Control.Feedback tooltip type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xl={{ span: 8, offset: 2 }} xs={12}>
                <Button block variant="primary" type="submit" disabled={isLoading}>
                  {t("auth.button.submit")}
                </Button>
              </Col>
            </Row>
            <Row className="small">
              <Col>
                <Trans i18nKey="auth.button.backToLogin">
                  <Link className="underline" to="/login"></Link>
                </Trans>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

Recovery.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
Recovery.defaultProps = {}

export default Recovery
