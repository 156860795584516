import React from "react"
import { useTranslation } from "react-i18next"
import { HiQuestionMarkCircle } from "react-icons/hi"

const Tooltip = ({ message, displayLabel = false }) => {
  const { t } = useTranslation()

  return (
    <div className="tw-relative tw-flex tw-grow tw-justify-end">
      <div className="tw-group">
        {!!displayLabel && (
          <span className="tw-text-xs tw-text-dark-50 tw-font-normal tw-mr-1">{t("common.validation.tooltip")}</span>
        )}
        <HiQuestionMarkCircle className="tw-h-4 tw-w-4 tw-text-dark-50" />
        <span
          className="tw-absolute tw-max-w-[350px] tw-min-w-[200px] tw-bottom-5 tw-right-0 tw-rounded-md tw-bg-dark 
            tw-p-3 tw-text-sm tw-text-center tw-text-white tw-transition-opacity tw-ease-in tw-duration-200
            tw-opacity-0 tw-hidden group-hover:tw-opacity-100 group-hover:tw-block">
          {t(message)}
        </span>
      </div>
    </div>
  )
}

Tooltip.propTypes = {}
Tooltip.defaultProps = {}

export default Tooltip
