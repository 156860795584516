import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Container, Row, Col, Alert } from "react-bootstrap"
import { Switch, Route } from "react-router-dom"
import { withTranslation } from "react-i18next"

import { Login, Recovery, Register, ResetPassword } from "./views"
import { login, register, requestResetEmail, resetPassword } from "../../actions/auth"
import { getAuthToken } from "../../storage/authStorage"
import LanguagePicker from "../../components/languagePicker"

import bg1 from "../../assets/login/bg-cody-chan.jpg"
import bg2 from "../../assets/login/bg-narahari-kr.jpg"
import bg3 from "../../assets/login/bg-saikrishna-sy.jpg"

import logo from "../../assets/gettips_logo_purple.svg"

const NOTIFICATIONS = {
  registerSuccess: {
    type: "success",
    title: "auth.notifications.register.success.title",
    text: "auth.notifications.register.success.text",
  },
  registerFailed: {
    type: "danger",
    title: "auth.notifications.register.failed.title",
    text: "auth.notifications.register.failed.text",
  },
  loginFailed: {
    type: "danger",
    title: "auth.notifications.login.failed.title",
    text: "auth.notifications.login.failed.text",
  },
  requestSuccess: {
    type: "success",
    title: "auth.notifications.requestReset.success.title",
    text: "auth.notifications.requestReset.success.text",
  },
  changePasswordSuccess: {
    type: "success",
    title: "auth.notifications.resetPassword.success.title",
    text: "auth.notifications.resetPassword.success.text",
  },
  changePasswordFailed: {
    type: "danger",
    title: "auth.notifications.resetPassword.failed.title",
    text: "auth.notifications.resetPassword.failed.text",
  },
}

const BACKGROUND_DELAY = 8000

const BACKGROUND_IMAGES = [
  {
    src: bg1,
    credit: "Photo by Cody Chan",
    link: "https://unsplash.com/@cceee",
    alt: "Summer bench on the beach house next to ocean",
  },
  {
    src: bg2,
    credit: "Photo by Narahari K R",
    link: "https://unsplash.com/@naaaaraaaaa",
    alt: "Finland Nuuksio national park",
  },
  {
    src: bg3,
    credit: "Photo by SaiKrishna Saketh Yellapragada",
    link: "https://unsplash.com/@saiksaketh",
    alt: "Finland national park with boat and water",
  },
]

class AuthContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notification: null,
      imageIndex: 0,
      values: null,
    }
  }

  componentDidMount() {
    const token = getAuthToken()
    if (token) {
      this.props.history.push("/")
    }

    this.interval = setInterval(() => this.changeBackgroundImage(), BACKGROUND_DELAY)
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  changeBackgroundImage() {
    let currentIndex = 0
    const { imageIndex } = this.state

    if (imageIndex !== BACKGROUND_IMAGES.length - 1) {
      currentIndex = imageIndex + 1
    }

    this.setState({ imageIndex: currentIndex })
  }

  onSubmitLogin = values => {
    if (values.email && values.password) {
      // Authenticate user directly
      this.props
        .login(values.email, values.password)
        .then(() => {
          this.props.history.push("/")
        })
        .catch(() => {
          this.setState({ notification: NOTIFICATIONS.loginFailed })
        })
    }
  }

  onSubmitRegister = values => {
    if (values && values.email) {
      this.props
        .register(values)
        .then(() => {
          this.props.history.push({ pathname: "/login" })
          this.setState({ notification: NOTIFICATIONS.registerSuccess, values })
        })
        .catch(() => {
          this.props.history.push({ pathname: "/login" })
          this.setState({ notification: NOTIFICATIONS.registerFailed })
        })
    }
  }

  onSubmitRequest = email => {
    if (email) {
      this.props
        .requestResetEmail(email)
        .then(() => {
          this.props.history.push({ pathname: "/login" })
          this.setState({ notification: NOTIFICATIONS.requestSuccess })
        })
        .catch(() => {})
    }
  }

  onSubmitResetPassword = password => {
    let searchParams = new URLSearchParams(this.props.location.search)
    let token = searchParams.get("token")
    let uidb64 = searchParams.get("uidb64")

    if (password && token && uidb64) {
      this.props
        .resetPassword({ password, token, uidb64 })
        .then(() => {
          this.props.history.push({ pathname: "/login" })
          this.setState({ notification: NOTIFICATIONS.changePasswordSuccess })
        })
        .catch(() => {
          this.setState({ notification: NOTIFICATIONS.changePasswordFailed })
        })
    } else {
      this.setState({ notification: NOTIFICATIONS.changePasswordFailed })
    }
  }

  closeAlert = () => {
    this.setState({ notification: null })
  }

  render() {
    const { t, isLoading } = this.props
    const { imageIndex } = this.state

    return (
      <Container fluid className="auth h-100 align-items-center bg-white min-vh-100">
        <LanguagePicker absolute />
        <Row className="h-100">
          <Col xs={0} md={8} xl={9} className="d-none d-md-block image-container">
            {BACKGROUND_IMAGES.map((image, index) => (
              <div key={index} className={"background-image " + (imageIndex === index ? "visible" : "hidden")}>
                <img src={image.src} alt={image.alt} />
                <div className="credits">
                  <a href={image.link} target="_blank" rel="noopener noreferrer">
                    {image.credit}
                  </a>
                </div>
              </div>
            ))}
          </Col>
          <Col xs={12} md={4} xl={3} className="bg-light-md min-vh-100">
            <Row className="h-100 py-4 align-items-end text-center content">
              <div className="auth-form-container">
                <div className="auth-form-content mb-4">
                  <div className="w-100 h-100">
                    <img src={logo} alt="Get Tips logo purple" className="logo" />
                    {this.state.notification && (
                      <Container>
                        <Row className="mb-2">
                          <Col>
                            <Alert variant={this.state.notification.type} onClose={this.closeAlert} dismissible>
                              <Alert.Heading>{t(this.state.notification.title)}</Alert.Heading>
                              <p className="mb-0 mr-3">{t(this.state.notification.text)}</p>
                            </Alert>
                          </Col>
                        </Row>
                      </Container>
                    )}
                    <Switch>
                      <Route path="/login">
                        <Login onSubmit={this.onSubmitLogin} isLoading={isLoading} values={this.state.values} />
                      </Route>
                      <Route path="/register">
                        <Register onSubmit={this.onSubmitRegister} isLoading={isLoading} />
                      </Route>
                      <Route path="/recovery">
                        <Recovery onSubmit={this.onSubmitRequest} isLoading={isLoading} />
                      </Route>
                      <Route path="/reset-password">
                        <ResetPassword onSubmit={this.onSubmitResetPassword} isLoading={isLoading} />
                      </Route>
                    </Switch>
                  </div>
                </div>
              </div>
              <Col xs={12} className="pb-3 footer-links">
                <a href="https://www.gettips.fi/legal/app-terms-of-service" target="_blank" rel="noopener noreferrer">
                  {t("auth.footer.termsOfService")}
                </a>
                <a href="https://www.gettips.fi/legal/app-privacy-policy" target="_blank" rel="noopener noreferrer">
                  {t("auth.footer.privacyPolicy")}
                </a>
                <a href="https://www.gettips.fi/contact-us" target="_blank" rel="noopener noreferrer">
                  {t("auth.footer.customerSupport")}
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

AuthContainer.propTypes = {
  login: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

AuthContainer.defaultProps = {
  isLoading: false,
}

const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
  user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({
  login: (email, code) => dispatch(login(email, code)),
  register: values => dispatch(register(values)),
  requestResetEmail: email => dispatch(requestResetEmail(email)),
  resetPassword: data => dispatch(resetPassword(data)),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AuthContainer))
