import React from "react"
import { useTranslation } from "react-i18next"

function ErrorContainer() {
  const { t } = useTranslation()
  return (
    <div className="tw-w-full tw-h-screen-with-header tw-flex tw-items-center tw-justify-center tw-text-center">
      <div>
        <h4 className="tw-mb-4 tw-font-semibold">{t("error.404.title")}</h4>
        <p>{t("error.404.content")}</p>
      </div>
    </div>
  )
}

export default ErrorContainer
