import { combineReducers } from "redux"

import authReducer from "./auth"
import destinationsReducer from "./destinations"
import activitiesReducer from "./activities"
import campaignsReducer from "./campaigns"
import feedbackReducer from "./feedback"

const reducers = combineReducers({
  auth: authReducer,
  destinations: destinationsReducer,
  activities: activitiesReducer,
  campaigns: campaignsReducer,
  feedback: feedbackReducer,
})

export default reducers
