import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { HiCheck } from "react-icons/hi"
import { twClassNames } from "../helpers/classNames"

const Progressbar = ({ phases, current, translationKeys }) => {
  const { t } = useTranslation()

  return (
    <div className="tw-flex tw-justify-center tw-mx-8">
      {Array.from({ length: phases }, (_, i) => i + 1).map(phase => (
        <Fragment key={phase}>
          <div className="tw-relative tw-text-center">
            <div
              className={twClassNames(
                "tw-w-[40px] tw-h-[40px] tw-border-2 tw-border-dark-25 tw-rounded-full tw-m-auto tw-leading-9 tw-font-semibold",
                current >= phase && "tw-bg-primary tw-text-white"
              )}>
              {current > phase ? <HiCheck /> : <span>{phase}</span>}
            </div>
            <p className="tw-text-xs tw-mt-2 tw-absolute -tw-left-8 -tw-right-8 md:-tw-left-12 md:-tw-right-12">
              {t(translationKeys + ".phase_" + phase)}
            </p>
          </div>
          {phase < phases && (
            <span
              className={twClassNames(
                "tw-w-full tw-max-w-[160px] tw-m-5 tw-border-t tw-border-dark-25 max-sm:tw-mx-2",
                current > phase && "tw-border-dark"
              )}
            />
          )}
        </Fragment>
      ))}
    </div>
  )
}

Progressbar.propTypes = {}
Progressbar.defaultProps = {}

export default Progressbar
