import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import en from "./translations/en.json"
import fi from "./translations/fi.json"

const options = {
  order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator"],
  lookupQuerystring: "lang",
  lookupCookie: "userLocale",
  lookupLocalStorage: "userLocale",
  lookupSessionStorage: "userLocale",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"],
  cookieMinutes: 30,
  cookieDomain: "gettips",
  cookieOptions: { path: "/", sameSite: "strict" },
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    detection: options,
    fallbackLng: "en",
    resources: {
      en: { translation: en },
      fi: { translation: fi },
    },
  })

export default i18n
