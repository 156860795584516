import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Container, Row, Col, Card, Button, Nav, Toast } from "react-bootstrap"
import { HiOutlineMenu, HiOutlineRefresh, HiOutlineEyeOff, HiOutlineEye, HiLockClosed } from "react-icons/hi"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { withTranslation, Trans } from "react-i18next"

import IonIcon from "../../../components/ionIcon"
import ConfirmationModal from "../../../components/confirmation"
import VideoModal from "../../../components/video-modal"
import { deleteGuide, createGuide } from "../../../actions/guides"
import { updateDestination } from "../../../actions/destinations"
import { mapOrder } from "../../../helpers/mapOrder"
import { getLocaleValue } from "../../../helpers/getLocaleValue"
import { reorderArray } from "../../../helpers/reorderArray"
import { GuideText, GuideImage, GuideVideoThumbnail, GuideModal } from "./components"
import { twClassNames } from "../../../helpers/classNames"

const SortableItem = SortableElement(({ data, language, onClick, selected, destinationId }) => (
  <Card
    body
    className={["guide", selected && "selected", isLocked(data, destinationId) && "locked"]}
    onClick={() => onClick(data)}>
    <IonIcon name={data?.icon} />
    <span className="ml-3">{data && getLocaleValue(data, "topic", language)}</span>
    <HiOutlineMenu className="float-right mt-1" />
    {data?.is_draft && <HiOutlineEyeOff className="float-right mt-1 mr-1" />}
    {data?.is_locked && <HiLockClosed className="float-right mt-1 mr-1" />}
  </Card>
))

const SortableList = SortableContainer(({ items, language, onClick, selectedGuideId, destinationId }) => {
  return (
    <div>
      {items.map((data, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          sortIndex={index}
          data={data}
          language={language}
          onClick={onClick}
          selected={data.id === selectedGuideId}
          destinationId={destinationId}
        />
      ))}
    </div>
  )
})

const isLocked = (guide, destinationId) => {
  return !guide || (guide.is_locked && destinationId !== guide.destination)
}

class GuidesContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toast: { display: false, type: null, message: null },
      language: props.i18n.language,
      selectedGuideId: null,
      showGuideModal: false,
      showConfirmationModal: false,
      videoModal: {
        display: false,
        video: null,
      },
    }
  }

  setToast = (display, type, message, values) => {
    this.setState({ toast: { display, type, content: { message, values } } })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { guides, id, name_fi, name_en, municipal, description_fi, description_en } = this.props.destination
    const updatedOrder = reorderArray(
      oldIndex,
      newIndex,
      guides.map(x => x.id)
    )
    const data = {
      id, // No changes, but required field
      name: name_en || name_fi, // No changes, but required field
      name_fi, // No changes, but required field
      name_en, // No changes, but required field
      municipal, // No changes, but required field
      description: description_en || description_fi, // No changes, but required field
      description_fi, // No changes, but required field
      description_en, // No changes, but required field
      order: updatedOrder,
    }

    if (id && oldIndex !== newIndex) {
      this.props.updateDestination(id, data).catch(e => this.setToast(true, "error", "sort.failed"))
    }
  }

  onSelectGuide = data => {
    this.setState({ selectedGuideId: data?.id })
  }

  onEdit = () => {
    const { selectedGuideId } = this.state
    if (selectedGuideId !== null) {
      this.props.history.push("/handbook/guides/" + selectedGuideId)
    }
  }

  onDelete = () => {
    const { selectedGuideId } = this.state
    const guide = this.props.destination?.guides.find(x => x.id === selectedGuideId)

    if (selectedGuideId && guide) {
      this.props
        .deleteGuide(selectedGuideId)
        .then(() => this.setToast(true, "success", "deleteGuide.success", { topic: guide.topic }))
        .catch(e => this.setToast(true, "error", "deleteGuide.failed"))
    }

    this.setState({ showConfirmationModal: false })
  }

  onCreate = values => {
    const { destination, createGuide, history } = this.props
    this.setState({ showGuideModal: false })

    if (destination) {
      const data = {
        topic: values.topic_en !== "" ? values.topic_en : values.topic_fi,
        topic_en: values.topic_en,
        topic_fi: values.topic_fi,
        icon: values.icon,
        is_draft: values.is_draft,
        content: [],
        destination: destination.id,
        destinations: [destination.id],
      }

      createGuide(data)
        .then(response => history.push("/handbook/guides/" + response.payload.id))
        .catch(e => this.setToast(true, "error", "createGuide.failed"))
    } else {
      this.setToast(true, "error", "createGuide.failed")
    }
  }

  openGuideModal = () => {
    this.setState({ showGuideModal: true })
  }

  closeGuideModal = () => {
    this.setState({ showGuideModal: false })
  }

  openConfirmationModal = () => {
    this.setState({ showConfirmationModal: true })
  }

  closeConfirmationModal = () => {
    this.setState({ showConfirmationModal: false })
  }

  openVideoModal = data => {
    this.setState({ videoModal: { display: true, video: data.video } })
  }

  closeVideoModal = () => {
    this.setState({ videoModal: { display: false, video: null } })
  }

  render() {
    const { toast, language, selectedGuideId, showGuideModal, showConfirmationModal, videoModal } = this.state
    const { t, destination, isLoading } = this.props

    if (!destination) {
      return null
    }

    const guide = destination.guides?.find(x => x.id === selectedGuideId) // Selected guide
    const guides = destination.guides ? mapOrder(destination.guides, destination.order, "id") : [] // All guides sorted

    return (
      <Container fluid>
        {toast && toast.display && (
          <Toast
            className={"fixed-toast " + toast.type}
            onClose={() => this.setToast(false)}
            show={toast.display}
            delay={5000}
            autohide>
            <Toast.Header>
              <strong className="mr-auto">{t("common.notifications.type." + toast.type)}</strong>
            </Toast.Header>
            <Toast.Body>{t("guides.notifications." + toast.content.message, toast.content.values)}</Toast.Body>
          </Toast>
        )}
        <GuideModal show={showGuideModal} onSubmit={this.onCreate} onCancel={this.closeGuideModal} />
        <ConfirmationModal
          show={showConfirmationModal}
          title="delete"
          content="delete.guide"
          onOK={this.onDelete}
          onCancel={this.closeConfirmationModal}
          danger
        />
        <Row>
          <Col>
            <h3 className="mb-4 font-weight-bold">{t("guides.title")}</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={8} className="guides-container">
            <Card className="mb-4">
              <Card.Header>
                <Row>
                  <Col xs={8}>
                    <h5 className="mb-0 mt-1">{destination && getLocaleValue(destination, "name", language)}</h5>
                  </Col>
                  <Col xs={4}>
                    <Nav
                      justify
                      defaultActiveKey={language}
                      onSelect={language => this.setState({ language })}
                      className="language-navigation">
                      <Nav.Item>
                        <Nav.Link eventKey="fi">{t("common.languages.fi")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="en">{t("common.languages.en")}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Header className="button-group">
                <Button
                  disabled={isLoading}
                  onClick={this.openGuideModal}
                  className="btn-default btn-block-xs-only mb-1 mb-sm-0"
                  variant="primary">
                  {t("guides.button.toolbar.new")}
                </Button>
                <Button
                  disabled={!guide || guide.is_locked}
                  onClick={this.openConfirmationModal}
                  className="float-sm-right btn-default btn-block-xs-only mb-1 mb-sm-0"
                  variant="danger">
                  {t("guides.button.toolbar.delete")}
                </Button>
                <Button
                  disabled={isLocked(guide, destination.id)}
                  onClick={this.onEdit}
                  className="float-sm-right btn-default btn-block-xs-only mr-sm-1 mb-1 mb-sm-0"
                  variant="primary">
                  {t("guides.button.toolbar.edit")}
                </Button>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    {isLoading && (
                      <div className="loading-spinner">
                        <HiOutlineRefresh className="slow-spin icon loading-spinner-icon" />
                      </div>
                    )}
                    <SortableList
                      destinationId={destination.id}
                      items={guides}
                      selectedGuideId={selectedGuideId}
                      language={language}
                      onSortEnd={this.onSortEnd}
                      distance={5}
                      onClick={this.onSelectGuide}
                      useWindowAsScrollContainer
                    />
                    {(!destination || !destination.guides.length) && (
                      <p className="text-center mt-3">{t("guides.noContent")}</p>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={4} className="preview-container">
            <Card className="mb-4">
              <Card.Header className="tw-flex tw-justify-between">
                <h5 className="mb-0">{t("guides.preview")}</h5>
                {guide && (
                  <div className="tw-group tw-relative">
                    <span
                      className={twClassNames(
                        "tw-text-sm tw-mb-0 tw-place-self-center",
                        guide.is_draft ? "tw-text-red-400" : "tw-text-dark-50"
                      )}>
                      {guide.is_draft ? (
                        <>
                          <HiOutlineEyeOff className="tw-mr-1" />
                          {t("guides.form.draft")}
                        </>
                      ) : (
                        <>
                          <HiOutlineEye className="tw-mr-1" />
                          {t("guides.form.publish")}
                        </>
                      )}
                    </span>
                    <span
                      className="tw-absolute tw-w-[300px] tw-top-8 -tw-right-3 tw-rounded-md tw-bg-white tw-shadow-md
                        tw-border tw-border-dark-15 tw-p-3 tw-text-sm tw-text-left tw-z-50 tw-transition-opacity 
                        tw-ease-in tw-duration-200 tw-opacity-0 tw-hidden group-hover:tw-opacity-100 group-hover:tw-block">
                      {guide.is_draft ? (
                        <Trans i18nKey="guides.tooltips.is_draft.draft" />
                      ) : (
                        <Trans i18nKey="guides.tooltips.is_draft.publish" />
                      )}
                    </span>
                  </div>
                )}
              </Card.Header>
              <div className="mb-3">
                {guide &&
                  guide.content &&
                  guide.content.map((data, index) => {
                    switch (Object.keys(data)[0]) {
                      case "text":
                        return <GuideText data={data.text} key={index} language={language} preview />
                      case "image":
                        return <GuideImage data={data.image} key={index} preview />
                      case "video":
                        return (
                          <GuideVideoThumbnail
                            data={data.video}
                            key={index}
                            preview
                            list
                            onClick={this.openVideoModal}
                          />
                        )
                      default:
                        return null
                    }
                  })}
                {!guide && (
                  <p className="text-muted font-italic m-0 px-4 pt-3">{t("guides.selectedGuide.unselected")}</p>
                )}
                {guide && !guide.content.length && (
                  <p className="text-muted font-italic m-0 px-4 pt-3">{t("guides.selectedGuide.noContent")}</p>
                )}
              </div>
            </Card>
          </Col>
        </Row>
        <VideoModal video={videoModal.video} display={videoModal.display} close={this.closeVideoModal} />
      </Container>
    )
  }
}

GuidesContainer.propTypes = {
  isLoading: PropTypes.bool,
}

GuidesContainer.defaultProps = {
  isLoading: false,
}

const mapStateToProps = state => ({
  isLoading: state.destinations.isLoading,
  destination: state.destinations.destinations.find(x => x.id === state.destinations.selectedDestinationId),
})

const mapDispatchToProps = dispatch => ({
  updateDestination: (id, data) => dispatch(updateDestination(id, data)),
  deleteGuide: id => dispatch(deleteGuide(id)),
  createGuide: data => dispatch(createGuide(data)),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(GuidesContainer))
