import {
  LOG_OUT,
  CAMPAIGNS_LOADING,
  CAMPAIGNS_SUCCESS,
  CAMPAIGNS_FAILED,
  CREATE_CAMPAIGN_LOADING,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILED,
  DELETE_CAMPAIGN_LOADING,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAILED,
} from "../constants/actionTypes"

const initialState = {
  isLoading: false,
  campaigns: [],
  activityId: null,
}

function deleteCampaign(campaigns, id) {
  return [...campaigns.filter(data => data.id !== id)]
}

export default function (state = initialState, action) {
  switch (action.type) {
    // =============================================================================
    case CAMPAIGNS_LOADING:
      return { ...state, isLoading: true }
    case CAMPAIGNS_SUCCESS:
      return { ...state, isLoading: false, campaigns: action.payload, activityId: action.meta.activityId }
    case CAMPAIGNS_FAILED:
      return { ...state, isLoading: false }
    // =============================================================================
    case CREATE_CAMPAIGN_LOADING:
      return { ...state, isLoading: true }
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campaigns: [...state.campaigns, action.payload],
      }
    case CREATE_CAMPAIGN_FAILED:
      return { ...state, isLoading: false }
    // =============================================================================
    case DELETE_CAMPAIGN_LOADING:
      return { ...state, isLoading: true }
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campaigns: deleteCampaign(state.campaigns, action.payload),
      }
    case DELETE_CAMPAIGN_FAILED:
      return { ...state, isLoading: false }
    // =============================================================================
    case LOG_OUT:
      return initialState
    // =============================================================================
    default:
      break
  }
  return state
}
