import React from "react"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"

import AuthContainer from "./containers/Auth"
import ProjectsContainer from "./containers/Projects"
import HandbookContainer from "./containers/Handbook"
import CreateDestinationContainer from "./containers/Handbook/Create"
import ActivitiesContainer from "./containers/Activities"
import CreateActivityContainer from "./containers/Activities/Create"
import ErrorContainer from "./containers/Error/404"

import { getAuthToken } from "./storage/authStorage"

import "./assets/styles.css"
import "./App.scss"

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: Component, ...rest }) {
  const token = getAuthToken()
  return <Route {...rest} render={props => (token ? <Component {...props} /> : <Redirect to="/login" />)} />
}

export default function App() {
  return (
    <Router>
      <Switch>
        {/* Auth */}
        <Route exact path="/login" component={AuthContainer} />
        <Route exact path="/register" component={AuthContainer} />
        <Route exact path="/recovery" component={AuthContainer} />
        <Route exact path="/reset-password" component={AuthContainer} />
        {/* Projects */}
        <PrivateRoute exact path="/:active_tab?" component={ProjectsContainer} />
        {/* Handbook */}
        <PrivateRoute exact path="/handbook/create" component={CreateDestinationContainer} />
        <PrivateRoute path="/handbook" component={HandbookContainer} />
        {/* Activities */}
        <PrivateRoute exact path="/activities/create" component={CreateActivityContainer} />
        <PrivateRoute path="/activities" component={ActivitiesContainer} />
        {/* Error */}
        <PrivateRoute path="*" component={ErrorContainer} />
      </Switch>
    </Router>
  )
}
