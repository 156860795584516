import { getRequest, postRequest, deleteRequest } from "../helpers/fetchHandler"
import {
  CAMPAIGNS_LOADING,
  CAMPAIGNS_SUCCESS,
  CAMPAIGNS_FAILED,
  CREATE_CAMPAIGN_LOADING,
  CREATE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_FAILED,
  DELETE_CAMPAIGN_LOADING,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAILED,
} from "../constants/actionTypes"

// Require authorization
export function fetchCampaigns(activityId) {
  return dispatch => {
    dispatch({ type: CAMPAIGNS_LOADING })

    const url = `/api/campaigns/${activityId}/`
    return getRequest({ url, auth: true })
      .then(data => dispatch({ type: CAMPAIGNS_SUCCESS, payload: data, meta: { activityId } }))
      .catch(error => {
        dispatch({ type: CAMPAIGNS_FAILED, error })
        return Promise.reject(error)
      })
  }
}

// Require authorization
export function createCampaign(activityId, data) {
  return dispatch => {
    dispatch({ type: CREATE_CAMPAIGN_LOADING })

    const url = `/api/campaigns/${activityId}/`
    return postRequest({ url, data, auth: true })
      .then(response => dispatch({ type: CREATE_CAMPAIGN_SUCCESS, payload: response }))
      .catch(error => {
        dispatch({ type: CREATE_CAMPAIGN_FAILED, error })
        return Promise.reject(error)
      })
  }
}

// Require authorization
export function deleteCampaign(activityId, id) {
  return dispatch => {
    dispatch({ type: DELETE_CAMPAIGN_LOADING })

    const url = `/api/campaigns/${activityId}/${id}/`
    return deleteRequest({ url, auth: true })
      .then(() => dispatch({ type: DELETE_CAMPAIGN_SUCCESS, payload: id }))
      .catch(error => {
        dispatch({ type: DELETE_CAMPAIGN_FAILED, error })
        return Promise.reject(error)
      })
  }
}
