/* eslint-disable */
import React from "react"
import { Player, BigPlayButton, ControlBar } from "video-react"
import { classNames } from "../../../../helpers/classNames"

export const GuideVideo = ({ data, list, preview, full }) => {
  const classes = classNames({
    "video-container": true,
    list: list,
    preview: preview,
    fullsize: full,
  })

  return (
    <div className={classes}>
      <Player src={data.video} className="video-player" height={preview ? 150 : 600} fluid={full ? false : true}>
        <BigPlayButton position="center" />
        <ControlBar disableCompletely={!full} />
      </Player>
    </div>
  )
}
