import React from "react"
import { Player, BigPlayButton, ControlBar } from "video-react"
import { Button, Modal } from "react-bootstrap"
import { HiOutlineX } from "react-icons/hi"

const VideoModal = ({ video, display, close }) => {
  const player = React.useRef(null)

  if (!video) {
    return null
  }

  return (
    <Modal centered show={display} onHide={close}>
      <Button variant="link" className="video-modal-close-button" onClick={() => close()}>
        <HiOutlineX />
      </Button>
      <Modal.Body className="p-0">
        <Player ref={player} src={video} className="video-player" height={600} fluid={false}>
          <BigPlayButton position="center" />
          <ControlBar autoHide={false} />
        </Player>
      </Modal.Body>
    </Modal>
  )
}

export default VideoModal
