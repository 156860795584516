import React, { Component } from "react"
import { Col, Button, Modal, Form } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import { connect } from "formik"

import guideIcons from "../constants/guideIcons"
import IonIcon from "./ionIcon"

class IconPicker extends Component {
  constructor(props) {
    super(props)
    this.state = { display: false }
  }

  showIconPicker = () => {
    this.setState({ display: true })
  }

  hideIconPicker = () => {
    this.setState({ display: false })
  }

  selectIcon = item => {
    const {
      name,
      formik: { setFieldValue },
    } = this.props
    setFieldValue(name, item.icon)
    this.hideIconPicker()
  }

  render() {
    const { t, values } = this.props
    const { display } = this.state
    const isSelected = item => values.icon === item.icon

    return (
      <Col xs={12} className="">
        <Form.Text className="text-muted">{t("guides.modal.selectIcon")}</Form.Text>

        <Modal show={display} onHide={this.hideIconPicker} backdrop={false} animation={false}>
          <Modal.Header>
            <Modal.Title>{t("guides.modal.selectIcon")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="overflow-auto icon-picker-modal">
            {guideIcons.map(value => (
              <Button
                key={value.icon}
                variant="outline-primary"
                onClick={() => this.selectIcon(value)}
                className={"icon-picker-button" + (isSelected(value) ? " selected" : "")}>
                <IonIcon name={value.icon} className="icon-picker-icon" />
              </Button>
            ))}
          </Modal.Body>
        </Modal>

        <Button variant="secondary-link" className="icon-picker" onClick={this.showIconPicker}>
          <IonIcon name={values.icon} style={{ fontSize: 38, borderWidth: 0 }} />
        </Button>
      </Col>
    )
  }
}

export default withTranslation()(connect(IconPicker))
