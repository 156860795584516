import {
  LOG_OUT,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  PASSWORD_RESET_LOADING,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILED,
  REQUEST_RESET_EMAIL_LOADING,
  REQUEST_RESET_EMAIL_SUCCESS,
  REQUEST_RESET_EMAIL_FAILED,
} from "../constants/actionTypes"

const initialState = {
  isLoading: false,
  user: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    // =============================================================================
    case REGISTER_LOADING:
      return { ...state, isLoading: true }
    case REGISTER_SUCCESS:
      return { ...state, isLoading: false, user: { ...state.user, email: action.meta.email } }
    case REGISTER_FAILED:
      return { ...state, isLoading: false }
    // =============================================================================
    case LOGIN_LOADING:
      return { ...state, isLoading: true }
    case LOGIN_SUCCESS:
      return { ...state, isLoading: false, user: { ...state.user, email: action.meta.email } }
    case LOGIN_FAILED:
      return { ...state, isLoading: false }
    // =============================================================================
    case LOG_OUT:
      return initialState
    // =============================================================================
    case PASSWORD_RESET_LOADING:
    case REQUEST_RESET_EMAIL_LOADING:
      return { ...state, isLoading: true }
    case PASSWORD_RESET_SUCCESS:
    case REQUEST_RESET_EMAIL_SUCCESS:
      return { ...state, isLoading: false }
    case PASSWORD_RESET_FAILED:
    case REQUEST_RESET_EMAIL_FAILED:
      return { ...state, isLoading: false }
    // =============================================================================
    default:
      break
  }
  return state
}
