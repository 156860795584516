import React from "react"
import { Container, Row, Col, Card, Accordion } from "react-bootstrap"
import { withTranslation } from "react-i18next"

function PartnersContainer(props) {
  return (
    <Container fluid>
      <Row>
        <Col>
          <h3 className="mb-4 font-weight-bold">{props.t("partners.title")}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion>
            <Card className="flex-row flex-wrap mb-4">
              <Row className="p-4">
                <Col>
                  <Card.Body>
                    <p>
                      Get Tips Oy on tehnyt yhteistyön Kiilto Oyn sekä Metsä Groupin kanssa ja tarjoaa Visit Levi
                      mökkikansion mökkiomistajille halvemmat hinnat tuotteistaan. Tarkoitus on tuoda suomalaisia
                      kotimaisia tuotteita saataville mökkeihin edulliseen hintaan.
                    </p>
                    <p>
                      Tuotteiden tilaaminen ja toimitus on helppoa, Rekisteröitte yrityksenne Velimarkin kotisivuilla ja
                      mainitsette yrityksen kohdalla myös Get Tips. Hinnoittelun ja rekisteröitymisen tultua voimaan
                      löydätte tarjoushinnat alla olevien ohjeiden mukaisesti. Toimitus on sovittu kätevästi K-Market
                      Levinporon kanssa. Eli VeliMark toimittaa tilauksen K-Market Levinporoon joka informoi teitä
                      saapuneesta lähetyksestä. Tilauksen kesto varastossa oleville tuotteille on n. 2 työpäivää.
                    </p>
                    <h5 className="mt-4">Alla tarkemmat ohjeet</h5>
                  </Card.Body>
                  <Accordion.Toggle as={Card.Header} eventKey="0" className="pb-0">
                    <p className="font-weight-bold cursor-pointer">
                      Kuinka rekisteröin yritykseni ja pääsen mukaan nauttimaan Get Tipsin halvoista sopimushinnoista?
                    </p>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0" className="pt-4 border-bottom">
                    <ul>
                      <li>
                        Rekisteröitymällä asiakkaaksi VeliMarkin kotisivuilla
                        <a href="https://www.velimark.com/fiFI/welcome.htm" target="_blank" rel="noopener noreferrer">
                          {" "}
                          www.velimark.fi
                        </a>
                        .
                      </li>
                      <br />
                      <li>
                        Tieto yrityksen ja Get Tipsin yhdistämisestä järjestelmässämme tulee löytyä
                        rekisteröitymislomakkeen Yritys –kentässä. <br />
                        Esimerkiksi merkkaamalla se kenttään seuraavasti: <b>YRITYKSESI NIMI / Get Tips</b>.<br />
                        <br />
                        <img src={require("../../../assets/velimark/Picture1.jpg")} alt="velimark rekisteröidy" />
                      </li>
                    </ul>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Card.Header} eventKey="1" className="pb-0">
                    <p className="font-weight-bold cursor-pointer">Mistä löydän erikoihinnoitellut tuotteet?</p>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1" className="pt-4 border-bottom">
                    <ul>
                      <li>
                        Erikoishinnoitellut tuotteet löytyvät:
                        <br />
                        <br />
                        <img src={require("../../../assets/velimark/Picture2.jpg")} alt="velimark get tips hinnat" />
                      </li>
                    </ul>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Card.Header} eventKey="2" className="pb-0">
                    <p className="font-weight-bold cursor-pointer">Kauanko tilauksen tekeminen kestää?</p>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2" className="pt-4 border-bottom">
                    <ul>
                      <li>
                        Tilaukset toimitetaan tilauksen vastaanottamisesta kahdessa arkipäivässä. Mikäli tilaus halutaan
                        tehdä etukäteen, siten että toimitus halutaan saapuvaksi myöhempänä ajankohtana, voi halutun
                        toimituspäivämäärän valita kassanäkymästä ennen tilauksen lähettämistä.
                      </li>
                    </ul>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Card.Header} eventKey="3" className="pb-0">
                    <p className="font-weight-bold cursor-pointer">
                      Millaisen vastaanottajatiedon tuo tarvii? Toimitusosoite tosiaan on aina K-market Levinporo, mutta
                      mihin lisään omat yhteystietoni jotta K-Market Levinporossa tiedetään ilmoittaa saapumisesta?
                    </p>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3" className="pt-4 border-bottom">
                    <ul>
                      <li>
                        Kun uusi asiakas rekisteröityy verkkokauppa-asiakkaaksi, jokainen tähdellä merkitty kohta
                        rekisteröitymislomakkeessa tulee täyttää yrityksen laskutustietojen mukaisesti.
                        <br />
                        <br />
                        Kun saamme tiedon yhdistää uusi verkkokauppa-asiakas Get Tipsiin, niin silloin toimitus tapahtuu
                        aina automaattisesti K-Market LevinPoroon. Toimituksen mukana tulevaan lähetyslistaan tulee näin
                        näkyviin toimituskohteena tuo K-Market LevinPoro ja samassa lähetyslistassa on myös tilaavan
                        yrityksen nimi ja rekisteröitymislomakkeeseen laitettu puhelinnumero, johon LevinPorolaiset
                        voivat olla yhteydessä tilauksen saavuttua. Eli siis kun rekisteröidyt itse yrityksesi tiedoilla
                        ja puhelinnumerollasi, tulee tuo puhelinnumero näkyviin myös tilauksen lähetyslistaan. Mikäli
                        kontaktoitava puhelinnumero on eri kuin mikä on ilmoitettu rekisteröitymislomakkeessa, voi siitä
                        ilmoittaa verkkokauppamme kassanäkymän Huomautuksesi –kohdassa, jossa on kenttä
                        kuljetusohjeille, puhelinnumerolle tai muille lisätiedoille.
                      </li>
                      <br />
                      <li>
                        Huomioittehan vielä tilauksen tekemisessä seuraavan: Teidän tulee valita ennen tilauksen
                        lähettämistä oikea toimituskohde (K-Market LevinPoro) toimituskohdevalikosta. Laskutuksellisista
                        syistä yrityksenne laskutusosoite tulee automaattisesti tuohon listaukseen näkyville, siksi
                        onkin ehdottoman tärkeää, että toimitus valitaan K-Market LevinPoroon, muutoin tilauksen
                        toimitus saattaa ohjautua automaattisesti osoitteeseen yrityksenne kotiosoitteeseen. Alla
                        kuvakaappaus toimituskohdevalikosta:
                        <br />
                        <img src={require("../../../assets/velimark/Picture3.jpg")} alt="velimark toimitusosoite" />
                      </li>
                    </ul>
                  </Accordion.Collapse>
                  <Card.Body>
                    <p>Eikun hyviä tilauksia tekemään!</p>
                    <div className="w-100 text-right">
                      <img
                        src={require("../../../assets/velimark/katrin_logo.svg")}
                        alt="Katrin logo"
                        width="200px"
                        className="px-4"
                      />
                      <img src={require("../../../assets/velimark/kiilto_logo.svg")} alt="Kiilto logo" width="80px" />
                    </div>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  )
}

export default withTranslation()(PartnersContainer)
