import React from "react"
import { Nav } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { twClassNames } from "../helpers/classNames"

const Emulator = ({ children, lng, setPreviewLanguage, flex = false }) => {
  const { t } = useTranslation()

  return (
    <div className="tw-bg-light tw-py-6 tw-h-full tw-rounded-2xl tw-flex tw-items-center tw-flex-col">
      <div className="tw-mb-6">
        <Nav justify defaultActiveKey={lng} onSelect={setPreviewLanguage} className="language-navigation">
          <Nav.Item>
            <Nav.Link eventKey="fi">{t("common.languages.fi")}</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="en">{t("common.languages.en")}</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      {/* iPhone 11 Pro */}
      <div className="tw-h-[667px] tw-w-[320px] tw-bg-[#CDCED0] tw-rounded-[37px] tw-sticky tw-top-6">
        <div
          className="tw-relative tw-h-[657px] tw-w-[310px] tw-bg-white tw-rounded-[35px] 
          tw-border-10 tw-border-[#E8E9EC] tw-m-[5px]">
          {/* Top notch */}
          <div
            className="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-m-auto tw-h-[28px] tw-bg-[#E8E9EC] tw-w-[150px] 
            tw-rounded-b-[15px] tw-z-10 tw-flex tw-justify-center tw-space-x-2">
            <div className="tw-h-[8px] tw-w-[40px] tw-rounded tw-bg-[#CDCED0] tw-mt-[6px]" />
            <div className="tw-h-[12px] tw-w-[12px] tw-rounded tw-bg-[#CDCED0] tw-mt-[4px]" />
          </div>
          {/* Content */}
          <div className={twClassNames(flex && "tw-flex", "tw-h-full tw-w-full")}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export default Emulator
