import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { twClassNames } from "../../../helpers/classNames"
import { getLocaleValue } from "../../../helpers/getLocaleValue"
import { datetimeFormat } from "../../../helpers/time"
import { getStatus } from "../../../helpers/campaignStatus"

import { fetchCampaigns } from "../../../actions/campaigns"

const ActivitiesCampaignContainer = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const activityId = useSelector(state => state.activities.selectedActivityId)
  const campaigns = useSelector(state => state.campaigns.campaigns)
  const [query, setQuery] = useState("")

  useEffect(() => {
    dispatch(fetchCampaigns(activityId))
  }, [activityId, dispatch])

  const hasActiveCampaigns = useMemo(() => {
    return campaigns.map(campaign => getStatus(campaign).title !== "expired").filter(x => x !== false).length > 0
  }, [campaigns])

  const filteredCampaigns = useMemo(() => {
    return query === ""
      ? campaigns
      : campaigns.filter(item => {
          return item.title.toLowerCase().includes(query.toLowerCase())
        })
  }, [query, campaigns])

  return (
    <>
      <h1 className="tw-font-display tw-font-semibold tw-text-4xl tw-mb-6">{t("activities.campaigns.title")}</h1>
      <div className="tw-shadow tw-bg-white tw-rounded">
        <div className="tw-w-full">
          {/* Header group */}
          <div className="tw-flex tw-p-6 tw-pb-2 tw-flex-col md:tw-flex-row">
            {/* Search bar */}
            <div className="tw-grow md:tw-mr-6 max-md:tw-mb-4">
              <div
                className="tw-relative tw-w-full tw-rounded-lg tw-bg-white tw-text-left tw-cursor-default 
                tw-h-10 tw-border tw-border-dark-15 focus:tw-outline-none">
                <input
                  id="search"
                  type="text"
                  onChange={event => setQuery(event.target.value)}
                  className="tw-w-full tw-border-none tw-py-2 tw-pl-3 tw-pr-10 tw-leading-5 tw-rounded-lg
                    sm:tw-text-sm focus:tw-outline-primary-50"
                  placeholder={t("common.search.placeholder")}
                />
              </div>
            </div>
            {/* Button group */}
            <div className="tw-flex tw-justify-end tw-content-end tw-space-x-4">
              <button
                className="tw-btn tw-btn-primary tw-min-w-[180px]"
                disabled={hasActiveCampaigns}
                onClick={() => history.push("/activities/campaigns/create")}>
                <span>{t("activities.campaigns.button.create.new")}</span>
              </button>
            </div>
          </div>
        </div>
        <div className="tw-p-6 tw-space-y-4">
          {/* Empty */}
          {filteredCampaigns.length === 0 && (
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center md:tw-my-12">
              <p className="tw-text-center tw-text-dark-50 tw-text-lg tw-mb-0">
                {query ? t("activities.campaigns.empty.withQuery") : t("activities.campaigns.empty.withoutQuery")}
              </p>
              {query === "" && (
                <button
                  className="tw-btn tw-btn-outline tw-min-w-[180px] tw-mt-6 max-md:tw-hidden"
                  disabled={hasActiveCampaigns}
                  onClick={() => history.push("/activities/campaigns/create")}>
                  <span>{t("activities.campaigns.button.create.campaign")}</span>
                </button>
              )}
            </div>
          )}
          {filteredCampaigns.map(campaign => {
            const status = getStatus(campaign)
            return (
              <div key={campaign.id} className="tw-bg-white tw-shadow tw-rounded-2xl tw-border-2 tw-border-gray">
                {/* Content */}
                <div className="tw-flex tw-flex-row tw-w-full tw-px-6 tw-py-4">
                  {/* Left */}
                  <div className="tw-flex tw-grow tw-flex-col">
                    <p className="tw-font-semibold tw-text-lg tw-line-clamp-2 tw-mb-2">
                      {getLocaleValue(campaign, "title", "fi")}
                    </p>
                    <span className="tw-text-sm tw-line-clamp-3">{getLocaleValue(campaign, "description", "fi")}</span>
                  </div>
                  {/* Right */}
                  <div className="tw-justify-right tw-flex tw-flex-col tw-items-center">
                    <span
                      className={twClassNames(
                        "tw-text-sm tw-min-w-[100px] tw-text-center tw-py-1 tw-rounded",
                        status.color
                      )}>
                      {t("activities.campaigns.status." + status.title)}
                    </span>
                    <span className="tw-text-xs tw-mt-2 tw-text-left">{t("activities.campaigns.publishDate")}</span>
                    <span className="tw-text-xs">{datetimeFormat(campaign.published_at)}</span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

ActivitiesCampaignContainer.propTypes = {
  isLoading: PropTypes.bool,
  campaigns: PropTypes.array,
  activityId: PropTypes.number,
}

ActivitiesCampaignContainer.defaultProps = {
  isLoading: false,
  campaigns: [],
  activityId: null,
}

export default ActivitiesCampaignContainer
