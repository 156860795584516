export const setAuthToken = async token => {
  try {
    await null
    // Store the credentials
    return localStorage.setItem("token", token)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Failed to store authentication token:", error)
    return null
  }
}

export const getAuthToken = () => {
  // Retrieve the credentials
  return localStorage.getItem("token")
}

export const getAuthToken2 = async () => {
  try {
    await null
    // Retrieve the credentials
    const credentials = localStorage.getItem("token")
    if (credentials) {
      return credentials
    }
    throw Error("No credentials stored.")
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Failed to retrieve authentication token:", error)
    return null
  }
}

export const clearAuthToken = async () => {
  try {
    await null
    // Remove credentials from the secure storage
    return localStorage.removeItem("token")
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Failed to delete authentication token:", error)
    return null
  }
}
