import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Container, Row, Col, Card, Nav } from "react-bootstrap"
import { HiOutlineRefresh } from "react-icons/hi"
import { withTranslation } from "react-i18next"

import IonIcon from "../../../components/ionIcon"
import VideoModal from "../../../components/video-modal"
import { fetchExampleDestination } from "../../../actions/destinations"
import { mapOrder } from "../../../helpers/mapOrder"
import { getLocaleValue } from "../../../helpers/getLocaleValue"
import { GuideText, GuideImage, GuideVideoThumbnail } from "../Guides/components"

class ExamplesContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      language: props.i18n.language,
      selectedGuideId: null,
      videoModal: {
        display: false,
        video: null,
      },
    }
  }

  componentDidMount() {
    this.props.fetchExampleDestination().catch(() => {})
  }

  onSelectGuide = data => {
    this.setState({ selectedGuideId: data?.id })
  }

  openVideoModal = data => {
    this.setState({ videoModal: { display: true, video: data.video } })
  }

  closeVideoModal = () => {
    this.setState({ videoModal: { display: false, video: null } })
  }

  render() {
    const { language, selectedGuideId, videoModal } = this.state
    const { t, destination, isLoading } = this.props

    const guide = destination?.guides?.find(x => x.id === selectedGuideId) // Selected guide
    const guides = destination?.guides ? mapOrder(destination.guides, destination.order, "id") : [] // All guides sorted

    return (
      <Container fluid>
        <Row>
          <Col>
            <h3 className="mb-4 font-weight-bold">{t("examples.title")}</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={8}>
            <Card className="mb-4">
              <Card.Header>
                <Row>
                  <Col xs={8}>
                    <h5 className="mb-0 mt-1">{destination && getLocaleValue(destination, "name", language)}</h5>
                  </Col>
                  <Col xs={4}>
                    <Nav
                      justify
                      defaultActiveKey={language}
                      onSelect={language => this.setState({ language })}
                      className="language-navigation">
                      <Nav.Item>
                        <Nav.Link eventKey="fi">{t("common.languages.fi")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="en">{t("common.languages.en")}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    {isLoading && (
                      <div className="loading-spinner">
                        <HiOutlineRefresh className="slow-spin icon loading-spinner-icon" />
                      </div>
                    )}
                    {guides.map((data, index) => (
                      <Card
                        body
                        key={index}
                        className={["guide", data.id === selectedGuideId && "selected"]}
                        onClick={() => this.onSelectGuide(data)}>
                        <IonIcon name={data?.icon} />
                        <span className="ml-3">{data && getLocaleValue(data, "topic", language)}</span>
                      </Card>
                    ))}
                    {(!destination || !destination.guides.length) && (
                      <p className="text-center mt-3">{t("guides.noContent")}</p>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={4}>
            <Card className="mb-4">
              <Card.Header>
                <h5 className="mb-0">{t("guides.preview")}</h5>
              </Card.Header>
              <div className="mb-3">
                {guide &&
                  guide.content &&
                  guide.content.map((data, index) => {
                    switch (Object.keys(data)[0]) {
                      case "text":
                        return <GuideText data={data.text} key={index} language={language} preview />
                      case "image":
                        return <GuideImage data={data.image} key={index} preview />
                      case "video":
                        return (
                          <GuideVideoThumbnail
                            data={data.video}
                            key={index}
                            preview
                            list
                            onClick={this.openVideoModal}
                          />
                        )
                      default:
                        return null
                    }
                  })}
                {!guide && (
                  <p className="text-muted font-italic m-0 px-4 pt-3">{t("guides.selectedGuide.unselected")}</p>
                )}
                {guide && !guide.content.length && (
                  <p className="text-muted font-italic m-0 px-4 pt-3">{t("guides.selectedGuide.noContent")}</p>
                )}
              </div>
            </Card>
          </Col>
        </Row>
        <VideoModal video={videoModal.video} display={videoModal.display} close={this.closeVideoModal} />
      </Container>
    )
  }
}

ExamplesContainer.propTypes = {
  isLoading: PropTypes.bool,
}

ExamplesContainer.defaultProps = {
  isLoading: false,
}

const mapStateToProps = state => ({
  isLoading: state.destinations.isLoading,
  destination: state.destinations.example,
})

const mapDispatchToProps = dispatch => ({
  fetchExampleDestination: () => dispatch(fetchExampleDestination()),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ExamplesContainer))
