import { getRequest, postRequest, putfileRequest, deleteRequest } from "../helpers/fetchHandler"
import {
  ACTIVITIES_LOADING,
  ACTIVITIES_SUCCESS,
  ACTIVITIES_FAILED,
  ACTIVITY_SELECTED,
  ACTIVITY_UNSELECTED,
  SUBCATEGORIES_LOADING,
  SUBCATEGORIES_SUCCESS,
  SUBCATEGORIES_FAILED,
  CREATE_ACTIVITIES_LOADING,
  CREATE_ACTIVITIES_SUCCESS,
  CREATE_ACTIVITIES_FAILED,
  UPDATE_ACTIVITIES_LOADING,
  UPDATE_ACTIVITIES_SUCCESS,
  UPDATE_ACTIVITIES_FAILED,
  DELETE_ACTIVITIES_LOADING,
  DELETE_ACTIVITIES_SUCCESS,
  DELETE_ACTIVITIES_FAILED,
} from "../constants/actionTypes"

// Require authorization
export function fetchActivities() {
  return dispatch => {
    dispatch({ type: ACTIVITIES_LOADING })

    const url = "/api/amenities/"
    return getRequest({ url, auth: true })
      .then(data => dispatch({ type: ACTIVITIES_SUCCESS, payload: data }))
      .catch(error => {
        dispatch({ type: ACTIVITIES_FAILED, error })
        return Promise.reject(error)
      })
  }
}

// Require authorization
export function fetchActivityCategories() {
  return dispatch => {
    dispatch({ type: SUBCATEGORIES_LOADING })

    const url = "/api/activity-categories/subcategories/"
    return getRequest({ url, auth: true })
      .then(data => dispatch({ type: SUBCATEGORIES_SUCCESS, payload: data }))
      .catch(error => {
        dispatch({ type: SUBCATEGORIES_FAILED, error })
        return Promise.reject(error)
      })
  }
}

// Require authorization
export function createActivity(data) {
  return dispatch => {
    dispatch({ type: CREATE_ACTIVITIES_LOADING })

    const url = "/api/amenities/"
    return postRequest({ url, data, auth: true })
      .then(response => dispatch({ type: CREATE_ACTIVITIES_SUCCESS, payload: response }))
      .catch(error => {
        dispatch({ type: CREATE_ACTIVITIES_FAILED, error })
        return Promise.reject(error)
      })
  }
}

// Require authorization
export function updateActivity(id, data) {
  return dispatch => {
    dispatch({ type: UPDATE_ACTIVITIES_LOADING })

    const formData = new FormData()
    Object.entries(data).forEach(entry => {
      const [key, value] = entry
      if (key === "image") {
        formData.append(key, value, value.name)
      } else if (key === "categories") {
        value.forEach(x => formData.append(key, x))
      } else {
        formData.append(key, value)
      }
    })

    const url = `/api/amenities/${id}/`
    return putfileRequest({ url, data: formData, auth: true })
      .then(response => dispatch({ type: UPDATE_ACTIVITIES_SUCCESS, payload: response }))
      .catch(error => {
        dispatch({ type: UPDATE_ACTIVITIES_FAILED, error })
        return Promise.reject(error)
      })
  }
}

// Require authorization
export function deleteActivity(id) {
  return dispatch => {
    dispatch({ type: DELETE_ACTIVITIES_LOADING })

    const url = `/api/amenities/${id}/`
    return deleteRequest({ url, auth: true })
      .then(() => dispatch({ type: DELETE_ACTIVITIES_SUCCESS, payload: id }))
      .catch(error => {
        dispatch({ type: DELETE_ACTIVITIES_FAILED, error })
        return Promise.reject(error)
      })
  }
}

export function selectActivity(id) {
  return dispatch => {
    dispatch({ type: ACTIVITY_SELECTED, payload: id })
    return Promise.resolve()
  }
}

export function unselectActivity() {
  return dispatch => {
    dispatch({ type: ACTIVITY_UNSELECTED })
    return Promise.resolve()
  }
}
