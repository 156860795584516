import React from "react"
import { useTranslation } from "react-i18next"

import Paginations from "./paginations"

const ListFooter = ({ total, pagination, onPaginationSelect, maxRows, onMaxRowSelect }) => {
  const { t } = useTranslation()

  return (
    <div className="tw-flex -tw-mx-6 tw-p-6 tw-mt-6 tw-border-t tw-rounded-b">
      <div className="tw-flex tw-w-1/2 md:tw-w-1/3">
        <select
          id="countries"
          name="countries"
          value={maxRows}
          onChange={e => onMaxRowSelect(Number(e.target.value))}
          className="tw-bg-white tw-border tw-border-dark-15 tw-text-sm tw-rounded-lg tw-place-self-center
            focus:tw-outline-none tw-h-10 tw-w-14 tw-p-2 tw-mr-2">
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </select>
        <label className="tw-place-self-center tw-mb-0">{t("projects.listFooter.maxRows")}</label>
      </div>
      <span className="tw-flex tw-place-self-center md:tw-w-1/3 max-md:tw-hidden">
        <span className="tw-w-full tw-text-center">
          {t("projects.listFooter.display", {
            from: total > 0 ? pagination.min + 1 : 0,
            to: pagination.max,
            total: total,
          })}
        </span>
      </span>
      <div className="tw-flex tw-justify-end tw-w-1/2 md:tw-w-1/3">
        {total > maxRows && (
          <Paginations
            className="tw-mb-0"
            total={total}
            maxRows={maxRows}
            active={pagination.selected}
            onSelect={onPaginationSelect}
          />
        )}
      </div>
    </div>
  )
}

export default ListFooter
