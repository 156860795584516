import React from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, Form } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import { useTranslation, Trans } from "react-i18next"

const validationSchema = t => {
  return Yup.object({
    email: Yup.string().email(t("common.validation.invalidEmail")).required(t("common.validation.required")),
    password: Yup.string()
      .min(9, t("common.validation.invalidPasswordLength"))
      .required(t("common.validation.required")),
  })
}

const Login = ({ onSubmit, isLoading, values }) => {
  const { t } = useTranslation()

  return (
    <>
      <h1 className="title mb-5">{t("auth.login")}</h1>
      <Formik
        enableReinitialize={true}
        initialValues={{ email: values?.email || "", password: "" }}
        validationSchema={validationSchema(t)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={submitValues => onSubmit(submitValues)}>
        {({ errors, touched, handleChange, handleBlur, handleSubmit, ...props }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="my-5">
              <Form.Group as={Col} xs={12} controlId="LoginFormEmail">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder={t("auth.form.email")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={props.values.email}
                  isInvalid={touched.email && errors.email}
                  disabled={!!values?.email}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs={12} controlId="LoginFormPassword">
                <Form.Control
                  type="password"
                  name="password"
                  placeholder={t("auth.form.password")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={props.values.password}
                  isInvalid={touched.password && errors.password}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Col className="text-right small underline">
                <Link to="/recovery">{t("auth.button.forgotPassword")}</Link>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xl={{ span: 8, offset: 2 }} xs={12}>
                <Button block variant="primary" type="submit" disabled={isLoading}>
                  {t("auth.button.login")}
                </Button>
              </Col>
            </Row>
            <Row className="small">
              <Col>
                <Trans i18nKey="auth.button.register">
                  <Link className="underline" to="/register"></Link>
                </Trans>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

Login.defaultProps = {}

export default Login
