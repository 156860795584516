import React, { useState, useEffect, useCallback, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { HiOutlineDotsHorizontal } from "react-icons/hi"
import { Tab, Menu, Transition } from "@headlessui/react"

import { getLocaleValue } from "../../../helpers/getLocaleValue"
import { twClassNames } from "../../../helpers/classNames"
import { selectActivity, deleteActivity } from "../../../actions/activities"

import ListFooter from "./listFooter"

const ThumbnailImage = ({ image }) => (
  <img
    width="200"
    height="200"
    className="tw-w-[200px] tw-max-w-[200px] tw-h-[200px] tw-object-cover tw-rounded-tl-2xl tw-rounded-bl-2xl"
    alt="activity cover"
    src={typeof image === "string" ? image : URL.createObjectURL(image)}
  />
)

const Activity = ({ activity, onSelect, onDelete }) => {
  const { t, i18n } = useTranslation()
  const language = i18n.language

  return (
    <div
      className="tw-flex tw-flex-col md:tw-flex-row tw-bg-white tw-shadow tw-rounded-2xl 
      tw-border-2 tw-border-gray tw-mb-4">
      {/* Image */}
      <div className="tw-w-full tw-max-w-[200px] tw-h-[200px] tw-bg-gray tw-hidden lg:tw-block">
        {activity.image && <ThumbnailImage image={activity.image} />}
      </div>
      {/* Content */}
      <div className="tw-flex tw-flex-col tw-w-full tw-px-6 tw-py-4">
        {/* Header */}
        <div className="tw-flex tw-mb-2">
          <div className="tw-grow tw-font-semibold tw-text-lg">{getLocaleValue(activity, "name", language)}</div>
          <Menu as="div" className="tw-relative tw-inline-block tw-text-left">
            <Menu.Button className="tw-inline-flex tw-w-full tw-justify-right tw-px-2">
              <HiOutlineDotsHorizontal className="tw-h-5 tw-w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="tw-transition tw-ease-out tw-duration-100"
              enterFrom="tw-transform tw-opacity-0 tw-scale-95"
              enterTo="tw-transform tw-opacity-100 tw-scale-100"
              leave="tw-transition tw-ease-in tw-duration-75"
              leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
              leaveTo="tw-transform tw-opacity-0 tw-scale-95">
              <Menu.Items
                className="tw-absolute tw-right-0 tw-z-10 tw-w-56 tw-origin-top-right tw-rounded-md tw-py-1
                    tw-bg-white tw-shadow-lg tw-border tw-border-dark-15 focus:tw-outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => onDelete(activity)}
                      className={twClassNames(
                        active ? "tw-bg-gray" : "tw-text-red-700",
                        "tw-block tw-px-4 tw-py-2 tw-text-sm hover:tw-no-underline hover:tw-text-red-700"
                      )}>
                      {t("projects.button.delete")}
                    </div>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        {/* Body */}
        <div className="tw-flex tw-grow tw-flex-col md:tw-flex-row">
          {/* Left */}
          <div className="tw-w-full tw-border-t-2 tw-border-gray md:tw-mr-6 lg:tw-mr-12">
            <div className="tw-flex tw-text-sm tw-my-3">
              <div className="tw-w-1/2">
                <span className="tw-capitalize">
                  {activity.address}
                  <br />
                  {activity.municipal}
                </span>
                <span className="tw-uppercase">, Finland</span>
              </div>
              <div className="tw-w-1/2">{activity.company}</div>
            </div>
            <div className="tw-text-dark-50 tw-text-sm tw-line-clamp-3">
              {getLocaleValue(activity, "description", language)}
            </div>
          </div>
          {/* Right */}
          <div className="tw-flex md:tw-w-1/3">
            <div className="tw-grow tw-flex tw-items-end tw-mt-4">
              <button className="tw-btn tw-btn-primary tw-w-full" onClick={() => onSelect(activity)}>
                {t("projects.button.select")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TabActivities = ({ activities, query, onMaxRowSelect, maxRows, setConfirmation, hideConfirmation }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const defaultPagination = useCallback(() => {
    return { selected: 1, min: 0, max: maxRows > activities.length ? activities.length : maxRows }
  }, [activities, maxRows])

  const [pagination, setPagination] = useState(defaultPagination)

  useEffect(() => {
    setPagination(defaultPagination)
  }, [query, defaultPagination])

  const onSelect = id => {
    dispatch(selectActivity(id))
      .then(() => history.push("/activities/campaigns"))
      .catch(() => {})
  }

  const onDelete = id =>
    setConfirmation({
      show: true,
      callback: () => {
        hideConfirmation()
        dispatch(deleteActivity(id))
      },
      title: "delete",
      content: "delete.destination",
      danger: true,
    })

  const onPaginationSelect = value =>
    setPagination({
      selected: value,
      min: (value - 1) * maxRows,
      max: value * maxRows > activities.length ? activities.length : value * maxRows,
    })

  return (
    <Tab.Panel>
      {/* Empty */}
      {activities.length === 0 && (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-my-12">
          <p className="tw-text-center tw-text-dark-50 tw-text-lg tw-mb-0">
            {query ? t("projects.empty.withQuery.activities") : t("projects.empty.withoutQuery.activities")}
          </p>
          {query === "" && (
            <button
              className="tw-btn tw-btn-outline tw-min-w-[180px] tw-mt-6"
              onClick={() => history.push("/activities/create")}>
              <span>{t("projects.button.create.activity")}</span>
            </button>
          )}
        </div>
      )}
      {activities.slice(pagination.min, pagination.max).map(activity => (
        <Activity
          activity={activity}
          key={activity.id}
          onSelect={activity => onSelect(activity.id)}
          onDelete={activity => onDelete(activity.id)}
        />
      ))}
      <ListFooter
        total={activities.length}
        pagination={pagination}
        onPaginationSelect={onPaginationSelect}
        maxRows={maxRows}
        onMaxRowSelect={onMaxRowSelect}
      />
    </Tab.Panel>
  )
}

export default TabActivities
