import React from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import { Row, Col, Button, Form } from "react-bootstrap"
import * as Yup from "yup"
import { useTranslation } from "react-i18next"

const validationSchema = t => {
  return Yup.object({
    password: Yup.string()
      .min(9, t("common.validation.invalidPasswordMinLength", { min: 9 }))
      .required(t("common.validation.required")),
    repassword: Yup.string()
      .required(t("common.validation.required"))
      .oneOf([Yup.ref("password")], t("common.validation.unmatchPassword")),
  })
}

const ResetPassword = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation()

  return (
    <>
      <h1 className="title mb-5">{t("auth.resetPassword")}</h1>
      <Formik
        enableReinitialize={true}
        initialValues={{ password: "", repassword: "" }}
        validationSchema={validationSchema(t)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={values => onSubmit(values.password)}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-4 mt-4">
              <Col>
                <Form.Group controlId="ResetFormNewPassword" className="position-relative">
                  <Form.Control
                    type="text"
                    name="password"
                    placeholder={t("auth.form.new_password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={touched.password && errors.password}
                  />
                  <Form.Control.Feedback tooltip type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="ResetFormRePassword" className="position-relative">
                  <Form.Control
                    type="text"
                    name="repassword"
                    placeholder={t("auth.form.re_password")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.repassword}
                    isInvalid={touched.repassword && errors.repassword}
                  />
                  <Form.Control.Feedback tooltip type="invalid">
                    {errors.repassword}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl={{ span: 8, offset: 2 }} xs={12}>
                <Button block variant="primary" type="submit" disabled={isLoading}>
                  {t("auth.button.updatePassword")}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

ResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
ResetPassword.defaultProps = {}

export default ResetPassword
