import React, { useState } from "react"
import { twClassNames } from "../helpers/classNames"

const DragZone = ({ onDrop, className, ...props }) => {
  const [active, setDragActive] = useState(false)

  const handleEnter = e => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(true)
  }

  const handleLeave = e => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
  }

  const handleOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = e => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files.length > 0) {
      onDrop(e.dataTransfer.files[0])
    }
  }

  return (
    <div
      onDragEnter={handleEnter}
      onDragLeave={handleLeave}
      onDragOver={handleOver}
      onDrop={handleDrop}
      className={twClassNames(active && "tw-border-primary", className, "[&>*]:tw-pointer-events-none")}
      {...props}>
      {props.children}
    </div>
  )
}

export default DragZone
