import { postRequest } from "../helpers/fetchHandler"
import { FEEDBACK_LOADING, FEEDBACK_SUCCESS, FEEDBACK_FAILED } from "../constants/actionTypes"

export function postFeedback(data) {
  return dispatch => {
    dispatch({ type: FEEDBACK_LOADING })

    const url = "/api/feedback/"
    return postRequest({ url, data, auth: true })
      .then(data => dispatch({ type: FEEDBACK_SUCCESS, payload: data }))
      .catch(error => {
        dispatch({ type: FEEDBACK_FAILED, error })
        return Promise.reject(error)
      })
  }
}
